import { type CreateBlockData } from '@/lib/builder/types'

import {
  type CareersSectionCreate,
  type CareersSection,
} from '../../../WebsiteBuilder.types'

export const createCareersData = ({
  brandId,
}: CreateBlockData<CareersSection>): CareersSectionCreate => ({
  brandId,
  params: {
    heading: 'Why work with us?',
    jobs: [],
    subheading:
      'We are always hiring A players who work hard, love helping others, and do great work. Fill out the 2 minute form below with your resume and a few sentences about you.',
  },
  type: 'careers',
})
