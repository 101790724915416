<template>
  <PreviewSheet class="features-preview" radius="0">
    <div class="flex-col gap-16 align-center justify-center">
      <PreviewTypography :text="content.params.title" variant="heading2" />
      <div
        class="features-preview__items flex-row justify-center flex-wrap"
        :style="style"
      >
        <FeaturesCard
          v-for="feature in features"
          :key="feature.title"
          :feature="feature"
        />
      </div>
    </div>
  </PreviewSheet>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import PreviewSheet from '@/lib/builder/public/preview/PreviewSheet.vue'
import PreviewTypography from '@/lib/builder/public/preview/PreviewTypography.vue'

import type { FeaturesSection } from '../../../WebsiteBuilder.types'

import FeaturesCard from './FeaturesCard.vue'

const props = defineProps<{
  content: FeaturesSection
}>()

const features = computed(() => {
  return props.content.params.features
})

const numColumns = computed(() => {
  const numberOfFeatures = features.value.length

  return numberOfFeatures === 4 || numberOfFeatures > 6 ? 4 : 3
})

const style = computed(() => {
  if (numColumns.value === 4) {
    return { maxWidth: '1200px' }
  } else if (numColumns.value === 3) {
    return { maxWidth: '900px' }
  } else {
    return {}
  }
})
</script>

<style lang="scss" scoped>
.features-preview {
  padding: 120px;

  &__items {
    column-gap: 40px;
    row-gap: 56px;
    align-self: center;
  }
}
</style>
