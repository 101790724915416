<i18n locale="en">
{
  "order-now": "Order Online",
  "read-our-story": "Read Our Story"
}
</i18n>

<template>
  <div :class="['flex-col gap-8', `justify-${justify}`]">
    <div class="flex-col gap-8" :style="{ maxWidth }">
      <div class="flex-col gap-6 align-start">
        <PreviewTypography
          v-if="littleTitle"
          :text="littleTitle"
          :color="textColor"
          :style="littleTitleBorder"
          class="landing-hero-content-group__little-title"
          variant="titleSmall"
        />
        <PreviewTypography
          :text="bigTitle"
          :color="textColor"
          class="landing-hero-content-group__big-title"
          variant="heading1"
        />
      </div>
    </div>
    <div class="flex-row gap-8">
      <PreviewButton :title="t('order-now')" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { type Location } from '@/core/types'
import { useMergedTheme } from '@/lib/builder/public/preview/mixins/useMergedThemeComp'
import PreviewButton from '@/lib/builder/public/preview/PreviewButton.vue'
import PreviewTypography from '@/lib/builder/public/preview/PreviewTypography.vue'
import { useMappedGetter } from '@/store'

import type {
  LandingHeroSection,
  WebsiteResource,
} from '../../../WebsiteBuilder.types'

const props = withDefaults(
  defineProps<{
    id: string

    /**
     * Force max width on heading
     */
    justify?: 'start' | 'center' | 'end'
    maxWidth?: string
  }>(),
  { justify: 'center', maxWidth: undefined }
)

const { t } = useI18n()

const blockById = useMappedGetter<
  (blockId: string) => LandingHeroSection | undefined
>('websiteBuilder/blockById')
const locations = useMappedGetter<Location[] | undefined>(
  'core/brand/active/locations'
)
const resource = useMappedGetter<WebsiteResource>(
  'websiteBuilder/currentResource'
)

const theme = useMergedTheme()

const headline = computed(() =>
  currentBlockParams.value && currentBlockParams.value.headline !== ''
    ? currentBlockParams.value.headline
    : undefined
)

const seoTitle = computed(() => {
  if (!locations.value?.length || !locations.value[0]) return ''

  const { address } = locations.value[0]

  let commonLocation =
    locations.value.length > 1 ? address?.state : address?.city
  if (
    resource.value?.path.params &&
    'page' in resource.value.path.params &&
    resource.value?.path.params.page === 'location'
  ) {
    const location = locations.value.find(
      ({ id }) =>
        resource.value?.path.params &&
        'params' in resource.value.path.params &&
        id === resource.value?.path.params.params?.locationId
    )
    if (location) {
      commonLocation = location.address?.city
    }
  }

  return `Best ${resource.value?.keyword ?? 'Restaurant'} in ${commonLocation}`
})

const bigTitle = computed(() => headline.value ?? seoTitle.value)

const currentBlockParams = computed(() => blockById.value(props.id)?.params)

const textColor = computed(() =>
  currentBlockParams.value?.layout === 'cover' ? 'white' : 'primary'
)

const littleTitle = computed(() => headline.value && seoTitle.value)

const littleTitleBorder = computed(() => ({
  borderLeft: `4px solid ${
    currentBlockParams.value?.layout === 'cover'
      ? 'white'
      : theme.value?.colors.text.primary
  }`,
}))
</script>

<style lang="scss" scoped>
.landing-hero-content-group {
  &__big-title {
    text-wrap: balance;
  }

  &__little-title {
    padding-left: 16px;
  }
}
</style>
