<template>
  <PreviewSheet
    v-motion-fade
    class="standalone-builder-preview"
    :style="{ backgroundColor: builderBackgroundColor }"
  >
    <BuilderBlockPreviewWrapper
      v-if="previewHeading"
      :block-module="previewHeading"
      edit-min-height="0"
    >
      <BuilderBlockPreview
        id="heading"
        class="standalone-builder-preview__heading"
        :preview="previewHeading.preview"
        :block-content="{}"
      />
    </BuilderBlockPreviewWrapper>

    <div v-for="{ id, blockData, module } of previewBlockMap" :key="id">
      <BuilderBlockPreviewWrapper :block-module="module">
        <BuilderBlockPreview
          :id="id"
          class="standalone-builder-preview__section"
          :preview="module?.preview"
          :block-content="blockData"
        />
      </BuilderBlockPreviewWrapper>
    </div>

    <BuilderBlockPreviewWrapper
      v-if="previewFooter"
      :block-module="previewFooter"
    >
      <BuilderBlockPreview
        id="footer"
        class="standalone-builder-preview__footer"
        :preview="previewFooter.preview"
        :block-content="{}"
      />
    </BuilderBlockPreviewWrapper>
  </PreviewSheet>
</template>

<script>
import { PreviewSheet } from '../public'
import { useMergedTheme } from '../public/preview/mixins/useMergedTheme'

import BuilderBlockPreview from './preview/BuilderBlockPreview.vue'
import BuilderBlockPreviewWrapper from './preview/BuilderBlockPreviewWrapper.vue'

export default {
  name: 'StandaloneBuilderPreview',
  components: {
    BuilderBlockPreview,
    BuilderBlockPreviewWrapper,
    PreviewSheet,
  },
  mixins: [useMergedTheme],
  props: {
    /**
     * Block Modules
     * @private
     */
    blockModules: { type: Array, default: () => [] },

    /**
     * Preview Footer
     * @private
     */
    previewFooter: { type: Object, default: undefined },

    /**
     * Preview Header
     * @private
     */
    previewHeading: { type: Object, default: undefined },

    /**
     * Resource Data
     * @public
     */
    resource: { type: Object, required: true },

    /**
     * Section Data
     * @public
     */
    sections: { type: Array, required: true },
  },
  computed: {
    builderBackgroundColor() {
      const { mergedTheme, previewBackgroundColor } = this

      return mergedTheme?.colors.background[previewBackgroundColor]
    },
    previewBlockMap() {
      const { blockModules, sections, resource } = this

      return resource.sections.map((section) => {
        const sectionBlockData = sections.find((block) => block.id === section)

        const sectionBlockModule = blockModules.find((blockModule) =>
          blockModule.meta.find(
            (blockMeta) => blockMeta.key === sectionBlockData.type
          )
        )

        return {
          blockData: sectionBlockData,
          id: section,
          module: sectionBlockModule,
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.standalone-builder-preview {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  &__heading {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;
  }

  &__section {
    overflow: hidden;
  }

  &__footer {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;
    margin-bottom: 40px;
  }

  &__add-section {
    position: relative;
    z-index: 1;
  }
}
</style>
