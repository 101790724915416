<template>
  <div
    :style="{
      height: blockHeight && scaling ? `${blockHeight}px` : undefined,
    }"
  >
    <Responsive v-if="scaling" @size-change="setPreviewSize">
      <div
        class="builder-block-preview__scale-container"
        :style="{
          transform: `scale(${previewScale})`,
          width: `${previewBuildSize}px`,
        }"
      >
        <component :is="preview" :id="id" :content="blockContent" />
      </div>
    </Responsive>

    <component :is="preview" v-else :id="id" :content="blockContent" />
  </div>
</template>

<script>
import Responsive from '@/ui/Responsive.vue'
import { linterp } from '@/utils/linterp'

export default {
  name: 'BuilderBlockPreview',
  components: { Responsive },
  props: {
    blockContent: { type: Object, required: true },
    id: { type: String, required: true },
    preview: { type: Object, required: true },
  },
  inject: ['scaling'],
  data() {
    return {
      blockHeight: undefined,
      blockWidth: undefined,
      previewBuildSize: undefined, // px
      previewScale: undefined, // Percent
    }
  },
  created() {
    const { scaling } = this

    if (scaling) {
      this.previewBuildSize = scaling.previewBuildSize
      this.previewScale = scaling.initialScale
    }
  },
  methods: {
    setPreviewSize({ height, width }) {
      const { blockWidth, scaling } = this

      const [scalePoint1, scalePoint2] = scaling.scaleMap

      const scale = linterp(scalePoint1, scalePoint2, width)

      const scaledHeight = scale * height

      if (
        !blockWidth ||
        blockWidth !== width ||
        scaledHeight !== this.blockHeight
      ) {
        this.blockWidth = width

        this.previewScale = scale

        this.blockHeight = scaledHeight
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.builder-block-preview {
  &__scale-container {
    transform-origin: top left;
  }
}
</style>
