import { BuilderRouterFactory } from '@/lib/builder/router/BuilderRouterFactory'
import { useBuilderRouter } from '@/lib/builder/router/useBuilderRouter'

import { websiteBuilderConfig } from './websiteBuilderConfig'

const websiteBuilderRouteManager = useBuilderRouter(websiteBuilderConfig)

const websiteBuilderModuleRouter = BuilderRouterFactory(websiteBuilderConfig)

export { websiteBuilderModuleRouter, websiteBuilderRouteManager }
