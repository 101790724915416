<template>
  <div
    v-if="isVisible"
    class="own-dialog flex-col justify-center align-center"
    @click.stop="onCancel"
  >
    <OwnCard
      v-motion-slide-visible-bottom
      :class="['own-dialog__card', `own-dialog__card--${size}`]"
      @click.stop
    >
      <slot name="full-dialog">
        <slot name="heading" :close="closeDialog" />
        <slot :close="closeDialog" />
        <slot name="footer" :close="closeDialog" />
      </slot>
    </OwnCard>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeRouteLeave } from 'vue-router'

import { OwnCard } from '../OwnCard'

export type DialogSizes = 'sm' | 'lg'

const props = withDefaults(
  defineProps<{
    navigationGuard?: boolean
    size?: DialogSizes
  }>(),
  {
    navigationGuard: false,
    size: 'sm',
  }
)

onBeforeRouteLeave((to, from) => {
  if (!props.navigationGuard) return

  const answer = window.confirm('Do you really want to leave?')
  if (!answer) return false
})

const emit = defineEmits<{ cancel: [] }>()

const isVisible = defineModel<boolean>()

const closeDialog = () => {
  isVisible.value = false
}

const onCancel = () => {
  emit('cancel')
  closeDialog()
}
</script>

<style lang="scss">
@use '@/styles/breakpoints';

.own-dialog {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 300;

  margin: 0;
  background-color: rgba(0, 0, 0, 0.89);

  padding: 16px;

  &__card {
    width: 100%;
    max-height: calc(100% - 80px);

    &--sm {
      @include breakpoints.respond-to('md-and-up') {
        max-width: 600px;
      }
    }
    &--lg {
      @include breakpoints.respond-to('md-and-up') {
        max-width: 900px;
      }
    }
  }

  &__close {
    width: 20px;
    height: 20px;
  }
}
</style>
