import { PhCardholder } from '@phosphor-icons/vue'

import { type BuilderBlockModule } from '@/lib/builder/types'
import i18nPlugin from '@/plugins/i18n'

import {
  type WebsiteResource,
  type GiftCardsSection,
  type GiftCardsSectionUpdate,
  type GiftCardsSectionCreate,
} from '../../WebsiteBuilder.types'
import { websiteBlockMethods } from '../common/methods'

import GiftCardsEditor from './editor/GiftCardsEditor.vue'
import { createGiftCardsData } from './methods/createGiftCardsData'
import GiftCardsPreview from './preview/GiftCardsPreview.vue'
const i18n = i18nPlugin.global

export const GiftCardsBlockModule: BuilderBlockModule<
  GiftCardsSection,
  GiftCardsSectionCreate,
  GiftCardsSectionUpdate,
  WebsiteResource
> = {
  canAdd: false,
  canDelete: false,
  canReorderDown: false,
  canReorderUp: false,
  createData: createGiftCardsData,
  editor: GiftCardsEditor,
  meta: [
    {
      icon: PhCardholder,
      key: 'gift-cards',
      label: i18n.t('website-builder.blocks.gift-cards'),
    },
  ],
  methods: websiteBlockMethods,
  preview: GiftCardsPreview,
}
