import { OlympusClient } from '@/OlympusClient'

import {
  type WebsiteSection,
  type WebsiteSectionCreate,
} from '../../WebsiteBuilder.types'

export const createBlockHandler = async <
  TSection extends WebsiteSection,
  TSectionCreate extends WebsiteSectionCreate,
>(
  body: TSectionCreate
): Promise<TSection> => {
  // TODO: Tucker - How can we fix these types coming off olympus so the
  // usage is clearer and provides more type safety?
  return (await OlympusClient.funnel.v1.sections.createSection({
    body,
  })) as TSection
}
