import {
  type BlockCore,
  type BuilderEditorModes,
  type BuilderState,
  type ResourceCore,
} from '../../types'

export const setEditorModeMutation = <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  state: BuilderState<TResource, TBlock>,
  newEditMode: BuilderEditorModes
): void => {
  state._builder_editorMode = newEditMode
}
