import { PhStar } from '@phosphor-icons/vue'

import { type BuilderBlockModule } from '@/lib/builder/types'
import i18nPlugin from '@/plugins/i18n'

import {
  type WebsiteResource,
  type ReviewsSectionCreate,
  type ReviewsSection,
  type ReviewsSectionUpdate,
} from '../../WebsiteBuilder.types'
import { websiteBlockMethods } from '../common/methods'

import ReviewsEditor from './editor/ReviewsEditor.vue'
import { createReviewsData } from './methods/createReviewsData'
import ReviewsPreview from './preview/ReviewsPreview.vue'
const i18n = i18nPlugin.global

export const ReviewsBlockModule: BuilderBlockModule<
  ReviewsSection,
  ReviewsSectionCreate,
  ReviewsSectionUpdate,
  WebsiteResource
> = {
  createData: createReviewsData,
  customEditor: true,
  editor: ReviewsEditor,
  meta: [
    {
      icon: PhStar,
      key: 'reviews',
      label: i18n.t('website-builder.blocks.reviews'),
      sort: 7,
    },
  ],
  methods: websiteBlockMethods,
  preview: ReviewsPreview,
}
