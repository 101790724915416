import { type CreateBlockData } from '@/lib/builder/types'

import {
  type LandingHeroSectionCreate,
  type LandingHeroSection,
} from '../../../WebsiteBuilder.types'

export const createLandingHeroData = ({
  brandId,
}: CreateBlockData<LandingHeroSection>): LandingHeroSectionCreate => ({
  brandId,
  params: {
    carouselAssets: [
      {
        image:
          'https://dev-static-content.owner.com/3f74c8c0-6b2f-4daf-8683-ec88ce89fc49',
      },
      {
        image:
          'https://static-content.owner.com/a5627b3c-9f31-4b04-a80a-e2c321ba1571',
      },
    ],
    headline: 'The freshest of fresh food from Toronto!',
    layout: 'cover',
  },
  type: 'landing-hero',
})
