<i18n locale="en">
{
  "title": "Photo",
  "labels": {
    "upload": "Drag & drop or choose a photo to upload",
    "select": "Select Photo"
  }
}
</i18n>

<template>
  <OwnInputContainer :label="t('title')">
    <ImageInput
      v-model="image"
      :label="t('labels.select')"
      :upload-label="t('labels.upload')"
      :crop-width="700"
    />
  </OwnInputContainer>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'

import { ImageInput } from '@/components/inputs/ImageInput'
import { OwnInputContainer } from '@/ui/OwnInputContainer'

import { ImageCommands } from '../commands/ImageCommands'

export default {
  name: 'ImageEditor',
  components: { ImageInput, OwnInputContainer },
  props: {
    /** ID of the block */
    id: { type: String, required: true },
  },
  setup() {
    const { t } = useI18n()

    return { t }
  },
  data() {
    return {
      image: null,
    }
  },
  computed: {
    ...mapGetters({
      blockById: 'emailBuilder/blockById',
    }),
    currentImage() {
      const { id } = this

      return this.blockById(id)?.data?.image
    },
  },
  watch: {
    async image(image) {
      await ImageCommands.updateBlock({
        targetId: this.id,
        update: { data: { image } },
      })
    },
  },
  mounted() {
    this.image = this.currentImage
  },
}
</script>
