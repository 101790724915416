import { BuilderRouterFactory } from '@/lib/builder/router/BuilderRouterFactory'
import { useBuilderRouter } from '@/lib/builder/router/useBuilderRouter'

import { emailBuilderConfig } from './emailBuilderConfig'

const emailBuilderRouteManager = useBuilderRouter(emailBuilderConfig)

const emailBuilderRoutes = BuilderRouterFactory(emailBuilderConfig)

export { emailBuilderRoutes, emailBuilderRouteManager }
