import type { ActionContext } from 'vuex'

import type { CoreState } from '@/core/store/types'
import type { Location } from '@/core/types'
import { OlympusClient } from '@/OlympusClient'

export interface UpdateCoreLocationParams {
  locationId: string
  update: Pick<Location, 'name' | 'slug' | 'status' | 'localTimezone' | 'tax'>
}

export const updateGeneralLocationSettings = async (
  { commit }: ActionContext<CoreState, unknown>,
  { locationId, update }: UpdateCoreLocationParams
): Promise<void> => {
  const location = await OlympusClient.locations.location.updateLocation({
    body: update,
    params: {
      locationId,
    },
  })

  commit('SET_LOCATION', location)
}
