<i18n locale="en">
{
  "titles" : {
    "add": "Add External Link",
    "update": "Update Link"
  },
  "actions": {
    "update": "Update",
    "add": "Add"
  },
  "label": {
    "label": "Link Title",
    "placeholder": "Aa"
  },
  "url": {
    "label": "Link URL",
    "placeholder": "https://example.com"
  }
}
</i18n>

<template>
  <OwnForm :submit-handler="onUpdate">
    <OwnDialog :model-value="true" @cancel="closeAndReset">
      <template #heading>
        <OwnDialogHeading
          :title="editItem ? t('titles.update') : t('titles.add')"
          @cancel="closeAndReset"
        />
      </template>

      <OwnDialogContent scroll>
        <div class="flex-col gap-6">
          <OwnInputContainer
            :label="t('label.label')"
            :errors="label.status.errors"
          >
            <OwnInput
              v-model="label.value"
              :placeholder="t('label.placeholder')"
            />
          </OwnInputContainer>

          <OwnInputContainer
            :label="t('url.label')"
            :errors="url.status.errors"
          >
            <OwnInput v-model="url.value" :placeholder="t('url.placeholder')" />
          </OwnInputContainer>
        </div>
      </OwnDialogContent>

      <template #footer>
        <OwnDialogFooter>
          <OwnButton
            :text="editItem ? t('actions.update') : t('actions.add')"
            primary
            type="submit"
          >
            <template #icon-right>
              <PhPlusCircle v-if="!editItem" size="24" />
            </template>
          </OwnButton>
        </OwnDialogFooter>
      </template>
    </OwnDialog>
  </OwnForm>
</template>

<script>
import { PhPlusCircle } from '@phosphor-icons/vue'
import { useI18n } from 'vue-i18n'

import { FormBuilder, Validators } from '@/forms'
import { OwnButton } from '@/ui/OwnButton'
import {
  OwnDialog,
  OwnDialogContent,
  OwnDialogFooter,
  OwnDialogHeading,
} from '@/ui/OwnDialog'
import { OwnForm } from '@/ui/OwnForm'
import { OwnInput } from '@/ui/OwnInput'
import { OwnInputContainer } from '@/ui/OwnInputContainer'

export default {
  name: 'AddLinkDialog',
  components: {
    OwnButton,
    OwnDialog,
    OwnDialogContent,
    OwnDialogFooter,
    OwnDialogHeading,
    OwnForm,
    OwnInput,
    OwnInputContainer,
    PhPlusCircle,
  },
  mixins: [
    FormBuilder({
      label: {
        validators: [Validators.required],
      },
      url: {
        validators: [Validators.required, Validators.url],
      },
    }),
  ],
  props: {
    editItem: { type: Object, default: undefined },
  },
  emits: ['close', 'update'],
  setup() {
    const { t } = useI18n()

    return { t }
  },
  created() {
    const { editItem } = this

    if (editItem) {
      this.url.value = editItem.params.url
      this.label.value = editItem.params.label
    }
  },
  methods: {
    closeAndReset() {
      this.$emit('close')

      this.labelReset()
      this.urlReset()
    },
    onUpdate() {
      const { formValues, isFormValid } = this

      if (isFormValid) {
        const { url, label } = formValues

        this.$emit('update', {
          params: {
            label,
            url,
          },
          type: 'external',
        })

        this.closeAndReset()
      }
    },
  },
}
</script>
