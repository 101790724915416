<i18n locale="en">
{
  "no-data": "Your text here"
}
</i18n>

<template>
  <PreviewSheet
    background="primary"
    class="flex-col justify-center align-start py-3"
    :class="sheetClass"
    radius="0"
  >
    <PreviewTypography
      :color="color"
      :variant="variant"
      :text="content.data?.text ?? t('no-data')"
      hotlink-urls
      preserve-whitespace
    />
  </PreviewSheet>
</template>

<script>
import { useI18n } from 'vue-i18n'

import PreviewSheet from '@/lib/builder/public/preview/PreviewSheet.vue'
import PreviewTypography from '@/lib/builder/public/preview/PreviewTypography.vue'

export default {
  name: 'TextPreview',
  components: { PreviewSheet, PreviewTypography },
  props: {
    content: { type: Object, required: true },
  },
  setup() {
    const { t } = useI18n()

    return { t }
  },
  computed: {
    color() {
      switch (this.content.type) {
        case 'heading':
          return 'primary'
        case 'subheading':
          return 'secondary'
        case 'paragraph':
        default:
          return 'secondary'
      }
    },
    sheetClass() {
      switch (this.content.type) {
        case 'heading':
          return 'px-4 py-3'
        case 'subheading':
          return 'px-4 py-2 gap-4 email-subheading'
        case 'paragraph':
        default:
          return 'px-4 py-4 gap-4 email-paragraph'
      }
    },
    variant() {
      switch (this.content.type) {
        case 'heading':
          return 'heading3'
        case 'subheading':
          return 'titleLarge'
        case 'paragraph':
        default:
          return 'paragraphRegular'
      }
    },
  },
}
</script>
