import {
  type RouteLocationNamedRaw,
  type RouteLocationNormalizedGeneric,
} from 'vue-router'

export const getRedirectToLogin = (
  to: RouteLocationNormalizedGeneric
): RouteLocationNamedRaw => {
  return {
    name: 'auth.login',
    query: {
      redirectFrom: to.fullPath,
    },
  }
}
