import { useAuthStore } from '@/auth/useAuthStore'
import { getRedirectToLogin } from '@/router/guards/common/getRedirectToLogin'
import { checkACL, checkRole, checkScopes } from '@/utils/acl'

import { type HermesGuardFn } from './types'

export const checkPermissions: HermesGuardFn = async (to) => {
  const authStore = useAuthStore()

  const user = authStore.user
  const scope = authStore.scopes
  const userAcl = authStore.acl

  const { meta } = to

  if (meta.isPublic) return

  if (!user) return getRedirectToLogin(to)

  if (
    (meta.roles && !checkRole(user.role, meta.roles)) || // Check Role
    (meta.scopes && !checkScopes(scope, meta.scopes)) // Check Scope
  ) {
    return authStore.home
  }

  if (meta.acl) {
    const aclParams = Object.entries(meta.acl).reduce(
      (acc, [collection, routeParam]) =>
        Object.assign(acc, { [collection]: to.params[routeParam] }),
      {}
    )

    if (!checkACL(userAcl, aclParams)) {
      return authStore.home
    }
  }
}
