export const coreEn = {
  nav: {
    links: {
      admin: {
        brands: 'Brands',
        locations: 'Locations',
        onboarding: 'Onboarding',
        subscribe: 'Sales Register',
        usFoodsSubscribe: 'US Foods Register',
        users: 'Users',
      },
      marketing: {
        automations: 'Automated',
        flyers: 'Flyers',
        otc: 'One-time',
        overview: 'Overview',
      },
      reports: {
        dashboard: 'Dashboard',
        reports: 'Reports',
      },
      restaurant: {
        careers: 'Jobs',
        coupon: 'Coupons',
        delivery: 'Delivery',
        devices: 'Devices',
        feedback: 'Feedback',
        'gift-cards': 'Gift Cards',
        hours: 'Hours',
        menu: 'Menu',
        orders: 'Orders',
      },
      tools: {
        'clone-menu': 'Clone Menu',
        'conversion-cards': 'Conversion Cards',
        'delete-location-brand': 'Delete Location/Brand',
        dns: 'DNS',
        'hot-launch': 'Hot Launch',
        'in-store-app-adoption-materials': 'App Adoption Materials',
        onboarding: 'Onboarding',
        'sms-subscriber-import': 'SMS Subscriber Import',
      },
      website: {
        announcements: 'Announcements',
        domains: 'Domains',
        events: 'Events',
        forms: 'Forms',
        integrations: 'Integrations',
        pages: 'Pages',
      },
    },
    toggles: {
      admin: 'Admin',
      customers: 'Customers',
      home: 'Home',
      marketing: 'Marketing',
      reports: 'Reports',
      restaurant: 'Restaurant',
      tools: 'Tools',
      website: 'Website',
    },
  },
  pages: {
    admin: {
      brands: {
        description: 'Find the brands you need quickly.',
        title: 'Brands',
      },
      locations: {
        description: 'Find the locations you need quickly.',
        title: 'Locations',
      },
      onboarding: { title: 'Onboarding' },
      users: { title: 'Users' },
    },
    auth: {
      'direct-register': { title: 'Register' },
      'forgot-password': { title: 'Forgot Password' },
      login: { title: 'Login' },
      'multi-factor-auth': { title: 'Verification Sent' },
      'multi-factor-auth-verify': { title: 'Verifying' },
      register: { title: 'Register' },
      'reset-password': { title: 'Reset Password' },
      subscribe: { title: 'New Subscription' },
    },
    billing: {
      description: 'Keep track of your payments to and from Owner.',
      title: 'Billing',
    },
    customers: { title: 'Customers' },
    fallback: { title: 'Dashboard' },
    home: { title: 'Home' },
    marketing: {
      automations: {
        description:
          'Automatic email campaigns designed and sent on your behalf by Owner.',
        title: 'Marketing',
      },
      flyers: {
        description:
          'Our new conversion cards are the best-in-class system to convert delivery app customers into regulars on your own website. Use this page to request an order of 500 cards -- for free. Expect a new shape in the mail and huge results!',
        title: 'Conversion cards: like flyers but 10x more effective!',
      },
      otc: { title: 'Marketing' },
      overview: {
        description: 'A summary of email performance.',
        title: 'Marketing',
      },
    },
    oauth: {
      google: { title: 'Connecting Google...' },
      stripe: { title: 'Connecting Stripe...' },
    },
    reports: {
      dashboard: { title: 'Dashboard' },
      reports: { title: 'Reports' },
    },
    restaurant: {
      careers: {
        description: 'Manage jobs that are advertised on your website.',
        title: 'Jobs',
      },
      coupons: {
        description:
          'Create and manage coupons. Coupons apply to the total of an order, such as a Military Discount, Birthday Discount, or Student Discount.',
        title: 'Coupons',
      },
      delivery: {
        description:
          'Zones are listed in order of priority. Delivery availability and method will be determined by the first active zone that matches the delivery location.',
        title: 'Delivery',
      },
      devices: {
        description: 'Manage settings for your kitchen tablets.',
        title: 'Devices',
      },
      feedback: {
        description:
          'Manage and respond to feedback you receive from your website.',
        title: 'Feedback',
      },
      'gift-cards': {
        description: 'Manage your online gift cards.',
        title: 'Gift Cards',
      },
      hours: { title: 'Hours' },
      menu: {
        description: 'Set up your menu for online and mobile ordering.',
        title: 'Menu',
      },
      orders: {
        description: 'Manage your orders.',
        title: 'Orders',
      },
    },
    'select-locations': { title: 'Select Locations' },
    settings: { title: 'Settings' },
    'settings-brand': {
      title: 'Brand Settings',
    },
    tools: {
      'clone-menu': {
        description:
          'Clone menus from one location to another. Only eligible locations are enabled as target locations. Certain locations are disabled due to their POS integration.',
        title: 'Clone Menu',
      },
      'conversion-cards': {
        description: 'Generate and download conversion cards.',
        title: 'Conversion Cards',
      },
      'delete-location-brand': {
        description: 'Delete a restaurant location or brand permanently.',
        title: 'Delete Location/Brand',
      },
      dns: {
        description: 'DNS tools',
        title: 'DNS',
      },
      'hot-launch': {
        description: 'Create a new hot launch campaign.',
        title: 'Hot Launch',
      },
      'in-store-app-adoption-materials': {
        description:
          'Request app adoption materials (posters and table tents), and then generate and download QR codes for creating the designs.',
        title: 'App Adoption Materials',
      },
      onboarding: {
        description: 'Manage onboarding actions.',
        title: 'Onboarding',
      },
      'sms-subscriber-import': {
        description:
          'Enable SMS subscriptions for customers who previously opted in to text marketing outside of the Owner system.',
        title: 'SMS Subscriber Import',
      },
    },
    website: {
      announcements: {
        description: 'Add announcement banners to your website.',
        title: 'Announcements',
      },
      domains: {
        description: 'Manage and verify your domains.',
        title: 'Domains',
      },
      events: {
        description: 'Manage events on your website.',
        title: 'Events',
      },
      forms: {
        description:
          'Add and manage forms that you can insert into website pages.',
        title: 'Forms',
      },
      integrations: { title: 'Integrations' },
      pages: {
        description: 'Manage pages in your website.',
        title: 'Pages',
      },
    },
  },
}
