<i18n locale="en">
  {
    "titles": {
      "sent": "Sent",
      "scheduled": "Scheduled"
    },
    "days": {
      "future": "on {date}",
      "today": "Today",
      "tomorrow": "Tomorrow"
    },
    "content": {
      "sent": "This can take a couple of minutes depending on how many people you’re sending it to.",
      "scheduled": "Your campaign will be sent {date} at {time}."
    },
    "actions": {
      "close": "Done",
      "view": "View Campaign"
    }
  }
  </i18n>

<template>
  <OwnDialog :model-value="true" @cancel="emit('close')">
    <template #heading>
      <OwnDialogHeading :title="title" @cancel="emit('close')">
        <template #icon-left>
          <component :is="icon" size="32" :class="color" />
        </template>
      </OwnDialogHeading>
    </template>

    <OwnDialogContent scroll>
      <OwnType
        color="primary"
        variant="paragraph"
        :text="t(`content.${type}`, { date: dateText, time: timeText })"
      />
    </OwnDialogContent>

    <template #footer>
      <OwnDialogFooter>
        <OwnButton :text="actionText" primary @click="handleClick" />
      </OwnDialogFooter>
    </template>
  </OwnDialog>
</template>

<script lang="ts" setup>
import { PhCalendarCheck, PhCheckCircle } from '@phosphor-icons/vue'
import { add, format, isFuture } from 'date-fns'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import { useBuilderRouter } from '@/lib/builder/router/useBuilderRouter'
import { type BuilderModuleConfig } from '@/lib/builder/types'
import { useMappedGetter } from '@/store'
import { OwnButton } from '@/ui/OwnButton'
import {
  OwnDialog,
  OwnDialogContent,
  OwnDialogFooter,
  OwnDialogHeading,
} from '@/ui/OwnDialog'
import { OwnType } from '@/ui/OwnType'

import { BaseCampaignResource, CampaignBlock } from '../../types'

const props = defineProps<{
  builderConfig: BuilderModuleConfig<
    BaseCampaignResource,
    unknown,
    unknown,
    CampaignBlock,
    unknown,
    unknown
  >
}>()

const campaign = useMappedGetter<BaseCampaignResource>(
  `${props.builderConfig.options.storeModule}/currentResource`
)
const executeAt = computed(() =>
  campaign.value?.executeAt ? new Date(campaign.value.executeAt) : new Date()
)

const emit = defineEmits<{
  (event: 'close'): void
}>()

const { t } = useI18n()
const router = useRouter()
const route = useRoute()

const type = computed(() => (isFuture(executeAt.value) ? 'scheduled' : 'sent'))
const title = computed(() => t(`titles.${type.value}`))
const icon = computed(() =>
  type.value === 'sent' ? PhCheckCircle : PhCalendarCheck
)
const color = computed(() =>
  type.value === 'sent' ? 'status-success' : 'status-warning'
)

const dateText = computed(() => {
  // TECH DEBT: could be refactored to use `isToday`/`isTomorrow` to avoid the need to `format`
  const [selected, today, tomorrow] = [
    executeAt.value,
    new Date(),
    add(new Date(), { days: 1 }),
  ].map((date) => format(date, 'MMM do')) as [string, string, string]

  const reservedDay = {
    [today]: 'today',
    [tomorrow]: 'tomorrow',
  }[selected]

  return reservedDay
    ? t(`days.${reservedDay}`)
    : t(`days.future`, { date: selected })
})
const timeText = computed(() => format(executeAt.value, 'hh:mm a'))

const isViewingCampaign = computed(
  () =>
    route.name ===
    `brand.marketing.${props.builderConfig.options.resourceName}.view`
)
const actionText = computed(() =>
  t(`actions.${isViewingCampaign.value ? 'close' : 'view'}`)
)
const handleClick = async () => {
  if (isViewingCampaign.value) {
    emit('close')
  } else {
    const builderRouteManager = useBuilderRouter(props.builderConfig)
    const viewCampaignRoute = await builderRouteManager.customPage(
      'view',
      campaign.value.id
    )
    await router.push(viewCampaignRoute)
  }
}
</script>
