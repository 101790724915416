import { type BuilderTranslationMap } from '@/lib/builder/types'

export const formBuilderEn: BuilderTranslationMap = {
  blocks: {
    address: 'Address',
    date: 'Date & Time',
    email: 'Email',
    headline: 'Heading',
    'multi-select': 'Multiple Choice',
    name: 'Name',
    phone: 'Phone Number',
    select: 'Single Choice',
    subheading: 'Paragraph',
    'text-field': 'Text Field',
  },
  defaults: {
    description: 'Form description goes here.',
    title: 'Form',
  },
  description:
    'Start your form design with a pre-built template or leave it blank.',
  heading: 'Select a template',
  options: {
    blank: 'Blank',
    catering: 'Catering',
    contact: 'Contact',
    event: 'Event',
  },
  'resource-title': {
    placeholder: 'Enter form name',
  },
  templates: {
    catering: {
      'address-label': 'If delivery, enter the address it’s being delivered to',
      'allergies-label': 'Dietary restrictions or allergies',
      'date-label': 'Date and time you need catering',
      description:
        'Please complete the form below to send us a catering request.',
      fulfillment: {
        delivery: 'Delivery',
        label: 'Delivery or pickup?',
        pickup: 'Pickup',
      },
      'items-label': 'What would you like to order?',
      'notes-label': 'Additional details or special instructions',
      number: {
        fifty: '50+',
        forty: '40-49',
        label: 'Number of people you are catering for',
        one: '1-9',
        ten: '10-19',
        thirty: '30-39',
        twenty: '20-29',
      },
      submit: 'Send Catering Request',
      title: 'Catering',
    },
    contact: {
      description:
        'Please complete the form below and we’ll get back to you as soon as we can.',
      message: 'Message',
      title: 'Contact us',
    },
    event: {
      catering: {
        label: 'Will you need catering?',
        no: 'No',
        yes: 'Yes',
      },
      'date-label': 'Date and time of the event',
      'describe-label': 'Describe your event',
      description:
        'Please complete the form below to request to host an event at our restaurant.',
      'event-type': {
        birthday: 'Birthday party',
        corporate: 'Corporate',
        fundraiser: 'Fundraiser',
        label: 'What is the event?',
        other: 'Other',
        wedding: 'Wedding',
      },
      number: {
        fifty: '50+',
        forty: '40-49',
        label: 'Number of people attending',
        one: '1-9',
        ten: '10-19',
        thirty: '30-39',
        twenty: '20-29',
      },
      submit: 'Send event request',
      title: 'Host an event at our restaurant',
    },
  },
  title: 'New Form',
}
