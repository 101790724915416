import difference from 'lodash/difference'
import isEqual from 'lodash/isEqual'

import {
  type BlockCore,
  type BuilderState,
  type ResourceChange,
  type ResourceCore,
} from '../../types'

export const resourceChanges = <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  state: BuilderState<TResource, TBlock>
): ResourceChange<TResource> => {
  const resourceCurrentState = state._builder_currentState.resource

  const resourceOriginalState = state._builder_originalState.resource

  const hasChange = !isEqual(resourceCurrentState, resourceOriginalState)

  let removedBlocks: string[] = []
  if (resourceCurrentState) {
    removedBlocks = difference(
      resourceOriginalState?.sections,
      resourceCurrentState.sections
    )
  }

  return {
    changedResource: resourceCurrentState,
    hasChange,
    removedBlocks,
  }
}
