import { PhProjectorScreen } from '@phosphor-icons/vue'

import { type BuilderBlockModule } from '@/lib/builder/types'
import i18nPlugin from '@/plugins/i18n'

import {
  type WebsiteResource,
  type LandingHeroSectionCreate,
  type LandingHeroSection,
  type LandingHeroSectionUpdate,
} from '../../WebsiteBuilder.types'
import { websiteBlockMethods } from '../common/methods'

import LandingHeroEditor from './editor/LandingHeroEditor.vue'
import { createLandingHeroData } from './methods/createLandingHeroData'
import LandingHeroPreview from './preview/LandingHeroPreview.vue'
const i18n = i18nPlugin.global

export const LandingHeroBlockModule: BuilderBlockModule<
  LandingHeroSection,
  LandingHeroSectionCreate,
  LandingHeroSectionUpdate,
  WebsiteResource
> = {
  canAdd: false,
  canDelete: ({ index }) => index !== 0,
  canReorderDown: ({ index }) => index !== 0,
  canReorderUp: ({ index }) => index !== 0,
  createData: createLandingHeroData,
  editor: LandingHeroEditor,
  meta: [
    {
      icon: PhProjectorScreen,
      key: 'landing-hero',
      label: i18n.t('website-builder.blocks.landing-hero'),
    },
  ],
  methods: websiteBlockMethods,
  preview: LandingHeroPreview,
}
