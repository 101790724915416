<template>
  <div class="h-full">
    <router-view />
    <ToastManager />
    <CrescendoBot />
  </div>
</template>
<script lang="ts" setup>
import { useFavicon } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { onMounted } from 'vue'

import { useAuthStore } from '@/auth/useAuthStore'
import { FeatureFlags } from '@/plugins/featureFlags'

import CrescendoBot from './components/CrescendoBot.vue'
import ToastManager from './components/toasts/ToastManager.vue'
import { useLocaleHandler } from './composables/useLocaleHandler'
import { usePageTitle } from './composables/usePageTitle'
import { useSessionManager } from './composables/useSessionManager'

useLocaleHandler()
usePageTitle()
useSessionManager()
useFavicon(
  import.meta.env.MODE !== 'production'
    ? '/favicon/favicon-dev.ico'
    : '/favicon/favicon.ico'
)

const { user } = storeToRefs(useAuthStore())

const updateFeatureFlagCtx = () => {
  if (user.value) {
    FeatureFlags.setContextField('userId', user.value.email)
  }
}

onMounted(updateFeatureFlagCtx)
</script>
