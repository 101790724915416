import { createRouter, createWebHistory } from 'vue-router'

import { sendPageLoad } from '@/plugins/analytics'

import { checkAuthenticated } from './guards/checkAuthenticated'
import { checkPermissions } from './guards/checkPermissions'
import { checkSessionExpiration } from './guards/checkSessionExpiration'
import routes from './routes'

export const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'is-active',
  routes,
})

const guards = [checkAuthenticated, checkSessionExpiration, checkPermissions]

router.beforeEach(async (to) => {
  for (const guard of guards) {
    const redirect = await guard(to)
    if (redirect) return redirect
  }
})

router.afterEach((to) => {
  if (to?.name && typeof to.name === 'string') sendPageLoad(to.name)
})
