import {
  type BlockCore,
  type BuilderState,
  type ResourceCore,
} from '../../types'

export const discardStagedChangesMutation = <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  state: BuilderState<TResource, TBlock>
): void => {
  const currentCommittedChanges = state._builder_commandQueue

  state._builder_commandQueue = currentCommittedChanges.filter(
    (command) => command.committed
  )
}
