import { PhPaintBrushHousehold } from '@phosphor-icons/vue'

import { type TemplateOption } from '@/lib/builder/types'
import i18nPlugin from '@/plugins/i18n'

import { type SmsSectionCreate, type CampaignResourceCreate } from '../types'

import { blankTemplate } from './blankTemplate'
const i18n = i18nPlugin.global

const templateOptions: Array<
  TemplateOption<CampaignResourceCreate, SmsSectionCreate>
> = [
  {
    fetchData: blankTemplate,
    icon: PhPaintBrushHousehold,
    value: 'blank',
  },
].map((option) => ({
  ...option,
  label: i18n.t(`text-builder.options.${option.value}`),
}))

export default {
  description: i18n.t('text-builder.description'),
  heading: i18n.t('text-builder.heading'),
  options: templateOptions,
  title: i18n.t('text-builder.title'),
}
