import { PhImageSquare } from '@phosphor-icons/vue'

import { type BuilderBlockModule } from '@/lib/builder/types'
import i18nPlugin from '@/plugins/i18n'

import {
  type WebsiteResource,
  type GallerySectionCreate,
  type GallerySection,
  type GallerySectionUpdate,
} from '../../WebsiteBuilder.types'
import { websiteBlockMethods } from '../common/methods'

import GalleryEditor from './editor/GalleryEditor.vue'
import { createGalleryData } from './methods/createGalleryData'
import GalleryPreview from './preview/GalleryPreview.vue'
const i18n = i18nPlugin.global

export const GalleryBlockModule: BuilderBlockModule<
  GallerySection,
  GallerySectionCreate,
  GallerySectionUpdate,
  WebsiteResource
> = {
  createData: createGalleryData,
  editor: GalleryEditor,
  meta: [
    {
      icon: PhImageSquare,
      key: 'gallery',
      label: i18n.t('website-builder.blocks.gallery'),
      sort: 1,
    },
  ],
  methods: websiteBlockMethods,
  preview: GalleryPreview,
}
