<i18n locale="en">
{
  "labels": {
    "email": "Email",
    "phone": "Phone Number",
    "date": "Date/time",
    "name": "Full Name",
    "address": "Address"
  },
  "placeholders": {
    "date": "Select date",
    "time": "Select time",
    "phone": "Phone Number",
    "email": "Email",
    "address": "Address",
    "name": "Aa"
  }
}
</i18n>

<template>
  <PreviewSheet class="formatted-input-preview" border="primary" radius="0">
    <div class="flex-col gap-2">
      <div class="flex-row">
        <PreviewTypographyLegacy :text="title" variant="titleSmall" />
        <PreviewTypographyLegacy
          v-if="required"
          text="*"
          variant="titleSmall"
          color="danger"
        />
      </div>
      <PreviewTextField v-if="type !== 'date'" :placeholder="placeholder" />
      <div v-else class="flex-row gap-2">
        <PreviewTextField
          class="formatted-input-preview__input"
          :placeholder="t('placeholders.date')"
        />
        <PreviewTextField
          class="formatted-input-preview__input"
          :placeholder="t('placeholders.time')"
        />
      </div>
    </div>
  </PreviewSheet>
</template>

<script>
import { useI18n } from 'vue-i18n'

import PreviewTypographyLegacy from '@/lib/builder/public/preview/legacy/PreviewTypographyLegacy.vue'
import PreviewSheet from '@/lib/builder/public/preview/PreviewSheet.vue'
import PreviewTextField from '@/lib/builder/public/preview/PreviewTextField.vue'

export default {
  name: 'FormattedInputPreview',
  components: {
    PreviewSheet,
    PreviewTextField,
    PreviewTypographyLegacy,
  },
  props: {
    content: { type: Object, default: null },

    /**
     * Override data for out of module rendering contexts
     */
    populatedData: { type: Object, default: undefined },
  },
  setup() {
    const { t } = useI18n()

    return { t }
  },
  computed: {
    currentBlock() {
      const { content, populatedData } = this

      return populatedData ?? content
    },
    placeholder() {
      const { type } = this

      return this.t(`placeholders.${type}`)
    },
    required() {
      const { currentBlock } = this

      return currentBlock?.params?.required
    },
    title() {
      const { type, currentBlock } = this

      return currentBlock?.params?.label || this.t(`labels.${type}`)
    },
    type() {
      const { currentBlock } = this

      return currentBlock?.type
    },
  },
}
</script>

<style lang="scss" scoped>
.formatted-input-preview {
  padding: 12px 40px;

  &__input {
    width: 100%;
  }
}
</style>
