import {
  PhForkKnife,
  PhCalendarBlank,
  PhFile,
  PhDeviceMobile,
} from '@phosphor-icons/vue'

import { type TemplateOption } from '@/lib/builder/types'
import i18nPlugin from '@/plugins/i18n'

import {
  type FormCreate,
  type FormSectionCreate,
} from '../../types/FormBuilder.types'

import { blankTemplate } from './blankTemplate'
import { cateringTemplate } from './cateringTemplate'
import { contactTemplate } from './contactTemplate'
import { eventTemplate } from './eventTemplate'
const i18n = i18nPlugin.global

const templateOptions: Array<TemplateOption<FormCreate, FormSectionCreate>> = [
  {
    fetchData: contactTemplate,
    icon: PhDeviceMobile,
    value: 'contact',
  },
  {
    fetchData: eventTemplate,
    icon: PhCalendarBlank,
    value: 'event',
  },
  {
    fetchData: cateringTemplate,
    icon: PhForkKnife,
    value: 'catering',
  },
  {
    fetchData: blankTemplate,
    icon: PhFile,
    value: 'blank',
  },
].map((option) => ({
  ...option,
  label: i18n.t(`form-builder.options.${option.value}`),
}))

export default {
  description: i18n.t('form-builder.description'),
  heading: i18n.t('form-builder.heading'),
  options: templateOptions,
  title: i18n.t('form-builder.title'),
}
