import {
  type StoreExtension,
  type StoreExtensionOptions,
} from '@/lib/store/StoreModule'
import { type CoreModule } from '@/lib/store/types'
import { removeObserver } from '@/utils/helpers'

import {
  type BlockCore,
  type BuilderCoreState,
  type BuilderState,
  type ResourceCore,
} from '../types'

import { applyCommand } from './actions/applyCommand'
import { blockAdd } from './actions/blockAdd'
import { blockDelete } from './actions/blockDelete'
import { commitStagedCommands } from './actions/commitStagedCommands'
import { dequeueCommands } from './actions/dequeueCommands'
import { discardStagedCommands } from './actions/discardStagedCommands'
import { enqueueCommand } from './actions/enqueueCommand'
import { forceDiscardAllCommands } from './actions/forceDiscardAllCommands'
import { initializeData } from './actions/initializeData'
import { publishChanges } from './actions/publishChanges'
import { resetBlock } from './actions/resetBlock'
import { resetResource } from './actions/resetResource'
import { resourceSectionAdd } from './actions/resourceSectionAdd'
import { resourceSectionDelete } from './actions/resourceSectionDelete'
import { resourceSectionReorder } from './actions/resourceSectionReorder'
import { revertCommand } from './actions/revertCommand'
import { sanitize } from './actions/sanitize'
import { setEditorMode } from './actions/setEditorMode'
import { setReturnLocation } from './actions/setReturnLocation'
import { stepToCommand } from './actions/stepToCommand'
import { updateResource } from './actions/updateResource'
import { blockById } from './getters/blockById'
import { blockChanges } from './getters/blockChanges'
import { currentBlocks } from './getters/currentBlocks'
import { currentResource } from './getters/currentResource'
import { getEditorMode } from './getters/getEditorMode'
import { hasUnsavedChanges } from './getters/hasUnsavedChanges'
import { resourceChanges } from './getters/resourceChanges'
import { returnLocation } from './getters/returnLocation'
import { blockAddMutation } from './mutations/blockAddMutation'
import { blockDeleteMutation } from './mutations/blockDeleteMutation'
import { commitStagedChangesMutation } from './mutations/commitStagedChangesMutation'
import { dequeueBlockCommandsMutation } from './mutations/dequeueBlockCommandsMutation'
import { dequeueResourceCommandsMutation } from './mutations/dequeueResourceCommandsMutation'
import { discardStagedChangesMutation } from './mutations/discardStagedChangesMutation'
import { forceDiscardAllCommandsMutation } from './mutations/forceDiscardAllCommandsMutation'
import { initializeDataMutation } from './mutations/initializeDataMutation'
import { pushCommandMutation } from './mutations/pushCommandMutation'
import { setBlockMutation } from './mutations/setBlockMutation'
import { setCurrentCommandMutation } from './mutations/setCurrentCommandMutation'
import { setEditorModeMutation } from './mutations/setEditorModeMutation'
import { setResourceMutation } from './mutations/setResourceMutation'
import { setReturnLocationMutation } from './mutations/setReturnLocationMutation'

const BuilderExtension = <
  TBlock extends BlockCore,
  TResource extends ResourceCore,
>(
  options: StoreExtensionOptions<BuilderCoreState<TResource, TBlock>>
): StoreExtension<BuilderState<TResource, TBlock>, CoreModule> => {
  const fallbackDefaultState: BuilderCoreState<TResource, TBlock> = {
    blocks: [],
    resource: undefined,
  }

  const defaultState: BuilderState<TResource, TBlock> = {
    _builder_commandQueue: [],
    _builder_currentCommand: undefined,
    _builder_currentState: options.defaultState ?? fallbackDefaultState,
    _builder_editorMode: 'build',
    _builder_isClosing: false,
    _builder_originalState: options.defaultState ?? fallbackDefaultState,
    _builder_returnLocation: undefined,
    moduleName: options.moduleName,
  }

  return {
    actions: {
      _builder_applyCommand: applyCommand,
      _builder_blockAdd: blockAdd,
      _builder_blockDelete: blockDelete,
      _builder_dequeueCommands: dequeueCommands,
      _builder_enqueue: enqueueCommand,
      _builder_forceDiscardAllCommands: forceDiscardAllCommands,
      _builder_initializeData: initializeData,
      _builder_resetBlock: resetBlock,
      _builder_resetResource: resetResource,
      _builder_resourceSectionAdd: resourceSectionAdd,
      _builder_resourceSectionDelete: resourceSectionDelete,
      _builder_resourceSectionReorder: resourceSectionReorder,
      _builder_revertCommand: revertCommand,
      _builder_sanitize: sanitize,
      _builder_setEditMode: setEditorMode,
      _builder_setIsClosing: ({ commit }, isClosing: boolean) => {
        commit('_builder_SET_IS_CLOSING', isClosing)
      },
      _builder_setReturnLocation: setReturnLocation,
      _builder_stepTo: stepToCommand,
      commitStagedCommands,
      discardStagedCommands,
      publishChanges,
      updateResource,
    },
    getters: {
      _builder_isClosing: (state) => state._builder_isClosing,
      blockById,
      blockChanges,
      currentBlocks,
      currentResource,
      editMode: getEditorMode,
      hasUnsavedChanges,
      resourceChanges,
      returnLocation,
    },
    mutations: {
      _builder_BLOCK_ADD: blockAddMutation,
      _builder_BLOCK_DELETE: blockDeleteMutation,
      _builder_COMMIT_STAGED_CHANGES: commitStagedChangesMutation,
      _builder_DEQUEUE_BLOCK_COMMANDS: dequeueBlockCommandsMutation,
      _builder_DEQUEUE_RESOURCE_COMMANDS: dequeueResourceCommandsMutation,
      _builder_DISCARD_STAGED_CHANGES: discardStagedChangesMutation,
      _builder_FORCE_DISCARD_ALL_COMMANDS: forceDiscardAllCommandsMutation,
      _builder_INITIALIZE_DATA: initializeDataMutation,
      _builder_PUSH_COMMAND: pushCommandMutation,
      _builder_SANITIZE: (state: BuilderState<TResource, TBlock>): void => {
        const mutSafeDefaultState = removeObserver(defaultState)

        state._builder_commandQueue = mutSafeDefaultState._builder_commandQueue
        state._builder_currentCommand =
          mutSafeDefaultState._builder_currentCommand
        state._builder_currentState = mutSafeDefaultState._builder_currentState
        state._builder_editorMode = mutSafeDefaultState._builder_editorMode
        state._builder_originalState =
          mutSafeDefaultState._builder_originalState
        state._builder_returnLocation =
          mutSafeDefaultState._builder_returnLocation
      },
      _builder_SET_BLOCK: setBlockMutation,
      _builder_SET_CURRENT_COMMAND: setCurrentCommandMutation,
      _builder_SET_EDITOR_MODE: setEditorModeMutation,
      _builder_SET_IS_CLOSING: (state, isClosing: boolean): void => {
        state._builder_isClosing = isClosing
      },
      _builder_SET_RESOURCE: setResourceMutation,
      _builder_SET_RETURN_LOCATION: setReturnLocationMutation,
    },
    state: removeObserver(defaultState),
  }
}

export { BuilderExtension }
