/* eslint-disable sort-keys-fix/sort-keys-fix, vue/sort-keys -- Allows for logical ordering */
import { datadogRum } from '@datadog/browser-rum'

const MODE = import.meta.env.MODE

const APP_VERSION = import.meta.env.APP_VERSION

const DD_APPLICATION_ID = 'c85b11fc-0d2a-4393-9d8b-8211505c338b'

const DD_CLIENT_TOKEN = 'pub8fc6b4024c42123da8d79c31c993412b'

export const initializeDatadog = (): void => {
  if (MODE === 'production' || MODE === 'staging') {
    datadogRum.init({
      applicationId: DD_APPLICATION_ID,
      clientToken: DD_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'hermes',

      // By default vite uses `production` and `development` but we want to remap to
      // `dev` and `prod` to follow the pattern in DD.
      env: MODE === 'production' ? 'prod' : 'dev',
      version: APP_VERSION,

      sessionReplaySampleRate: 20,
      sessionSampleRate: MODE === 'production' ? 50 : 100,

      trackUserInteractions: true,
      trackLongTasks: true,
      trackResources: true,
      defaultPrivacyLevel: 'mask-user-input',

      allowedTracingUrls: [/https?:\/\/(\w+\.)?owner.com/],

      beforeSend: (event) => {
        if (
          event.type === 'error' &&
          (event.error.message.includes(
            'ResizeObserver loop completed with undelivered notifications'
          ) ||
            event.error.message.includes('ResizeObserver loop limit exceeded'))
        ) {
          return false
        }

        return true
      },
    })
  }
}

/* eslint-enable sort-keys-fix/sort-keys-fix, vue/sort-keys */
