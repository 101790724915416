<i18n locale="en">
{
  "labels": {
    "carousel-assets": "Carousel photos",
    "headline": "Title",
    "layout": "Layout"
  },
  "layout": {
    "card": "1",
    "cover": "2",
    "cover-center": "3"
  },
  "placeholders": {
    "headline": "Aa"
  }
}
</i18n>

<template>
  <div class="flex-col gap-8">
    <OwnInputContainer :label="t('labels.layout')">
      <OwnSegmentedControl
        :options="layoutOptions"
        :model-value="currentBlockParams.layout"
        @update:model-value="applyLandingHeroUpdate({ layout: $event })"
      />
    </OwnInputContainer>
    <OwnInputContainer
      :label="t('labels.headline')"
      :errors="headline.status.errors"
    >
      <OwnInput
        v-model="headline.value"
        :placeholder="t('placeholders.headline')"
      />
    </OwnInputContainer>
    <OwnInputContainer :label="t('labels.carousel-assets')">
      <HeroCarouselEditor :id="id" />
    </OwnInputContainer>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'

import { FormBuilder, Validators } from '@/forms'
import { OwnInput } from '@/ui/OwnInput'
import { OwnInputContainer } from '@/ui/OwnInputContainer'
import { OwnSegmentedControl } from '@/ui/OwnSegmentedControl'

import { LandingHeroCommands } from '../commands/LandingHeroCommands'

import HeroCarouselEditor from './HeroCarouselEditor'

export default {
  name: 'LandingHeroEditor',
  components: {
    HeroCarouselEditor,
    OwnInput,
    OwnInputContainer,
    OwnSegmentedControl,
  },
  mixins: [
    FormBuilder({
      headline: {
        debounce: 250,
        validateOnInit: true,
        validators: [Validators.required],
      },
    }),
  ],
  props: {
    /**
     * Id getting passed to the editor component
     */
    id: { type: String, required: true },
  },
  setup() {
    const { t } = useI18n()

    return { t }
  },
  data() {
    return {
      layoutOptions: ['card', 'cover', 'cover-center'].map((layoutOption) => ({
        label: this.t(`layout.${layoutOption}`),
        value: layoutOption,
      })),
    }
  },
  computed: {
    ...mapGetters({
      blockById: 'websiteBuilder/blockById',
    }),
    currentBlockParams() {
      const { id } = this

      return this.blockById(id)?.params
    },
  },
  created() {
    const { currentBlockParams } = this

    this.setInitialFormValues({ headline: currentBlockParams.headline })
  },
  methods: {
    async applyLandingHeroUpdate(newValue) {
      const { id } = this

      await LandingHeroCommands.updateBlock({
        targetId: id,
        update: {
          params: {
            ...newValue,
          },
        },
      })
    },
    async onFormControlUpdate(controlName, value) {
      await this.applyLandingHeroUpdate({ [controlName]: value })
    },
  },
}
</script>
