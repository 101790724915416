import {
  type BlockCore,
  type BuilderBlockModule,
  type FlatBuilderBlockModule,
  type ResourceCore,
} from '../types'

export const flattenBlockModules = <
  TBlock extends BlockCore,
  TBlockCreate,
  TBlockUpdate,
  TResource extends ResourceCore,
>(
  blockModules: Array<
    BuilderBlockModule<TBlock, TBlockCreate, TBlockUpdate, TResource>
  >
): Array<
  FlatBuilderBlockModule<TBlock, TBlockCreate, TBlockUpdate, TResource>
> => {
  return blockModules.flatMap((module) => {
    const { meta, ...moduleCore } = module

    return meta.map((moduleMeta) => ({
      ...moduleCore,
      ...moduleMeta,
    }))
  })
}
