<i18n locale="en">
{
  "label": "Submit Button Title",
  "placeholder": "(optional)"
}
</i18n>

<template>
  <div class="flex-col gap-8">
    <OwnInputContainer :label="t('label')">
      <OwnInput v-model="submit.value" :placeholder="t('placeholder')" />
    </OwnInputContainer>
  </div>
</template>

<script>
import set from 'lodash/set'
import { useI18n } from 'vue-i18n'
import { mapActions, mapGetters } from 'vuex'

import { FormBuilder } from '@/forms'
import { OwnInput } from '@/ui/OwnInput'
import { OwnInputContainer } from '@/ui/OwnInputContainer'

export default {
  name: 'FormFooterEditor',
  components: {
    OwnInput,
    OwnInputContainer,
  },
  mixins: [
    FormBuilder({
      submit: {
        debounce: 250,
        validators: [],
      },
    }),
  ],
  computed: {
    ...mapGetters({
      currentResource: 'formBuilder/currentResource',
    }),
  },
  setup() {
    const { t } = useI18n()

    return { t }
  },
  created() {
    const { currentResource } = this

    this.setInitialFormValues({ submit: currentResource.submit })
  },
  methods: {
    ...mapActions({
      updateResource: 'formBuilder/updateResource',
    }),
    async onFormControlUpdate(controlName, update) {
      await this.updateResource(set({}, controlName, update || null))
    },
  },
}
</script>
