import { BuilderExtension } from '@/lib/builder/extension/BuilderExtension'
import { type BuilderState } from '@/lib/builder/types'
import { Store } from '@/lib/store/StoreModule'

import {
  type WebsiteSection,
  type WebsiteResource,
} from '../WebsiteBuilder.types'

export interface WebsiteBuilderState {
  // this is only here because eslint won't let me define an empty interface...
  pages?: string[]
}

const websiteBuilder = Store.extend<
  WebsiteBuilderState,
  BuilderState<WebsiteResource, WebsiteSection>
>(
  {
    actions: {},
    getters: {},
    mutations: {},
    state: {},
  },
  BuilderExtension({
    moduleName: 'websiteBuilder',
  })
)

const websiteBuilderModule = Store.createModule(websiteBuilder, true)

export default websiteBuilderModule
