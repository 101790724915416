<i18n locale="en">
{
  "actions": {
    "delete": "Delete",
    "edit": "Edit"
  }
}
</i18n>

<template>
  <OwnActionMenu
    class="flex-shrink-0"
    :actions="actions"
    @action="$emit('action', { action: $event, item })"
  >
    <template #trigger>
      <PhDotsThreeCircle class="text-color-secondary" size="24" />
    </template>
  </OwnActionMenu>
</template>

<script>
import { PhDotsThreeCircle, PhPencil, PhTrash } from '@phosphor-icons/vue'
import { useI18n } from 'vue-i18n'

import { OwnActionMenu } from '@/ui/actions/OwnActionMenu'

export default {
  name: 'JobActionMenu',
  components: { OwnActionMenu, PhDotsThreeCircle },
  props: {
    item: { type: Object, required: true },
  },
  emits: ['action'],
  setup() {
    const { t } = useI18n()

    return { t }
  },
  computed: {
    actions() {
      const actions = [
        {
          icon: PhPencil,
          value: 'edit',
        },
        {
          color: 'danger',
          icon: PhTrash,
          value: 'delete',
        },
      ]

      return actions.map((action) => ({
        ...action,
        label: this.t(`actions.${action.value}`),
      }))
    },
  },
}
</script>
