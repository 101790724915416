import mergeWith from 'lodash/mergeWith'
import { type PartialDeep } from 'type-fest'
import { type ActionContext } from 'vuex'

import { type CoreModule } from '@/lib/store/types'
import { logError } from '@/logger'
import { removeObserver } from '@/utils/helpers'

import {
  type BlockCore,
  type BuilderState,
  type ResourceCore,
} from '../../types'
import { useBuilder } from '../useBuilder'

export const resourceSectionAdd = async <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  { state }: ActionContext<BuilderState<TResource, TBlock>, CoreModule>,
  { blockId, insertIndex }: { blockId: string; insertIndex: number | undefined }
): Promise<void> => {
  const currentResource = state._builder_currentState?.resource

  if (!currentResource) {
    return logError('Unable to add section to undefined resource')
  }

  const normalizedInsertIndex =
    insertIndex ?? currentResource.sections.length - 1

  const moduleName = state.moduleName

  const builderModule = useBuilder(moduleName)

  const createResourceCommand = builderModule.createResourceCommand<TResource>({
    commit: async (currentData, ctx) => {
      return mergeWith(currentData, ctx.update, (_objValue, srcValue, key) => {
        // Overwrite `sections` (array op) rather than merging
        if (key === 'sections') {
          return srcValue
        }
      })
    },
  })

  const sanitizedResource = removeObserver(currentResource)

  await createResourceCommand({
    update: {
      sections: [
        ...sanitizedResource.sections.slice(0, normalizedInsertIndex + 1),
        blockId,
        ...sanitizedResource.sections.slice(normalizedInsertIndex + 1),
      ],
    },
  } as { update: PartialDeep<TResource> })
}
