import { OlympusClient } from '@/OlympusClient'

import {
  type WebsiteSection,
  type WebsiteSectionUpdate,
} from '../../WebsiteBuilder.types'

export const updateBlockHandler = async <
  TSection extends WebsiteSection,
  TSectionUpdate extends WebsiteSectionUpdate,
>(
  id: string,
  body: TSectionUpdate
): Promise<TSection> => {
  return (await OlympusClient.funnel.v1.sections.section.updateSection({
    body,
    params: {
      sectionId: id,
    },
  })) as TSection
}
