<i18n locale="en">
{
  "labels": {
    "all": "All Locations",
    "copy-id": "Copy ID",
    "copy-success": "Location ID copied to clipboard"
  }
}
</i18n>

<template>
  <OwnDropdown
    v-if="currentBrandId && isMultiLocation"
    :filter-keys="['label']"
    :options="options"
    :model-value="selectedLocation"
    @update:model-value="onLocationSelect"
  >
    <template #right="{ item }">
      <InfoPopover content-width="75">
        <template #trigger>
          <Copyable
            v-if="isSuperAdmin && item.value !== 'all'"
            :value="item.value"
            :success-message="t('labels.copy-success')"
            icon-only
          />
        </template>
        <template #content>
          <OwnType
            :text="t('labels.copy-id')"
            variant="paragraph-small"
            color="primary"
          />
        </template>
      </InfoPopover>
    </template>
  </OwnDropdown>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import { useAuthStore } from '@/auth/useAuthStore'
import InfoPopover from '@/components/popovers/InfoPopover.vue'
import Copyable from '@/components/utils/Copyable.vue'
import { useRouterQueryParam } from '@/composables/useRouterMappedQueryParams'
import { useMappedAction, useMappedGetter } from '@/store'
import { OwnDropdown } from '@/ui/OwnDropdown'
import { OwnType } from '@/ui/OwnType'

import { Location } from '../../types'

const { t } = useI18n()

const route = useRoute()
const router = useRouter()

const [queryParamLocationId, setQueryParamLocationId] =
  useRouterQueryParam('locationId')

const currentBrandId = useMappedGetter<string | undefined>(
  'core/brand/active/id'
)

const locations = useMappedGetter<Location[] | undefined>(
  'core/brand/active/locations'
)

const activeLocation = useMappedGetter<string | undefined>(
  'core/location/active/id'
)

const { isSuperAdmin } = storeToRefs(useAuthStore())

const setActiveLocation = useMappedAction('core/location/setActive')

const isMultiLocation = computed(() => {
  return locations.value && locations.value.length > 1
})

const selectedLocation = computed<string>(() => {
  return activeLocation.value ?? 'all'
})

const options = computed(() => {
  if (!locations.value) return []

  const staticOptions = [
    {
      label: t('labels.all'),
      value: 'all',
    },
  ]

  return [
    ...staticOptions,
    ...locations.value.map((location: Location) => ({
      label: location.name,
      value: location.id,
    })),
  ]
})

const navToLocationPage = (locationToSet: string | undefined) => {
  if (currentBrandId.value && locationToSet) {
    if (route?.meta?.locationRoute) {
      return router.push({
        name: route.meta.locationRoute,
        params: {
          brandId: currentBrandId.value,
          locationId: locationToSet,
        },
      })
    } else {
      return router.push({
        name:
          typeof route.name === 'string'
            ? route.name.replace('brand', 'location')
            : route.name,
        params: {
          brandId: currentBrandId.value,
          locationId: locationToSet,
        },
      })
    }
  }
}

const navToBrandPage = () => {
  if (currentBrandId.value) {
    if (route?.meta?.brandRedirect) {
      return router.push({
        name: route.meta.brandRedirect,
        params: {
          brandId: currentBrandId.value,
        },
      })
    } else {
      return router.push({
        name:
          typeof route.name === 'string'
            ? route.name.replace('location', 'brand')
            : route.name,
        params: {
          brandId: currentBrandId.value,
        },
      })
    }
  }
}

const onLocationSelect = (selectedLocation: string) => {
  const locationToSet =
    selectedLocation === 'all' ? undefined : selectedLocation

  setActiveLocation(locationToSet)
  setQueryParamLocationId(locationToSet)

  if (route?.meta?.redirectToLocation || route?.meta?.isLocationPage) {
    if (locationToSet === undefined) {
      navToBrandPage()
    } else {
      navToLocationPage(locationToSet)
    }
  }
}

onMounted(() => {
  if (queryParamLocationId.value) {
    const foundLocation = locations.value?.find(
      (location) => location.id === queryParamLocationId.value
    )

    // If the query param location is loaded in store we activate it, otherwise it's an improper
    // cross-brand url and we remove the query param
    if (foundLocation) {
      setActiveLocation(queryParamLocationId.value)
    } else {
      setQueryParamLocationId(undefined)
    }
  }
})
</script>
