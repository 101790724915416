import {
  type BlockCore,
  type BuilderState,
  type ResourceCore,
} from '../../types'

export const getEditorMode = <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  state: BuilderState<TResource, TBlock>
): string => {
  return state._builder_editorMode
}
