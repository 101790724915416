import { type ActionContext } from 'vuex'

import { type CoreModule } from '@/lib/store/types'

import {
  type BlockCore,
  type BuilderState,
  type Command,
  type ResourceCore,
} from '../../types'

export const revertCommand = async <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  { state, commit }: ActionContext<BuilderState<TResource, TBlock>, CoreModule>,
  command: Command<TBlock>
): Promise<void> => {
  const currentBlocks = state._builder_currentState.blocks

  const editBlockIndex = currentBlocks.findIndex(
    (block) => block.id === command.context.targetId
  )

  if (editBlockIndex !== -1) {
    switch (command.context.scope) {
      case 'block':
        commit('_builder_SET_BLOCK', {
          data: command.context.revertData,
        })
        break

      case 'resource':
        commit('_builder_SET_RESOURCE', {
          data: command.context.revertData,
        })
        break

      default:
        break
    }
  }

  commit('_builder_SET_CURRENT_COMMAND', command.context.prevCommand)
}
