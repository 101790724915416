import { type ActionContext } from 'vuex'

import { type CoreModule } from '@/lib/store/types'

import {
  type BlockCore,
  type BuilderEditorModes,
  type BuilderState,
  type ResourceCore,
} from '../../types'

export const setEditorMode = async <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  { commit }: ActionContext<BuilderState<TResource, TBlock>, CoreModule>,
  newEditMode: BuilderEditorModes
): Promise<void> => {
  commit('_builder_SET_EDITOR_MODE', newEditMode)
}
