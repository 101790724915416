<i18n locale="en">
{
  "create": "Create",
  "error": "Must provide a name",
  "name": {
    "description": "A brief description or summary that highlights the content or purpose.",
    "label": "Text Name",
    "placeholder": "My Text"
  },
  "title": "Create Text"
}
</i18n>

<template>
  <OwnDialog :model-value="show" @cancel="emit('cancel')">
    <template #heading>
      <OwnDialogHeading :title="t('title')" @cancel="emit('cancel')" />
    </template>

    <OwnDialogContent scroll>
      <OwnInputContainer
        :label="t('name.label')"
        :description="t('name.description')"
        :errors="errors"
      >
        <OwnInput v-model="name" :placeholder="t('name.placeholder')" />
      </OwnInputContainer>
    </OwnDialogContent>

    <template #footer>
      <OwnDialogFooter>
        <OwnButton
          :text="t('create')"
          primary
          :processing="isCreating"
          @click="onCreate"
        >
          <template #icon-right>
            <PhArrowCircleRight size="24" />
          </template>
        </OwnButton>
      </OwnDialogFooter>
    </template>
  </OwnDialog>
</template>

<script lang="ts" setup>
import { PhArrowCircleRight } from '@phosphor-icons/vue'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { OwnButton } from '@/ui/OwnButton'
import {
  OwnDialog,
  OwnDialogContent,
  OwnDialogFooter,
  OwnDialogHeading,
} from '@/ui/OwnDialog'
import { OwnInput } from '@/ui/OwnInput'
import { OwnInputContainer } from '@/ui/OwnInputContainer'

withDefaults(
  defineProps<{
    isCreating: boolean
    show?: boolean
  }>(),
  { show: false }
)

const { t } = useI18n()
const name = ref('')
const errors = ref<string[]>([])

const emit = defineEmits<{
  (event: 'cancel'): void
  (event: 'create', overrides: { name: string }): void
}>()

const onCreate = () => {
  if (!name.value) {
    errors.value = [t('error')]
    return
  }
  emit('create', { name: name.value })
}

watch(name, () => {
  if (errors.value?.length && name.value) {
    errors.value = []
  }
})
</script>
