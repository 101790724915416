import { type ActionContext } from 'vuex'

import { type CoreModule } from '@/lib/store/types'

import {
  type BlockCore,
  type BuilderState,
  type ResourceCore,
} from '../../types'

export const dequeueCommands = async <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  { commit, state }: ActionContext<BuilderState<TResource, TBlock>, CoreModule>,
  { scope, id }: { scope: 'block' | 'resource'; id?: string }
): Promise<void> => {
  switch (scope) {
    case 'resource':
      commit('_builder_DEQUEUE_RESOURCE_COMMANDS')

      break

    case 'block':
      commit('_builder_DEQUEUE_BLOCK_COMMANDS', id)

      break

    default:
      throw new Error('command type not implemented')
  }

  const currentCommands = state._builder_commandQueue

  commit(
    '_builder_SET_CURRENT_COMMAND',
    currentCommands[currentCommands.length - 1]?.id
  )
}
