<i18n locale="en">
{
  "links": {
    "terms": "Terms of use",
    "privacy": "Privacy policy",
    "unsubscribe": "Unsubscribe",
    "feedback": "Share feedback",
    "owner": "Made with Owner",
    "unsubscribeLine": "If you no longer wish to receive these emails, you can unsubscribe."
  }
}
</i18n>

<template>
  <PreviewSheet radius="0">
    <PreviewSheet radius="0" class="gap-10" background="primary">
      <span><!-- This is a gap between footer and content --></span>
    </PreviewSheet>
    <div v-if="hasLoyalty" class="px-4 py-3">
      <EmailLoyalty />
    </div>
    <div class="flex-col pa-4 gap-4 w-full">
      <div class="flex-row gap-2">
        <img :src="instagramIcon" height="16" />
        <img :src="facebookIcon" height="16" />
      </div>
      <div class="flex-row gap-0">
        <PreviewTypography
          :text="t('links.unsubscribeLine')"
          color="secondary"
          variant="paragraphSmall"
        />
      </div>
      <div>
        <PreviewTypography
          :text="`© ${currentYear} ${brand.name}. All rights reserved.`"
          color="secondary"
          variant="paragraphSmall"
        />
      </div>
    </div>
  </PreviewSheet>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'

import { useMergedTheme } from '@/lib/builder/public/preview/mixins/useMergedTheme'
import PreviewSheet from '@/lib/builder/public/preview/PreviewSheet.vue'
import PreviewTypography from '@/lib/builder/public/preview/PreviewTypography.vue'

import EmailLoyalty from './EmailLoyalty.vue'

export default {
  name: 'EmailFooter',
  components: {
    EmailLoyalty,
    PreviewSheet,
    PreviewTypography,
  },
  mixins: [useMergedTheme],
  setup() {
    const { t } = useI18n()

    return { t }
  },
  computed: {
    ...mapGetters({
      brand: 'core/brand/active/*',
      campaign: 'emailBuilder/currentResource',
      locations: 'core/brand/active/locations',
    }),
    currentYear() {
      return new Date().getFullYear()
    },
    facebookIcon() {
      const { mergedTheme } = this

      if (mergedTheme.palette === 'dark') {
        return '/static/social-icons/facebook-dark.svg'
      } else {
        return '/static/social-icons/facebook.svg'
      }
    },
    hasLoyalty() {
      const { campaign, locations } = this
      if (!campaign) return false
      const { locationId } = campaign

      if (locationId) {
        const location = locations.find(
          (location) => location.id === locationId
        )

        if (location) {
          return location.features.loyalty
        }

        return false
      }

      return locations.some(({ features: { loyalty } }) => !!loyalty)
    },
    instagramIcon() {
      const { mergedTheme } = this

      if (mergedTheme.palette === 'dark') {
        return '/static/social-icons/instagram-dark.svg'
      } else {
        return '/static/social-icons/instagram.svg'
      }
    },
    ownerIcon() {
      const { mergedTheme } = this

      if (mergedTheme.palette === 'dark') {
        return '/branding/owner-profile-round-dark.svg'
      } else {
        return '/branding/owner-profile-round.svg'
      }
    },
  },
}
</script>
