import { type BuilderBlockModule } from '@/lib/builder/types'

import {
  type SmsSectionCreate,
  type SmsSection,
  type SmsSectionUpdate,
  type CampaignResource,
} from '../types'

import { TextBlockModule } from './TextSection'

export const TextBuilderBlocks: Array<
  BuilderBlockModule<
    SmsSection,
    SmsSectionCreate,
    SmsSectionUpdate,
    CampaignResource
  >
> = [TextBlockModule]
