import { PhTextT, PhTextAlignLeft } from '@phosphor-icons/vue'

import { type BuilderBlockModule } from '@/lib/builder/types'
import i18nPlugin from '@/plugins/i18n'

import type {
  Form,
  HeadlineSubheadingSection,
  HeadlineSubheadingSectionCreate,
  HeadlineSubheadingSectionUpdate,
} from '../../../types/FormBuilder.types'
import { formBlockMethods } from '../common/methods'

import HeadlineEditor from './editor/HeadlineEditor.vue'
import { createHeadlineData } from './methods/createHeadlineData'
import HeadlinePreview from './preview/HeadlinePreview.vue'
const i18n = i18nPlugin.global

export const HeadlineBlockModule: BuilderBlockModule<
  HeadlineSubheadingSection,
  HeadlineSubheadingSectionCreate,
  HeadlineSubheadingSectionUpdate,
  Form
> = {
  createData: createHeadlineData,
  editor: HeadlineEditor,
  meta: [
    {
      icon: PhTextT,
      key: 'headline',
      label: i18n.t('form-builder.blocks.headline'),
      sort: 7,
    },
    {
      icon: PhTextAlignLeft,
      key: 'subheading',
      label: i18n.t('form-builder.blocks.subheading'),
      sort: 9,
    },
  ],
  methods: formBlockMethods,
  preview: HeadlinePreview,
}
