import isEqual from 'lodash/isEqual'

import {
  type BlockChanges,
  type BlockCore,
  type BuilderState,
  type ResourceCore,
} from '../../types'

export const blockChanges = <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  state: BuilderState<TResource, TBlock>
): BlockChanges<TBlock> => {
  const blocksCurrentState = state._builder_currentState.blocks

  const blocksOriginalState = state._builder_originalState.blocks

  const changedBlocks = []

  for (const currentBlock of blocksCurrentState) {
    const originalVersion = blocksOriginalState.find(
      (originalBlock) => originalBlock.id === currentBlock.id
    )

    if (!originalVersion || !isEqual(currentBlock, originalVersion)) {
      changedBlocks.push(currentBlock)
    }
  }

  return {
    changedBlocks,
    hasChange: changedBlocks.length > 0,
  }
}
