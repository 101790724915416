import store from '../index'

/**
 * Maps to store accessor.
 * @param accessor Action accessor eg 'core/brand/setActive'
 * @example const logout = useMappedAction('core/brand/setActive')
 */
export const useMappedAction = (
  accessor: string
): ((payload?: unknown) => Promise<unknown>) => {
  return async (payload?: unknown) => await store.dispatch(accessor, payload)
}
