import {
  type BlockCore,
  type BuilderState,
  type ResourceCore,
} from '../../types'

export const dequeueResourceCommandsMutation = <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  state: BuilderState<TResource, TBlock>
): void => {
  state._builder_commandQueue = state._builder_commandQueue.filter(
    (command) => command.context.scope !== 'resource'
  )
}
