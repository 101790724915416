import { FormBlockModule } from '@/features/form-builder/public/website-builder-module/FormBlockModule'
import { LocationsBlockModule } from '@/features/website-builder/builder/blocks/location'
import { type BuilderBlockModule } from '@/lib/builder/types'

import {
  type WebsiteResource,
  type WebsiteSection,
  type WebsiteSectionCreate,
  type WebsiteSectionUpdate,
} from '../WebsiteBuilder.types'

import { CareersBlockModule } from './careers'
import { ContentBlockModule } from './content'
import { EventCalendarModule } from './events'
import { FaqBlockModule } from './faq'
import { FeaturesBlockModule } from './features'
import { GalleryBlockModule } from './gallery'
import { GiftCardsBlockModule } from './giftCards'
import { HeroBlockModule } from './hero'
import { LandingHeroBlockModule } from './landingHero'
import { PdfBlockModule } from './pdf'
import { ReviewsBlockModule } from './reviews'
import { TopPicksBlockModule } from './topPicks'
import { VideoBlockModule } from './video'

export const WebsiteBuilderBlocks: Array<
  BuilderBlockModule<
    WebsiteSection,
    WebsiteSectionCreate,
    WebsiteSectionUpdate,
    WebsiteResource
  >
> = [
  CareersBlockModule,
  ContentBlockModule,
  FeaturesBlockModule,
  FormBlockModule,
  GalleryBlockModule,
  GiftCardsBlockModule,
  HeroBlockModule,
  LandingHeroBlockModule,
  PdfBlockModule,
  ReviewsBlockModule,
  LocationsBlockModule,
  TopPicksBlockModule,
  VideoBlockModule,
  EventCalendarModule,
  FaqBlockModule,
]
