import { datadogRum } from '@datadog/browser-rum'

/**
 * Log at error level
 * @param data Data to log
 */
export const logError = (data: unknown): void => {
  const VITE_LOG_LEVEL = import.meta.env.VITE_LOG_LEVEL

  if (VITE_LOG_LEVEL.includes('error')) {
    switch (import.meta.env.MODE) {
      case 'development':
        // eslint-disable-next-line no-console
        console.error(data)
        break

      default:
        datadogRum.addError(data)
        break
    }
  }
}
