<i18n locale="en">
{
  "label": "Theme",
  "options": {
    "light": "Light",
    "dark": "Dark"
  }
}
</i18n>

<template>
  <OwnInputContainer :label="t('label')">
    <OwnSegmentedControl
      :model-value="themePalette"
      :options="options"
      @update:model-value="onPaletteUpdate"
    />
  </OwnInputContainer>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapActions } from 'vuex'

import { useMergedTheme } from '@/lib/builder/public/preview/mixins/useMergedTheme'
import { OwnInputContainer } from '@/ui/OwnInputContainer'
import { OwnSegmentedControl } from '@/ui/OwnSegmentedControl'

const options = [
  {
    value: 'light',
  },
  {
    value: 'dark',
  },
]

export default {
  name: 'ThemePaletteEditor',
  components: {
    OwnInputContainer,
    OwnSegmentedControl,
  },
  mixins: [useMergedTheme],
  setup() {
    const { t } = useI18n()

    return { t }
  },
  data() {
    return {
      options: options.map((option) => ({
        ...option,
        label: this.t(`options.${option.value}`),
      })),
    }
  },
  computed: {
    themePalette() {
      const { mergedTheme } = this

      return mergedTheme?.palette
    },
  },
  methods: {
    ...mapActions({
      updateResource: 'websiteBuilder/updateResource',
    }),
    async onPaletteUpdate(newColor) {
      const update = {
        theme: {
          palette: newColor,
        },
      }

      await this.updateResource(update)
    },
  },
}
</script>
