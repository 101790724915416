import { reactive, type Reactive, ref, type Ref } from 'vue'

import {
  type PaginatedRequestData,
  type PaginatedRequestParams,
} from '@/core/types'

export type UsePaginationDataArgs = Partial<PaginatedRequestParams>

export interface UsePaginationData {
  requestParams: Reactive<PaginatedRequestParams>
  requestData: Reactive<PaginatedRequestData>
  query: Ref<string>
}

export const usePaginationData = (
  params: UsePaginationDataArgs = {}
): UsePaginationData => {
  const requestParams = reactive<PaginatedRequestParams>({
    limit: params.limit ?? 10,
    page: params.page ?? 1,
  })

  const requestData = reactive<PaginatedRequestData>({
    pageCount: undefined,
    totalItems: undefined,
  })

  const query = ref('')

  return { query, requestData, requestParams }
}
