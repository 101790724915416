import { useLocalStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import { computed } from 'vue'
import { type RouteLocationNamedRaw } from 'vue-router'

import { OlympusClient } from '@/OlympusClient'

import { type User, type Session } from './types'

export const useAuthStore = defineStore('auth', () => {
  const session = useLocalStorage<Session | null>('owner-session', null, {
    serializer: {
      read: (v) => (v ? JSON.parse(v) : null),
      write: (v) => JSON.stringify(v),
    },
  })

  const csrfToken = computed<string | null>(() => {
    if (!session.value) return null

    return session.value.csrfToken ?? null
  })

  const scopes = computed(() => {
    if (!session.value) return []

    return session.value.scopes
  })

  const expiresAt = computed(() => {
    if (!session.value) return null

    return session.value.expires
  })

  const user = useLocalStorage<User | null>('owner-user', null, {
    serializer: {
      read: (v) => (v ? JSON.parse(v) : null),
      write: (v) => JSON.stringify(v),
    },
  })

  const acl = computed(() => {
    if (!user.value)
      return {
        brands: [],
        locations: [],
      }

    return {
      brands: user.value.brands,
      locations: user.value.locations,
    }
  })

  const role = computed(() => {
    if (!user.value) return null

    return user.value?.role
  })

  const isSuperAdmin = computed(() => {
    if (!user.value) return false

    return user.value.role === 'pb:super'
  })

  const home = computed<RouteLocationNamedRaw>(() => {
    if (!user.value) return { name: 'auth.login' }

    if (isSuperAdmin.value) return { name: 'brands' }

    if (user.value.brands && user.value.brands.length > 0) {
      return {
        name: 'brand.home',
        params: {
          brandId: user.value.brands[0],
        },
      }
    }

    return { name: 'auth.login' }
  })

  const isAuthenticated = computed(() => {
    if (!user.value || !session.value) return false

    return true
  })

  const login = async ({
    email,
    password,
  }: {
    email: string
    password: string
  }): Promise<void> => {
    const response = await OlympusClient.auth.v1.hermes.login({
      headers: {
        Authorization: `Basic ${btoa(`${email}:${password}`)}`,
      },
    })

    session.value = response.session
    user.value = response.user
  }

  const resetPassword = async ({
    password,
    token,
  }: {
    password: string
    token: string
  }): Promise<void> => {
    const response = await OlympusClient.auth.v1.hermes.reset({
      body: {
        password,
      },
      headers: {
        Authorization: `Otp ${token}`,
        'X-PB-Audience': 'hermes',
      },
    })

    session.value = response.session
    user.value = response.user
  }

  const multiFactorLogin = async ({
    email,
    token,
  }: {
    email: string
    token: string
  }): Promise<void> => {
    const response = await OlympusClient.auth.v1.hermes.multiFactorAuth({
      body: {
        email,
        token,
      },
      headers: {
        'X-PB-Audience': 'hermes',
      },
    })

    if (!response.user) throw new Error('User not returned from login')

    session.value = response.session
    user.value = response.user
  }

  const setSession = async (args: {
    session: Session
    user: User
  }): Promise<void> => {
    session.value = args.session
    user.value = args.user
  }

  const logout = async (): Promise<void> => {
    session.value = null
    user.value = null

    await OlympusClient.auth.v1.hermes
      .logout({
        headers: {},
      })
      .catch(() => undefined)
  }

  const updateNotifications = async (
    notifications: User['notifications']
  ): Promise<void> => {
    if (!user.value) return

    const updatedNotifications =
      await OlympusClient.users.user.notifications.updateNotifications({
        body: notifications,
        params: {
          userId: user.value.id,
        },
      })

    user.value.notifications = updatedNotifications
  }

  return {
    acl,
    csrfToken,
    expiresAt,
    home,
    isAuthenticated,
    isSuperAdmin,
    login,
    logout,
    multiFactorLogin,
    resetPassword,
    role,
    scopes,
    setSession,
    updateNotifications,
    user,
  }
})
