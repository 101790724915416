import { type BuilderTranslationMap } from '@/lib/builder/types'

export const emailBuilderEn: BuilderTranslationMap = {
  blocks: {
    coupon: 'Coupon',
    'cta-section': 'Button',
    'gift-card': 'Gift Card',
    heading: 'Headline',
    image: 'Image',
    item: 'Menu Item',
    loyalty: 'Loyalty',
    paragraph: 'Paragraph',
    subheading: 'Subheading',
  },
  defaults: {
    blank: {
      heading: 'Design your own email!',
      subheading:
        'To get started, you can link to items on your menu, current promotions, add text or images.',
      subject: 'New Email',
    },
    coupon: {
      heading: 'Just a few more days!',
      subheading:
        'Use this coupon at checkout to get 15% off your order. Save money by ordering direct with us!',
      subject: 'Offer Ending Soon!',
    },
    item: {
      heading: 'Have you tried our new dish?',
      subheading:
        'Order directly from us to save on fees and support our restaurant.',
      subject: 'Check out our new dish!',
    },
  },
  description:
    'Start your email design with a pre-built template or make your own.',
  heading: 'Send an email to',
  options: {
    blank: 'Blank',
    'menu-item': 'Menu Item',
    offer: 'Offer',
  },
  'resource-title': {
    placeholder: 'Enter campaign name',
  },
  title: 'New Email',
}
