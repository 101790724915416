import {
  type BlockCore,
  type BuilderState,
  type ResourceCore,
} from '../../types'

export const currentBlocks = <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  state: BuilderState<TResource, TBlock>
): TBlock[] => {
  const builderCurrentState = state._builder_currentState

  return builderCurrentState.blocks
}
