import { type RouteRecordRaw } from 'vue-router'

export const authRouter: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: { name: 'auth.login' },
  },
  {
    component: async () => import('../views/LoginView.vue'),
    meta: {
      isPublic: true,
      title: 'auth.login',
    },
    name: 'auth.login',
    path: '/login',
  },
  {
    component: async () => import('../views/MultiFactorAuthSentView.vue'),
    meta: {
      isPublic: true,
      title: 'auth.multi-factor-auth',
    },
    name: 'auth.multiFactorAuth',
    path: '/multi-factor-auth',
  },
  {
    component: async () => import('../views/MultiFactorAuthVerifyView.vue'),
    meta: {
      isPublic: true,
      title: 'auth.multi-factor-auth-verify',
    },
    name: 'auth.multiFactorAuthVerify',
    path: '/multi-factor-auth/verify',
  },
  {
    component: async () => import('../views/SubscribeView.vue'),
    meta: {
      isPublic: true,
      preventAuthenticatedRedirect: true,
      title: 'auth.subscribe',
    },
    name: 'auth.subscribe',
    path: '/subscribe',
  },
  {
    component: async () => import('../views/ForgotPasswordView.vue'),
    meta: {
      isPublic: true,
      title: 'auth.forgot-password',
    },
    name: 'auth.forgot-password',
    path: '/forgot-password',
  },
  {
    component: async () => import('../views/ResetPasswordView.vue'),
    meta: {
      isPublic: true,
      title: 'auth.reset-password',
    },
    name: 'auth.reset-password',
    path: '/reset-password',
  },
]
