import { type BuilderTranslationMap } from '@/lib/builder/types'

export const websiteBuilderEn: BuilderTranslationMap = {
  blocks: {
    careers: 'Careers',
    content: 'Content',
    'event-calendar': 'Events',
    faq: 'FAQ',
    features: 'Features',
    form: 'Form',
    gallery: 'Gallery',
    'gift-cards': 'Gift Cards',
    hero: 'Hero',
    'landing-hero': 'Hero',
    locations: 'Locations',
    pdf: 'PDF',
    reviews: 'Reviews',
    'top-picks': 'Popular Items',
    video: 'Video',
  },
  description:
    'Start your page design with a pre-built template or make your own.',
  heading: 'Select a template',
  options: {
    article: 'Article',
    blank: 'Blank',
    editorial: 'Editorial',
    showcase: 'Showcase',
  },
  'page-title': {
    announcements: 'Announcement Details',
    style: 'Style',
  },
  'resource-title': {
    placeholder: 'Enter page name',
  },
  title: 'New Page',
}
