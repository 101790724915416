import { type RouteLocationRaw } from 'vue-router'
import { type ActionContext } from 'vuex'

import { type CoreModule } from '@/lib/store/types'

import {
  type BlockCore,
  type BuilderState,
  type ResourceCore,
} from '../../types'

export const setReturnLocation = async <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  { commit }: ActionContext<BuilderState<TResource, TBlock>, CoreModule>,
  returnLocation?: RouteLocationRaw
): Promise<void> => {
  commit('_builder_SET_RETURN_LOCATION', returnLocation)
}
