import { type CreateBlockData } from '@/lib/builder/types'

import {
  type GiftCardsSectionCreate,
  type GiftCardsSection,
} from '../../../WebsiteBuilder.types'

export const createGiftCardsData = ({
  brandId,
}: CreateBlockData<GiftCardsSection>): GiftCardsSectionCreate => ({
  brandId,
  params: {
    heading: 'Order your gift card today',
    subheading:
      'Give the gift of a delicious meal with our restaurant gift cards! Perfect for any occasion, our gift cards can be used for takeout or delivery.',
  },
  type: 'gift-cards',
})
