import merge from 'lodash/merge'

import { useBuilder } from '@/lib/builder/extension/useBuilder'

import { type HeroSection } from '../../../WebsiteBuilder.types'

const websiteBuilderQueue = useBuilder('websiteBuilder')

const HeroCommands = {
  updateBlock: websiteBuilderQueue.createBlockCommand<HeroSection>({
    commit: async (currentData, ctx) => {
      return merge(currentData, ctx.update)
    },
  }),
}

export { HeroCommands }
