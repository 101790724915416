import { isAfter, isEqual, parseISO } from 'date-fns'

import { useAuthStore } from '@/auth/useAuthStore'
import { useEmitter } from '@/utils/useEmitter'

import { getRedirectToLogin } from './common/getRedirectToLogin'
import { type HermesGuardFn } from './types'

export const checkSessionExpiration: HermesGuardFn = async (to) => {
  const authStore = useAuthStore()

  const isAuthenticated = authStore.isAuthenticated
  const expiresAt = authStore.expiresAt
  const now = new Date()

  if (isAuthenticated && expiresAt) {
    const expiresAtDate = parseISO(expiresAt)

    if (
      expiresAt
        ? isEqual(now, expiresAtDate) || isAfter(now, expiresAtDate)
        : true
    ) {
      // Emits to useSessionManager
      useEmitter.emit('auth.logout')
      return getRedirectToLogin(to)
    }
  }
}
