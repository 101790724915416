import { OlympusClient } from '@/OlympusClient'

import { type SmsSection, type SmsSectionUpdate } from '../../types'

export const updateBlockHandler = async <
  TSection extends SmsSection,
  TSectionUpdate extends SmsSectionUpdate,
>(
  id: string,
  update: TSectionUpdate
): Promise<TSection> => {
  return (await OlympusClient.notifications.v1.templates.sms.sections.updateSMSSection(
    {
      body: update,
      params: { sectionId: id },
    }
  )) as TSection
}
