import { type BuilderBlockModule } from '@/lib/builder/types'

import {
  type EmailSection,
  type EmailSectionCreate,
  type CampaignResource,
  type EmailSectionUpdate,
} from '../types'

import { CouponBlockModule } from './CouponSection'
import { CTAButtonBlockModule } from './CTAButton'
import { GiftCardBlockModule } from './GiftCard'
import { ImageBlockModule } from './ImageSection'
import { ItemBlockModule } from './ItemSection'
import { TextBlockModule } from './TextSection'

export const EmailBuilderBlocks: Array<
  BuilderBlockModule<
    EmailSection,
    EmailSectionCreate,
    EmailSectionUpdate,
    CampaignResource
  >
> = [
  CouponBlockModule,
  ImageBlockModule,
  ItemBlockModule,
  TextBlockModule,
  GiftCardBlockModule,
  CTAButtonBlockModule,
]
