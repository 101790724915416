<template>
  <PreviewSheet class="hero-preview">
    <div class="hero-preview__content flex-col gap-4">
      <PreviewTypography
        :text="currentBlockParams?.heading"
        variant="heading2"
      />
      <PreviewTypography
        v-if="currentBlockParams?.subheading"
        :text="currentBlockParams?.subheading"
        variant="paragraphRegular"
      />
    </div>
  </PreviewSheet>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import PreviewSheet from '@/lib/builder/public/preview/PreviewSheet.vue'
import PreviewTypography from '@/lib/builder/public/preview/PreviewTypography.vue'

import type { HeroSection } from '../../../WebsiteBuilder.types'

const props = defineProps<{
  content: HeroSection
}>()

const currentBlockParams = computed(() => props.content?.params)
</script>
<style lang="scss" scoped>
.hero-preview {
  padding: 140px 120px;

  &__content {
    max-width: 700px;
    text-wrap: balance;
  }
}
</style>
