import { OlympusClient } from '@/OlympusClient'

import {
  type FormSection,
  type FormSectionCreate,
} from '../../../types/FormBuilder.types'

export const createBlockHandler = async <
  TSection extends FormSection,
  TSectionCreate extends FormSectionCreate,
>(
  body: TSectionCreate
): Promise<TSection> => {
  return (await OlympusClient.forms.v1.sections.createSection({
    body,
  })) as TSection
}
