<i18n locale="en">
{
  "labels": {
    "global-nav": "Changes to the navigation affect all pages."
  }
}
</i18n>

<template>
  <div class="flex-col gap-8">
    <OwnType :text="t('labels.global-nav')" />
    <LogoSizeEditor />
    <LogoPositionEditor />
    <NavigationOrderEditor />
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

import { OwnType } from '@/ui/OwnType'

import LogoPositionEditor from './LogoPositionEditor.vue'
import LogoSizeEditor from './LogoSizeEditor.vue'
import NavigationOrderEditor from './NavigationOrderEditor.vue'

const { t } = useI18n()
</script>
