import { type CreateBlockData } from '@/lib/builder/types'

import type {
  SelectSection,
  SelectSectionCreate,
} from '../../../../types/FormBuilder.types'

export const createSelectData = ({
  brandId,
  type,
}: CreateBlockData<SelectSection>): SelectSectionCreate => ({
  brandId,
  params: {
    label: '',
    options: [],
    required: false,
  },
  type,
})
