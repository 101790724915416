import merge from 'lodash/merge'

import { useBuilder } from '@/lib/builder/extension/useBuilder'

import { type ItemSection } from '../../../types'

const funnelBuilderQueue = useBuilder('emailBuilder')

const ItemCommands = {
  updateBlock: funnelBuilderQueue.createBlockCommand<ItemSection>({
    commit: async (currentData, ctx) => {
      return merge(currentData, ctx.update)
    },
  }),
}

export { ItemCommands }
