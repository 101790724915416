<template>
  <DefaultPageLayout class="page-blocker">
    <template #filter-bar>
      <BillingNavBar v-if="pageName === 'payouts'" />
    </template>

    <PageBlockerContent />
  </DefaultPageLayout>
</template>

<script>
import { mapGetters } from 'vuex'

import DefaultPageLayout from '@/components/layouts/components/page/DefaultPageLayout.vue'
// This breaks the core module boundary, but this component shouldn't be exported long term.
// Since this page is not sticking around for the long haul I think it's ok.
import BillingNavBar from '@/features/billing/public/BillingNavBar.vue'

import PageBlockerContent from './PageBlockerContent.vue'

export default {
  name: 'PageBlocker',
  components: {
    BillingNavBar,
    DefaultPageLayout,
    PageBlockerContent,
  },
  props: {
    /**
     * Page name
     */
    pageName: { type: String, default: undefined },
  },
  computed: {
    ...mapGetters({
      activeBrandLocations: 'core/brand/active/locations',
    }),
  },
  created() {
    const { activeBrandLocations } = this

    if (activeBrandLocations.length === 1) {
      this.$router.push({
        name: this.$route.name.replace('brand', 'location'),
        params: {
          brandId: this.$route.params.brandId,
          locationId: activeBrandLocations[0].id,
        },
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.page-blocker {
  height: 100%;
  width: 100%;
}
</style>
