import { type CreateBlockData } from '@/lib/builder/types'

import {
  type GallerySectionCreate,
  type GallerySection,
} from '../../../WebsiteBuilder.types'

export const createGalleryData = ({
  brandId,
}: CreateBlockData<GallerySection>): GallerySectionCreate => ({
  brandId,
  params: {
    // this copy is also tracked in olympus, please update in apps/olympus/src/services/generator/workers/generation.worker/tasks/writeOutput/writeFunnel.ts as well
    description:
      'Here’s a sneak peek of the items our customers can’t stop talking about',
    images: [
      'https://static-content.owner.com/f9782ea8-79f0-467f-8a96-deef5f1bac92',

      'https://static-content.owner.com/5d39f45b-0485-4cac-aee2-e6efacdd8db1',

      'https://static-content.owner.com/3076591b-9709-4060-9d61-228c71b04044',

      'https://static-content.owner.com/aeba3afa-e4bd-45d6-b368-3bf49f31c4fc',

      'https://static-content.owner.com/0bf40554-b4d5-40fc-9780-82f89e28cd20',

      'https://static-content.owner.com/9ae8d32a-0e68-469d-9f26-a98b87b75e59',

      'https://static-content.owner.com/81a84951-26b3-4390-a14b-84072a6dde57',
    ],
    layout: 'grid',
    // this copy is also tracked in olympus, please update in apps/olympus/src/services/generator/workers/generation.worker/tasks/writeOutput/writeFunnel.ts as well
    title: 'See our most popular items',
  },
  type: 'gallery',
})
