export const referralEn = {
  bonuses: {
    '2025-03': {
      subtitle: 'March only — tap to share!',
      title: 'Earn $1,500 per referral 💰',
    },
    '2025-04': {
      subtitle: 'April only — tap to share!',
      title: 'Earn $1,500 per referral 💰',
    },
  },
}
