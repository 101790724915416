import { storeToRefs } from 'pinia'
import { getCurrentInstance } from 'vue'

import { useAuthStore } from '@/auth/useAuthStore'
import { useMappedGetter } from '@/store'

export const useAnalytics = (): {
  track: (eventName: string, args?: Record<string, unknown>) => void
} => {
  const vm = getCurrentInstance()

  const { user } = storeToRefs(useAuthStore())

  const track = (eventName: string, args?: Record<string, unknown>): void => {
    const activeBrandId = useMappedGetter<string | undefined>(
      'core/brand/active/id'
    )

    const activeLocationId = useMappedGetter<string | undefined>(
      'core/location/active/id'
    )

    if (vm) {
      // @ts-expect-error This does exist on the vm via plugin
      vm.proxy.$analytics.track(eventName, {
        ...(args ?? {}),
        brandId: activeBrandId.value,
        locationId: activeLocationId.value,
        user: {
          brands: user.value?.brands,
          createdAt: user.value?.createdAt,
          email: user.value?.email,
          firstName: user.value?.firstName,
          lastName: user.value?.lastName,
          locations: user.value?.locations,
          role: user.value?.role,
        },
      })
    }
  }

  return {
    track,
  }
}
