import { type RouteLocationNormalized } from 'vue-router'

import PageBlocker from '@/core/public/PageBlocker.vue'
import { billingRouter } from '@/features/billing/router/billing.router'
import { customersRouter } from '@/features/customers/router/customers.router'
import { deliveryRouter } from '@/features/delivery/router/delivery.router'
import { devicesRouter } from '@/features/devices/router/devices.router'
import { domainsRouter } from '@/features/domains/router/domains.router'
import { eventsRouter } from '@/features/events/router/events.router'
import { formBuilderRoutes } from '@/features/form-builder/router/formBuilder.router'
import { giftCardsRouter } from '@/features/gift-cards/router/giftCards.router'
import { homeRouter } from '@/features/home/router/home.router'
import { hoursRouter } from '@/features/hours/router/hours.router'
import { marketingRouter } from '@/features/marketing/router/marketing.router'
import { menuRouter } from '@/features/menu/router/menu.router'
import { ordersRouter } from '@/features/orders/router/orders.router'
import { brandReportRoutes } from '@/features/reports/router/reports.router'
import { reviewsRouter } from '@/features/reviews/router/reviews.router'
import { settingsRouterBrand } from '@/features/settings/router/settings.router'
import { setupRouter } from '@/features/setup/router/setup.router'
import { toolsRouter } from '@/features/tools/router/tools.router'
import { websiteBuilderRouter } from '@/features/website-builder/router/websiteBuilder.router'
import { applyMetaToChildren } from '@/utils/router'

import { locationRoutes } from './locationRoutes'

export const brandRoutes = applyMetaToChildren(
  [
    {
      children: [
        {
          path: '',
          redirect: { name: 'brand.home' },
        },
        ...homeRouter,
        ...setupRouter,

        ...brandReportRoutes,

        ...menuRouter,
        ...ordersRouter,
        ...giftCardsRouter,
        {
          component: PageBlocker,
          meta: {
            redirectToLocation: true,
            title: 'restaurant.careers',
          },
          name: 'brand.careers',
          path: 'careers',
          props: {
            pageName: 'careers',
          },
        },
        ...eventsRouter,
        ...hoursRouter,
        ...deliveryRouter,
        ...reviewsRouter,
        ...devicesRouter,

        ...marketingRouter,

        {
          children: [...websiteBuilderRouter, ...formBuilderRoutes],
          component: {
            name: 'WebsiteBuilderRouterView',
            template: '<router-view></router-view>',
          },
          name: 'brand.website',
          path: 'website',
          redirect: { name: 'brand.website.pages' },
        },
        ...domainsRouter,

        ...customersRouter,

        ...toolsRouter,

        ...billingRouter,
        ...settingsRouterBrand,

        ...locationRoutes,
      ],
      component: async () => await import('@/views/BrandView/BrandView.vue'),
      path: 'brands/:brandId',
      props: (route: RouteLocationNormalized) => ({
        brandId: route.params.brandId,
        wrapperless:
          route.name &&
          typeof route.name === 'string' &&
          (route.name.startsWith('brand.setup') ||
            route.name.startsWith('location.setup')),
      }),
    },
  ],
  {
    acl: {
      Brand: 'brandId',
    },
  }
)
