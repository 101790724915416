<i18n locale="en">
{
  "label": "Link Order",
  "description": "Links that don’t fit will fall into a “More” menu.",
  "types": {
    "page": "Page",
    "action": "Action",
    "partner": "Internal Link",
    "external": "External Link"
  },
  "actions": {
    "add-page": "Page",
    "add-link": "Link",
    "add-action": "Action"
  }
}
</i18n>

<template>
  <div class="flex-col gap-3">
    <OwnInputContainer :label="t('label')" :description="t('description')">
      <Reorderable
        v-slot="{ item, index }"
        :model-value="navItems"
        :item-key="navItemKey"
        class="space-y-3"
        @reorder="onItemReorder"
      >
        <OwnOption
          :el="item.type === 'partner' ? 'div' : 'button'"
          :disabled="lockIndexPage(item, index)"
          :class="{
            locked: lockIndexPage(item, index),
          }"
          @click="onEditClick(item, index)"
        >
          <div class="flex-row gap-4 w-full">
            <DragHandle :locked="lockIndexPage(item, index)" />

            <div class="flex-1 flex-col gap-1">
              <OwnType
                :text="item?.params?.label"
                variant="subtitle"
                color="primary"
              />
              <OwnType
                :text="t(`types.${item.type}`)"
                variant="paragraph-small"
              />
            </div>

            <NavItemActionMenu
              v-if="!lockIndexPage(item, index)"
              :item="item"
              @action="onActionSelect($event, index)"
            />
          </div>
        </OwnOption>
      </Reorderable>
    </OwnInputContainer>

    <div class="flex-row gap-3">
      <AddNavItemButton
        :text="t('actions.add-page')"
        @click="showAddPageDialog = true"
      />
      <AddNavItemButton
        :text="t('actions.add-link')"
        @click="showLinkDialog = true"
      />
      <AddNavItemButton
        :text="t('actions.add-action')"
        @click="showActionDialog = true"
      />
    </div>

    <AddPageDialog
      v-if="showAddPageDialog"
      :edit-item="editItem"
      @update="onUpdateItem"
      @close="showAddPageDialog = false"
    />
    <AddLinkDialog
      v-if="showLinkDialog"
      :edit-item="editItem"
      @update="onUpdateItem"
      @close="showLinkDialog = false"
    />
    <AddActionDialog
      v-if="showActionDialog"
      :edit-item="editItem"
      @update="onUpdateItem"
      @close="showActionDialog = false"
    />
  </div>
</template>

<script>
import set from 'lodash/set'
import { useI18n } from 'vue-i18n'
import { mapActions, mapGetters } from 'vuex'

import { OwnInputContainer } from '@/ui/OwnInputContainer'
import { OwnOption } from '@/ui/OwnOption'
import { OwnType } from '@/ui/OwnType'
import DragHandle from '@/ui/reorderable/DragHandle.vue'
import Reorderable from '@/ui/reorderable/Reorderable.vue'
import { removeObserver, reorder } from '@/utils/helpers'

import AddActionDialog from '../../../../common/resource-dialogs/add-action-dialog/AddActionDialog.vue'
import AddLinkDialog from '../../../../common/resource-dialogs/add-link-dialog/AddLinkDialog.vue'
import AddPageDialog from '../../../../common/resource-dialogs/add-page-dialog/AddPageDialog.vue'
import { navItemKey } from '../common/navItemKey'

import AddNavItemButton from './AddNavItemButton.vue'
import NavItemActionMenu from './NavItemActionMenu.vue'

export default {
  name: 'NavigationOrderEditor',
  components: {
    AddActionDialog,
    AddLinkDialog,
    AddNavItemButton,
    AddPageDialog,
    DragHandle,
    NavItemActionMenu,
    OwnInputContainer,
    OwnOption,
    OwnType,
    Reorderable,
  },
  data() {
    return {
      editIndex: undefined,
      editItem: undefined,
      showActionDialog: false,
      showAddPageDialog: false,
      showLinkDialog: false,
    }
  },
  setup() {
    const { t } = useI18n()

    return { t }
  },
  computed: {
    ...mapGetters({
      activeBrandId: 'core/brand/active/id',
      currentResource: 'websiteBuilder/currentResource',
    }),

    lockIndexPage() {
      return (item, index) =>
        index === 0 && item.params?.path?.params?.page === 'index'
    },

    navItems() {
      const { currentResource } = this

      // locations is being removed on the server, and will be cleaned up (https://linear.app/owner/issue/GUE-440/remove-locations-nav-item-on-olympus-and-hermes)
      return (currentResource?.nav ?? []).filter(
        (item) => item.type !== 'action' || item.params.action !== 'locations'
      )
    },
  },
  methods: {
    ...mapActions({
      updateResource: 'websiteBuilder/updateResource',
    }),
    navItemKey,
    onActionSelect(event, index) {
      if (event.action === 'edit') {
        this.onEditClick(this.navItems[index], index)
      }
      if (event.action === 'delete') {
        this.removeItem(index)
      }
    },
    onEditClick(item, index) {
      this.editIndex = index
      this.editItem = item

      switch (item.type) {
        case 'page':
          this.showAddPageDialog = true
          break
        case 'action':
          this.showActionDialog = true
          break
        case 'external':
          this.showLinkDialog = true
          break
        default:
        // Pass
      }
    },
    onItemReorder({ newIndex, oldIndex }) {
      const { navItems } = this

      const currentItems = removeObserver(navItems)

      this.updateResource(
        set({}, 'nav', reorder(currentItems, oldIndex, newIndex))
      )
    },
    onUpdateItem(newItem) {
      const { navItems, editIndex } = this

      if (editIndex) {
        const currentItems = removeObserver(navItems)

        currentItems.editIndex = newItem

        this.updateResource(set({}, 'nav', currentItems))
      } else {
        const currentItems = removeObserver(navItems)

        this.updateResource(set({}, 'nav', [...currentItems, newItem]))
      }
    },
    removeItem(delIndex) {
      const { navItems } = this

      const updatedItems = removeObserver(navItems).filter(
        (_item, idx) => idx !== delIndex
      )

      this.updateResource(set({}, 'nav', updatedItems))
    },
  },
}
</script>

<style lang="scss" scoped>
.locked {
  cursor: not-allowed;
}
</style>
