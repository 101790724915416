<i18n locale="en">
{
  "actions": {
    "cancel": "Cancel"
  }
}
</i18n>

<template>
  <div
    :class="[
      'default-full-page-top-bar',
      isDesktop ? 'flex-row' : 'flex-col',
      'justify-center gap-4',
    ]"
  >
    <div
      :class="['flex-row align-center flex-1', isDesktop ? 'gap-8' : 'gap-4']"
    >
      <slot name="left-actions">
        <OwnButton
          :text="isDesktop ? t('actions.cancel') : undefined"
          @click="$emit('close')"
        >
          <template #icon-left>
            <PhXCircle size="24" />
          </template>
        </OwnButton>
      </slot>

      <slot name="title">
        <OwnType v-if="title" :text="title" variant="title" />
      </slot>
    </div>

    <slot name="center" />

    <div
      v-if="isDesktop || $slots['right-actions']"
      class="flex-row gap-6 justify-end align-center flex-1"
    >
      <TriggerSupport
        v-if="isDesktop"
        v-slot="{ triggerSupport }"
        class="default-full-page-top-bar__support"
      >
        <button @click="triggerSupport">
          <PhChatTeardropText size="24" />
        </button>
      </TriggerSupport>

      <div :class="!isDesktop ? 'w-full' : undefined">
        <slot name="right-actions" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PhChatTeardropText, PhXCircle } from '@phosphor-icons/vue'
import { useI18n } from 'vue-i18n'

import TriggerSupport from '@/core/public/TriggerSupport.vue'
import { OwnButton } from '@/ui/OwnButton'
import { OwnType } from '@/ui/OwnType'
import { useBreakpoints } from '@/utils/useBreakpoints'

withDefaults(
  defineProps<{
    title?: string
  }>(),
  {
    title: undefined,
  }
)

defineEmits<{ close: [] }>()

const { t } = useI18n()
const { isDesktop } = useBreakpoints()
</script>

<style lang="scss" scoped>
@use '@/styles/globals';

.default-full-page-top-bar {
  padding: 16px 24px;
  @include globals.control-shadow();

  &__support {
    flex-shrink: 0;
    height: 24px;
  }
}
</style>
