import { BuilderRouterFactory } from '@/lib/builder/router/BuilderRouterFactory'
import { useBuilderRouter } from '@/lib/builder/router/useBuilderRouter'

import { textBuilderConfig } from './textBuilderConfig'

const textBuilderRouteManager = useBuilderRouter(textBuilderConfig)

const textBuilderRoutes = BuilderRouterFactory(textBuilderConfig)

export { textBuilderRoutes, textBuilderRouteManager }
