import { type Olympus } from '@owner/olympus-client'

import {
  type BrandPageSlug,
  type LocationPageSlug,
  type CompanyPositionType,
} from '../types'

export interface SettingsTranslations {
  listings: {
    actions: Record<string, string>
    errors: Record<string, string>
    fields: Record<Olympus.Listings.ListingDataIssue['field'], string>
    issueTypes: Record<Olympus.Listings.ListingDataIssue['type'], string>
    success: string
  }
  titles: Record<string, string>
  pages: {
    brand: Record<BrandPageSlug, string>
    location: Record<LocationPageSlug, string>
  }
  companyPositions: Record<CompanyPositionType, string>
  validators: Record<string, string>
}

export const settingsEn: SettingsTranslations = {
  companyPositions: {
    CEO: 'CEO',
    CFO: 'CFO',
    Director: 'Director',
    GM: 'General Manager',
    General_Counsel: 'General Counsel',
    Other: 'Other',
    VP: 'Vice President',
  },
  listings: {
    actions: {
      open: 'Open Yext',
      publish: 'Publish Menu to Yext',
      sync: 'Push listing data',
    },
    errors: {
      'get-link': 'Unable to get Yext link',
      sync: 'Unable to sync listings data',
    },
    fields: {
      address: 'Address',
      cuisine: 'Cuisine',
      isDeliveryEnabled: 'Delivery',
      isPickupEnabled: 'Pickup',
      name: 'Name',
      phone: 'Phone',
      url: 'Domain',
    },
    issueTypes: {
      mismatch: 'mismatched',
      missing: 'missing',
    },
    success: 'Successfully synced listing data',
  },
  pages: {
    brand: {
      general: 'General',
      links: 'Links',
      notifications: 'Notifications',
      'sms-marketing': 'SMS Marketing',
    },
    location: {
      address: 'Address',
      contact: 'Contact',
      features: 'Features',
      fulfillment: 'Fulfillment',
      general: 'General',
      integrations: 'Integrations',
      links: 'Links',
      listings: 'Listings',
      payments: 'Payments',
    },
  },
  titles: {
    funnel: 'Funnel Settings',
    general: 'General Settings',
  },
  validators: {
    externalLink:
      'Link location must not use connected domain or ordersave.com',
  },
}
