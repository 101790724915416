import mergeWith from 'lodash/mergeWith'

import { useBuilder } from '@/lib/builder/extension/useBuilder'

import { type GallerySection } from '../../../WebsiteBuilder.types'

const websiteBuilderQueue = useBuilder('websiteBuilder')

const GalleryCommands = {
  updateBlock: websiteBuilderQueue.createBlockCommand<GallerySection>({
    commit: async (currentData, ctx) => {
      return mergeWith(currentData, ctx.update, (_objValue, srcValue, key) => {
        // Do not merge `blocks` because it is an array
        if (key === 'images') {
          return srcValue
        }
      })
    },
  }),
}

export { GalleryCommands }
