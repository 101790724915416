<i18n locale="en">
{
  "applications":  "0 applications | {count} application | {count} applications",
  "delete-confirm": {
    "delete": "Delete Job",
    "go-back": "Go Back",
    "message": "Are you sure you want to delete this job?"
  },
  "errors": {
    "load": "Unable to load jobs"
  },
  "headers" : {
    "applications": "Applications",
    "delete": "Delete",
    "salary": "Salary Range",
    "title": "Title"
  },
  "loading": "Getting jobs...",
  "no-data": "No jobs",
  "no-query-results": "No jobs match that search",
  "salary": {
    "max": "Up to {amount}",
    "min": "Starting at {amount}"
  }
}
</i18n>

<template>
  <div class="flex-col gap-4">
    <OwnTable
      :columns="columns"
      :items="items"
      :query="query"
      item-key="id"
      @click="editJob"
    >
      <template v-if="isLoading" #loading>
        <DefaultComponentLoader :message="t('loading')" />
      </template>
      <template #card="{ item }">
        <div class="w-full flex-row justify-between align-center gap-6">
          <div class="flex-col gap-2">
            <OwnType :text="item.title" color="primary" variant="subtitle" />
            <OwnType
              :text="t('applications', item.applications)"
              variant="paragraph-small"
            />
          </div>
          <button class="status-danger" @click.stop="onDelete(item.id)">
            <PhTrash size="24" />
          </button>
        </div>
      </template>
      <template #no-data>
        <DefaultEmptyState :message="t('no-data')">
          <template #icon>
            <PhUsersFour size="24" />
          </template>
        </DefaultEmptyState>
      </template>
      <template #no-query-results>
        <DefaultEmptyState :message="t('no-query-results')">
          <template #icon>
            <PhUsersFour size="24" />
          </template>
        </DefaultEmptyState>
      </template>

      <template #heading.title>
        <SearchInput v-model="query" borderless />
      </template>

      <template #item.delete="{ item }">
        <div class="h-full flex-row align-center justify-end">
          <button class="status-danger" @click.stop="onDelete(item.id)">
            <PhTrash size="24" />
          </button>
        </div>
      </template>
    </OwnTable>

    <PaginationControls
      v-model:request-params="requestParams"
      v-model:request-data="requestData"
      :loading="isLoading"
    />
  </div>

  <DeleteConfirmationDialog
    v-model="showDeleteConfirmation"
    :delete-action-label="t('delete-confirm.delete')"
    :message="t('delete-confirm.message')"
    :title="t('delete-confirm.delete')"
    @cancel="idToDelete = null"
    @delete="onDeleteConfirm"
  />

  <router-view />
</template>

<script>
import { PhUsersFour, PhTrash } from '@phosphor-icons/vue'
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'

import DeleteConfirmationDialog from '@/components/dialogs/DeleteConfirmationDialog.vue'
import DefaultEmptyState from '@/components/empty/DefaultEmptyState.vue'
import SearchInput from '@/components/inputs/SearchInput.vue'
import DefaultComponentLoader from '@/components/loaders/DefaultComponentLoader.vue'
import { usePaginationData } from '@/components/table/composables/usePaginationData'
import PaginationControls from '@/components/table/pagination-controls/PaginationControls.vue'
import { CurrencyFormatter } from '@/format'
import { logError } from '@/logger'
import notify from '@/mixins/notify'
import { OlympusClient } from '@/OlympusClient'
import { OwnTable } from '@/ui/OwnTable'
import { OwnType } from '@/ui/OwnType'
import { emptyToUndefined } from '@/utils/emptyToUndefined'

export default {
  name: 'JobsTable',
  components: {
    DefaultComponentLoader,
    DefaultEmptyState,
    DeleteConfirmationDialog,
    OwnTable,
    OwnType,
    PaginationControls,
    PhTrash,
    PhUsersFour,
    SearchInput,
  },
  mixins: [notify],
  setup() {
    const { t } = useI18n()
    const { requestParams, requestData, query } = usePaginationData()

    return {
      query,
      requestData,
      requestParams,
      t,
    }
  },
  data() {
    return {
      idToDelete: null,
      isLoading: false,
      items: [],
      showDeleteConfirmation: false,
    }
  },
  computed: {
    ...mapGetters({
      activeBrandId: 'core/brand/active/id',
      activeLocationId: 'core/location/active/id',
    }),
    columns() {
      return [
        {
          body: {
            color: 'primary',
            font: 'subtitle',
          },
          key: 'title',
          minWidth: 150,
        },
        {
          body: {
            format: (salary) => {
              if (salary.min && !salary.max) {
                return this.t('salary.min', {
                  amount: CurrencyFormatter(salary.min),
                })
              } else if (salary.max && !salary.min) {
                return this.t('salary.max', {
                  amount: CurrencyFormatter(salary.max),
                })
              }
              return `${CurrencyFormatter(salary.min)} – ${CurrencyFormatter(
                salary.max
              )}`
            },
          },
          key: 'salary',
          width: 150,
        },
        {
          key: 'applications',
          width: 100,
        },
        {
          key: 'delete',
          width: 60,
        },
      ].map((header) => ({
        ...header,
        label: this.t(`headers.${header.key}`),
      }))
    },
  },
  watch: {
    '$route.path': 'fetchJobs',
    async activeLocationId() {
      await this.fetchJobs()
    },
    async query() {
      this.requestParams.page = 1
      await this.fetchJobs()
    },
    requestParams: {
      deep: true,
      async handler() {
        await this.fetchJobs()
      },
    },
  },
  async created() {
    this.isLoading = true
    await this.fetchJobs()
    this.isLoading = false
  },
  methods: {
    async editJob(job) {
      await this.$router.push({
        name: 'location.careers.jobs.edit',
        params: { jobId: job.id },
      })
    },
    async fetchJobs() {
      const { activeBrandId, activeLocationId, requestParams, query } = this

      try {
        const response =
          await OlympusClient.dashboard.v1.brands.brand.careers.getJobs({
            params: {
              brandId: activeBrandId,
            },
            query: {
              limit: requestParams.limit,
              locationId: activeLocationId,
              page: requestParams.page,
              query: emptyToUndefined(query),
            },
          })

        this.items = response.results
        this.requestData.pageCount = response.pageCount
        this.requestData.totalItems = response.total
      } catch (err) {
        logError(err)
        return this.$notify(this.t('errors.load'), 'error')
      }
    },
    onDelete(id) {
      this.idToDelete = id
      this.showDeleteConfirmation = true
    },
    async onDeleteConfirm() {
      const { idToDelete } = this

      await OlympusClient.careers.v1.jobs.job.deleteJob({
        params: { jobId: idToDelete },
      })
      await this.fetchJobs()
    },
  },
}
</script>
