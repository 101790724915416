import mergeWith from 'lodash/mergeWith'

import { useBuilder } from '@/lib/builder/extension/useBuilder'
import { reorder } from '@/utils/helpers'

import { type ReviewsSection } from '../../../WebsiteBuilder.types'

const websiteBuilderQueue = useBuilder('websiteBuilder')

const updateBlock = websiteBuilderQueue.createBlockCommand<ReviewsSection>({
  commit: async (currentData, ctx) => {
    return mergeWith(currentData, ctx.update, (_objValue, srcValue, key) => {
      // Do not merge `reviews` because it is an array
      if (key === 'reviews') {
        return srcValue
      }
    })
  },
})

type ReviewBlock = ReviewsSection['params']['reviews'][number]

const BLANK_REVIEW: ReviewBlock = {
  comment: '',
  image: '',
  name: '',
  stars: 5,
}

const ReviewsCommands = {
  /**
   * Create a new, empty review in a reviews section.
   * @param current Current block value
   * @returns Index of added review
   */
  async createReview(current: ReviewsSection): Promise<number> {
    const reviews = current.params.reviews.concat([BLANK_REVIEW])

    await updateBlock({
      targetId: current.id,
      update: { params: { ...current.params, reviews } },
    })

    return reviews.length - 1
  },

  /**
   * Remove a review from a reviews section.
   * @param current Current block value
   * @param reviewIndex Index of review to update
   */
  async deleteReview(
    current: ReviewsSection,
    reviewIndex: number
  ): Promise<void> {
    const reviews = current.params.reviews
    reviews.splice(reviewIndex, 1)

    await updateBlock({
      targetId: current.id,
      update: { params: { ...current.params, reviews } },
    })
  },

  /**
   * Change the order of a review in a reviews section.
   * @param current Current block value
   * @param oldIndex Current index of review
   * @param newIndex New index of review
   */
  async reorderReview(
    current: ReviewsSection,
    oldIndex: number,
    newIndex: number
  ): Promise<void> {
    const reviews = reorder(current.params.reviews, oldIndex, newIndex)

    await updateBlock({
      targetId: current.id,
      update: { params: { ...current.params, reviews } },
    })
  },

  updateBlock,

  /**
   * Update a review in a reviews section.
   * @param current Current block value
   * @param reviewIndex Index of review to update
   * @param updatedReview A review
   */
  async updateReview(
    current: ReviewsSection,
    reviewIndex: number,
    updatedReview: ReviewBlock
  ): Promise<void> {
    const reviews = current.params.reviews
    reviews.splice(reviewIndex, 1, updatedReview)

    await updateBlock({
      targetId: current.id,
      update: { params: { ...current.params, reviews } },
    })
  },
}

export { ReviewsCommands }
