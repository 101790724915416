import {
  type BuilderFetchArgs,
  type BuilderFetchResult,
} from '@/lib/builder/types'
import { OlympusClient } from '@/OlympusClient'

import { labelNavItems } from '../../utils/navItems'
import {
  type WebsiteSection,
  type WebsiteResource,
} from '../WebsiteBuilder.types'

export const fetchPageData = async ({
  brandId,
  resourceId,
}: BuilderFetchArgs): Promise<
  BuilderFetchResult<WebsiteResource, WebsiteSection>
> => {
  const { funnel, sections, page, theme, brand, nav, keyword } =
    await OlympusClient.dashboard.v1.brands.brand.website.pages.getPageData({
      params: {
        brandId,
        pageId: resourceId,
      },
    })

  return {
    blocks: sections,
    resource: {
      ...page,
      brand,
      funnel,
      keyword,
      nav: labelNavItems(nav),
      theme,
    },
  }
}
