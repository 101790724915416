import { storeToRefs } from 'pinia'
import { watch } from 'vue'

import { useAppStore } from '@/App/useAppStore'

export const useLocaleHandler = (): void => {
  const { locale } = storeToRefs(useAppStore())

  // eslint-disable-next-line @typescript-eslint/unbound-method
  watch(locale, window.location.reload)
}
