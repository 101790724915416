import {
  type BuilderFetchArgs,
  type BuilderFetchResult,
} from '@/lib/builder/types'
import { OlympusClient } from '@/OlympusClient'

import { type CampaignResource, type EmailSection } from '../types'

export const fetchResource = async (
  campaignId: string
): Promise<{ resource: CampaignResource; blocks: EmailSection[] }> => {
  const [campaign, [emailBlock]] = await Promise.all([
    OlympusClient.marketing.v1.campaigns.campaign.getCampaign({
      params: { campaignId },
    }),
    OlympusClient.marketing.v1.campaigns.campaign.getBlocks({
      params: { campaignId },
    }),
  ])

  if (
    emailBlock?.type !== 'action' ||
    emailBlock?.params?.type !== 'send-email'
  )
    throw new Error('Invalid first block in campaign')

  const emailTemplateId = emailBlock.params.params.templateId as string

  const [{ sections, subject }, blocks] = await Promise.all([
    OlympusClient.notifications.v1.templates.emails.email.getEmailTemplate({
      params: { emailTemplateId },
    }),
    OlympusClient.notifications.v1.templates.emails.email.getSections({
      params: { emailTemplateId },
    }),
  ])

  return {
    blocks,
    resource: {
      brandId: campaign.brandId,
      emailTemplateId,
      executeAt: campaign.executeAt ? new Date(campaign.executeAt) : null,
      id: campaignId,
      locationId: campaign.locationId,
      name: campaign.name,
      sections,
      status: campaign.status,
      subject,
      transport: campaign.transport,
    },
  }
}

export const fetchEmailData = async ({
  resourceId: campaignId,
}: BuilderFetchArgs): Promise<
  BuilderFetchResult<CampaignResource, EmailSection>
> => {
  const [{ resource, blocks }] = await Promise.all([fetchResource(campaignId)])

  return {
    blocks,
    resource,
  }
}
