<i18n locale="en">
{
  "title": "Some of the sections you’ve added haven’t been completed:",
  "warnings": {
    "item": "Menu Item",
    "coupon": "Coupon",
    "heading": "Text (heading)",
    "subheading": "Text (subheading)",
    "paragraph": "Text (paragraph)",
    "image": "Image",
    "text": "Text message body"
  }
}
</i18n>

<template>
  <div class="invalid-configuration-content">
    <div class="flex-col gap-6">
      <OwnType variant="paragraph" color="primary" :text="t('title')" />
      <div class="flex-col gap-3">
        <div v-for="(warning, i) in warnings" :key="i" class="flex-row gap-2">
          <PhWarning size="24" class="status-warning" />
          <OwnType
            variant="paragraph"
            color="primary"
            :text="t(`warnings.${warning}`)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PhWarning } from '@phosphor-icons/vue'
import { useI18n } from 'vue-i18n'

import { OwnType } from '@/ui/OwnType'

withDefaults(defineProps<{ warnings?: string[] }>(), { warnings: () => [] })

const { t } = useI18n()
</script>
