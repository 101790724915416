<i18n locale="en">
  {
    "labels": {
      "label": "Title",
      "link-to": "Link To",
      "description": "Select a link, page, or action the button opens when clicked."
    },
    "options": {
      "external": "URL",
      "page": "Page",
      "action": "Action"
    }
  }
  </i18n>

<template>
  <div class="flex-col gap-4">
    <OwnInputContainer :label="t('labels.label')" :errors="label.status.errors">
      <OwnInput v-model="label.value" />
    </OwnInputContainer>

    <OwnRule type="dashed" />

    <OwnType :text="t('labels.description')" />

    <OwnInputContainer :label="t('labels.link-to')">
      <OwnSegmentedControl v-model="selectedValue" :options="options" />
    </OwnInputContainer>

    <UrlForm
      v-if="selectedValue === 'external'"
      :action-config="modelValue"
      @update="
        onFormControlUpdate($event.controlName, $event.value, $event.status)
      "
    />

    <PageForm
      v-if="selectedValue === 'page'"
      :action-config="modelValue"
      @update="onActionUpdate"
    />

    <ActionForm
      v-if="selectedValue === 'action'"
      :action-config="modelValue"
      @update="onActionUpdate"
    />
  </div>
</template>

<script>
import merge from 'lodash/merge'
import { useI18n } from 'vue-i18n'

import { FormBuilderModelSync, Validators } from '@/forms'
import { OwnInput } from '@/ui/OwnInput'
import { OwnInputContainer } from '@/ui/OwnInputContainer'
import { OwnRule } from '@/ui/OwnRule'
import { OwnSegmentedControl } from '@/ui/OwnSegmentedControl'
import { OwnType } from '@/ui/OwnType'

import ActionForm from './action-forms/ActionForm.vue'
import PageForm from './action-forms/PageForm.vue'
import UrlForm from './action-forms/UrlForm.vue'

export default {
  name: 'ContentActionEditor',
  components: {
    ActionForm,
    OwnInput,
    OwnInputContainer,
    OwnRule,
    OwnSegmentedControl,
    OwnType,
    PageForm,
    UrlForm,
  },
  mixins: [
    FormBuilderModelSync({
      label: {
        debounce: 250,
        validateOnInit: true,
        validators: [Validators.required, Validators.maxLength(50)],
      },
    }),
  ],
  emits: ['update'],
  setup() {
    const { t } = useI18n()

    return { t }
  },
  data() {
    return {
      options: ['external', 'page', 'action'].map((value) => ({
        label: this.t(`options.${value}`),
        value,
      })),
      selectedValue: 'external',
    }
  },
  beforeMount() {
    const { modelValue } = this

    this.selectedValue = modelValue.type ?? 'external'

    this.setInitialFormValues({
      label: modelValue?.params?.label,
      url: modelValue?.params?.url,
    })
  },
  methods: {
    onActionUpdate(update) {
      const { formValues } = this

      const mergedUpdate = merge(update, {
        params: { label: formValues.label },
      })

      this.$emit('update', mergedUpdate)
    },
    onFormControlUpdate(controlName, newValue, status) {
      const { modelValue } = this

      if (controlName === 'label' && status.valid) {
        const mergedUpdate = merge(modelValue, {
          params: { label: newValue },
        })

        this.$emit('update', mergedUpdate)
      }

      if (controlName === 'url' && status.valid) {
        this.onActionUpdate({
          params: { url: newValue },
          type: 'external',
        })
      }
    },
  },
}
</script>
