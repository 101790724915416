import { OlympusClient } from '@/OlympusClient'

import { type CampaignResourceCreate, type CampaignResource } from '../types'

export const createResourceHandler = async ({
  brandId,
  locationId,
  name,
  subject,
  sections,
}: CampaignResourceCreate): Promise<CampaignResource> => {
  const campaign = await OlympusClient.marketing.v1.campaigns.createCampaign({
    body: {
      brandId,
      conditions: ['email-subscribed'],
      locationId,
      name,
      transport: 'email',
      type: 'one-time',
    },
  })

  const { id: emailTemplateId } =
    await OlympusClient.notifications.v1.templates.emails.createEmailTemplate({
      body: {
        brandId,
        locationId,
        name,
        subject,
      },
    })

  await OlympusClient.notifications.v1.templates.emails.email.updateEmailTemplate(
    {
      body: { sections },
      params: { emailTemplateId },
    }
  )

  await OlympusClient.marketing.v1.campaigns.campaign.createBlock({
    body: {
      name: null,
      params: {
        expiresAt: null,
        next: null,
        params: {
          templateId: emailTemplateId,
          // The client cannot create in-code templates, so this is always null
          templateSlug: null,
        },
        type: 'send-email',
      },
      type: 'action',
    },
    params: { campaignId: campaign.id },
  })

  return {
    ...campaign,
    emailTemplateId,
    executeAt: campaign.executeAt ? new Date(campaign.executeAt) : null,
    sections,
    subject,
  }
}
