<i18n locale="en">
  {
    "download": "Download",
    "empty": "No PDF to display",
    "error": "Failed to load PDF"
  }
</i18n>

<template>
  <PreviewSheet
    class="pdf-preview"
    background="secondary"
    border="secondary"
    radius="0"
  >
    <div class="flex-col gap-16">
      <div class="flex-col gap-8 align-center">
        <div class="flex-col gap-4">
          <PreviewTypography :text="params.heading" variant="heading2" />
          <PreviewTypography
            v-if="params.subheading"
            :text="params.subheading"
            variant="paragraphRegular"
            color="secondary"
          />
        </div>
        <PreviewButton :title="t('download')" caret />
      </div>
      <VuePdfEmbed
        v-if="params.pdf && !error"
        :source="params.pdf"
        @loading-failed="error = true"
      />
      <PreviewTypography
        v-else-if="params.pdf && error"
        :text="t('error')"
        class="text--center"
        color="danger"
        variant="paragraphLarge"
      />
      <PreviewTypography
        v-else-if="!params.pdf"
        :text="t('empty')"
        class="text--center"
        color="danger"
        variant="paragraphLarge"
      />
    </div>
  </PreviewSheet>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import VuePdfEmbed from 'vue-pdf-embed'

import PreviewButton from '@/lib/builder/public/preview/PreviewButton.vue'
import PreviewSheet from '@/lib/builder/public/preview/PreviewSheet.vue'
import PreviewTypography from '@/lib/builder/public/preview/PreviewTypography.vue'

import type { PdfSection } from '../../../WebsiteBuilder.types'

const props = defineProps<{
  content: PdfSection
}>()

const { t } = useI18n()

const params = computed(() => props.content.params)

const error = ref(false)

watch(
  () => params.value.pdf,
  () => {
    error.value = false
  }
)
</script>

<style lang="scss" scoped>
.pdf-preview {
  padding: 120px;
}
</style>
