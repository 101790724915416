<i18n locale="en">
{
  "errors": {
    "load": "There was a problem loading the analytics for this campaign"
  },
  "labels": {
    "sales": "Sales",
    "orders": "Orders",
    "click-rate": "Click Rate",
    "sent": "Sent",
    "delivered": "Delivered",
    "opened": "Opened",
    "bounced": "Bounced"
  }
}
</i18n>

<template>
  <div v-if="isLoading" class="flex-row align-center justify-center">
    <OwnProgressSpinner size="24" />
  </div>
  <div v-else class="flex-col gap-6">
    <div
      v-for="({ label, value }, i) in analytics"
      :key="i"
      class="flex-row justify-between w-full"
    >
      <OwnType :text="label" variant="subtitle" color="primary" />
      <OwnType :text="value" variant="subtitle" />
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'

import { logError } from '@/logger'
import notify from '@/mixins/notify'
import { OlympusClient } from '@/OlympusClient'
import { OwnProgressSpinner } from '@/ui/OwnProgressSpinner'
import { OwnType } from '@/ui/OwnType'
import { formatNumberValue } from '@/utils/helpers'

export default {
  name: 'CampaignAnalytics',
  components: { OwnProgressSpinner, OwnType },
  mixins: [notify],
  props: {
    /** The ID of the current campaign */
    campaign: { type: String, required: true },

    /** The transport of the current campaign */
    transport: { type: String, default: null },
  },
  setup() {
    const { t } = useI18n()

    return { t }
  },
  data() {
    return {
      analytics: [],
      isLoading: true,
    }
  },
  mounted() {
    this.loadAnalytics()
  },
  methods: {
    async loadAnalytics() {
      try {
        const { campaign: campaignId } = this
        this.isLoading = true
        const analytics =
          await OlympusClient.marketing.v1.campaigns.campaign.getCampaignAnalytics(
            { params: { campaignId } }
          )

        this.analytics = [
          ...(this.transport === 'email'
            ? [
                {
                  format: 'currency',
                  name: 'sales',
                  value: analytics.sales,
                },
                {
                  name: 'orders',
                  value: analytics.orders,
                },
                {
                  format: 'percentage',
                  name: 'click-rate',
                  value: analytics.clicked,
                },
              ]
            : []),
          {
            name: 'sent',
            value: analytics.sent,
          },
          {
            format: 'percentage',
            name: 'delivered',
            value: analytics.delivered,
          },
          ...(this.transport === 'email'
            ? [
                {
                  format: 'percentage',
                  name: 'opened',
                  value: analytics.opened,
                },
                {
                  format: 'percentage',
                  name: 'bounced',
                  value: analytics.bounced,
                },
              ]
            : []),
        ].map(({ format, name, value }) => ({
          label: this.t(`labels.${name}`),
          value: formatNumberValue(value, format),
        }))
      } catch (error) {
        logError(error)
        this.$notify(this.t('errors.load'), 'error')
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
