import { PhTextAlignLeft } from '@phosphor-icons/vue'

import { type BuilderBlockModule } from '@/lib/builder/types'
import i18nPlugin from '@/plugins/i18n'

import {
  type CampaignResource,
  type SmsSection,
  type TextSectionCreate,
  type TextSectionUpdate,
} from '../../types'
import { textBlockMethods } from '../common/methods'

import TextEditor from './editor/TextEditor.vue'
import { createTextSection } from './methods/createTextData'
import TextPreview from './preview/TextPreview.vue'
const i18n = i18nPlugin.global

export const TextBlockModule: BuilderBlockModule<
  SmsSection,
  TextSectionCreate,
  TextSectionUpdate,
  CampaignResource
> = {
  canAdd: false,
  canDelete: false,
  createData: createTextSection,
  editor: TextEditor,
  meta: [
    {
      icon: PhTextAlignLeft,
      key: 'text',
      label: i18n.t('text-builder.blocks.text'),
    },
  ],
  methods: textBlockMethods,
  // Avoid empty space at the bottom of the phone outline while keeping the text bubble vertically aligned with the edit button
  minimumPreviewHeightPx: 53,
  preview: TextPreview,
}
