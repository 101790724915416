<i18n locale="en">
{
  "actions": {
    "fix": "Update payment method"
  },
  "labels": {
    "title": "Payment failed for {locationName}",
    "body": "We haven't been able to process payment for this location.  Please update your payment method."
  }
}
</i18n>

<template>
  <OwnCallout
    v-if="hasLocationsUnderCollections"
    :title="title"
    :body="t('labels.body')"
    type="danger"
    :click-handler="onFixClick"
  >
  </OwnCallout>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import { useMappedGetter } from '@/store'
import { OwnCallout } from '@/ui/OwnCallout'

const router = useRouter()
const route = useRoute()
const { t } = useI18n()

const locationsUnderCollection = useMappedGetter<
  Array<{ id: string; name: string }> | undefined
>('core/brand/active/locationsUnderCollections')

const hasLocationsUnderCollections = computed(() => {
  if (locationsUnderCollection.value) {
    return locationsUnderCollection.value.length > 0
  }

  return false
})

const title = computed(() => {
  if (
    locationsUnderCollection.value &&
    locationsUnderCollection.value?.length
  ) {
    return t('labels.title', {
      locationName: locationsUnderCollection.value[0]?.name,
    })
  }

  return undefined
})

const isOnCollectionsPage = computed(() => {
  return route?.name === 'brand.billing.methods'
})

const onFixClick = async () => {
  if (!isOnCollectionsPage.value) {
    await router.push({
      name: 'brand.billing.methods',
    })
  }
}
</script>
