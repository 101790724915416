<i18n locale="en">
{
  "title": "Title",
  "description": "Description"
}
</i18n>

<template>
  <div class="flex-col gap-8">
    <OwnInputContainer :label="t('title')">
      <OwnInput v-model="title.value" />
    </OwnInputContainer>
    <OwnInputContainer :label="t('description')">
      <OwnTextarea v-model="description.value" />
    </OwnInputContainer>
  </div>
</template>

<script>
import set from 'lodash/set'
import { useI18n } from 'vue-i18n'
import { mapActions, mapGetters } from 'vuex'

import { FormBuilder, Validators } from '@/forms'
import { OwnInput } from '@/ui/OwnInput'
import { OwnInputContainer } from '@/ui/OwnInputContainer'
import { OwnTextarea } from '@/ui/OwnTextarea'

export default {
  name: 'FormHeaderEditor',
  components: {
    OwnInput,
    OwnInputContainer,
    OwnTextarea,
  },
  mixins: [
    FormBuilder({
      description: {
        debounce: 250,
        validators: [Validators.required],
      },
      title: {
        debounce: 250,
        validators: [Validators.required],
      },
    }),
  ],
  setup() {
    const { t } = useI18n()

    return { t }
  },
  computed: {
    ...mapGetters({
      currentResource: 'formBuilder/currentResource',
    }),
  },
  created() {
    const { currentResource } = this

    this.setInitialFormValues({
      description: currentResource?.description,
      title: currentResource?.title,
    })
  },
  methods: {
    ...mapActions({
      updateResource: 'formBuilder/updateResource',
    }),
    async onFormControlUpdate(controlName, update) {
      await this.updateResource(set({}, controlName, update))
    },
  },
}
</script>
