import { type ActionContext } from 'vuex'

import { type CoreModule } from '@/lib/store/types'

import {
  type BlockCore,
  type BuilderState,
  type ResourceCore,
} from '../../types'

export const resetBlock = async <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  { commit }: ActionContext<BuilderState<TResource, TBlock>, CoreModule>,
  payload: TBlock
): Promise<void> => {
  commit('_builder_SET_BLOCK', {
    data: payload,
    target: ['current', 'original'],
  })
}
