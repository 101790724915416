import { type CreateBlockData } from '@/lib/builder/types'

import type {
  HeadlineSubheadingSection,
  HeadlineSubheadingSectionCreate,
} from '../../../../types/FormBuilder.types'

export const createHeadlineData = ({
  type,
  brandId,
}: CreateBlockData<HeadlineSubheadingSection>): HeadlineSubheadingSectionCreate => ({
  brandId,
  params: {
    text: '',
  },
  type,
})
