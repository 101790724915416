import { VueQueryPlugin } from '@tanstack/vue-query'
import { MotionPlugin } from '@vueuse/motion'
import { createPinia } from 'pinia'
import { createApp } from 'vue'

import { initializeDatadog } from '@/plugins/datadog'

import App from './App/App.vue'
import { AnalyticsPlugin } from './plugins/analytics'
import FeatureFlagsPlugin from './plugins/featureFlags'
import i18n from './plugins/i18n'
import { SprigPlugin } from './plugins/sprig'
import { router } from './router/router'
import store from './store'

import './styles/main.scss'

const pinia = createPinia()

const app = createApp(App)

initializeDatadog()

app.config.performance = import.meta.env.MODE !== 'production'

app.use(i18n)
app.use(pinia)
app.use(router)
app.use(store)
app.use(MotionPlugin)
app.use(FeatureFlagsPlugin)
app.use(AnalyticsPlugin)
app.use(SprigPlugin)
app.use(VueQueryPlugin)

app.mount('#app')
