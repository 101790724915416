import { removeObserver } from '@/utils/helpers'

import {
  type BlockCore,
  type BuilderState,
  type ResourceCore,
} from '../../types'

export const setResourceMutation = <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  state: BuilderState<TResource, TBlock>,
  {
    data,
    target = ['current'],
  }: { data: TResource; target: ['current' | 'original'] }
): void => {
  if (target.includes('current')) {
    state._builder_currentState.resource = removeObserver(data)
  }

  if (target.includes('original')) {
    state._builder_originalState.resource = removeObserver(data)
  }
}
