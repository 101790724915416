<i18n locale="en">
{
  "titles" : {
    "add": "Add Existing Page",
    "update": "Update Page"
  },
  "actions": {
    "update": "Update",
    "add": "Add"
  },
  "errors": {
    "load-pages": "Unable to load pages"
  }
}
</i18n>

<template>
  <OwnForm :submit-handler="onUpdate">
    <OwnDialog :model-value="true" class="add-link-dialog" @cancel="close">
      <template #heading>
        <OwnDialogHeading
          :title="editItem ? t('titles.update') : t('titles.add')"
          @cancel="close"
        />
      </template>

      <OwnDialogContent scroll>
        <FullScreenLoader v-if="isLoading" />
        <OwnTouchRadioGroup
          v-else
          v-model="selectedOption"
          :options="options"
          :columns="2"
        />
      </OwnDialogContent>

      <template #footer>
        <OwnDialogFooter>
          <OwnButton
            :text="editItem ? t('actions.update') : t('actions.add')"
            primary
            type="submit"
          >
            <template #icon-right>
              <PhPlusCircle v-if="!editItem" size="24" />
            </template>
          </OwnButton>
        </OwnDialogFooter>
      </template>
    </OwnDialog>
  </OwnForm>
</template>

<script>
import { PhPlusCircle } from '@phosphor-icons/vue'
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'

import FullScreenLoader from '@/components/loaders/FullScreenLoader.vue'
import { logError } from '@/logger'
import notify from '@/mixins/notify'
import { OlympusClient } from '@/OlympusClient'
import { OwnButton } from '@/ui/OwnButton'
import {
  OwnDialog,
  OwnDialogContent,
  OwnDialogFooter,
  OwnDialogHeading,
} from '@/ui/OwnDialog'
import { OwnForm } from '@/ui/OwnForm'
import { OwnTouchRadioGroup } from '@/ui/OwnTouchRadioGroup'

export default {
  name: 'AddPageDialog',
  components: {
    FullScreenLoader,
    OwnButton,
    OwnDialog,
    OwnDialogContent,
    OwnDialogFooter,
    OwnDialogHeading,
    OwnForm,
    OwnTouchRadioGroup,
    PhPlusCircle,
  },
  mixins: [notify],
  props: {
    editItem: { type: Object, default: undefined },
  },
  emits: ['update', 'close'],
  setup() {
    const { t } = useI18n()

    return { t }
  },
  data() {
    return {
      isLoading: false,
      options: [],
      selectedOption: undefined,
    }
  },
  computed: {
    ...mapGetters({
      activeBrandId: 'core/brand/active/id',
    }),
  },
  async created() {
    const { editItem } = this

    await this.loadPageOptions()

    if (editItem) {
      this.selectedOption = editItem.params.pageId
    } else if (this.options.length > 0) {
      this.selectedOption = this.options[0].value
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async loadPageOptions() {
      const { activeBrandId } = this

      try {
        this.isLoading = true

        const { results } = await OlympusClient.funnel.v1.pages.getPages({
          query: {
            brandId: activeBrandId,
            limit: 50,
          },
        })

        this.options = results.map((page) => ({
          label: page.title,
          value: page.id,
        }))
      } catch (err) {
        logError(err)
        this.$notify(this.t('errors.load-pages'), 'error')
      } finally {
        this.isLoading = false
      }
    },
    onUpdate() {
      const { options, selectedOption } = this

      const fullSelectedOption = options.find(
        (option) => option.value === selectedOption
      )

      this.$emit('update', {
        params: {
          label: fullSelectedOption.label,
          pageId: fullSelectedOption.value,
        },
        type: 'page',
      })

      this.close()
    },
  },
}
</script>
