<template>
  <div class="gift-card-type-selector flex-row gap-4">
    <div
      v-for="(image, index) in images"
      :key="image.name"
      class="gift-card-type-selector__image-wrapper"
      :style="{
        borderColor: index === 1 ? themeBrandColor : 'transparent',
        filter: index !== 1 && 'grayscale(100%)',
      }"
    >
      <component :is="image" class="gift-card-type-selector__image" />
    </div>
  </div>
</template>

<script>
import GenericGiftCard from '@/components/gift-card-images/GenericGiftCard.vue'
import HappyBirthday from '@/components/gift-card-images/HappyBirthday.vue'
import HappyHolidays from '@/components/gift-card-images/HappyHolidays.vue'
import Thanks from '@/components/gift-card-images/Thanks.vue'
import { useMergedTheme } from '@/lib/builder/public/preview/mixins/useMergedTheme'

export default {
  name: 'GiftCardTypeSelector',
  mixins: [useMergedTheme],
  data() {
    return {
      images: [GenericGiftCard, HappyBirthday, HappyHolidays, Thanks],
    }
  },
  computed: {
    themeBrandColor() {
      const { mergedTheme } = this
      return mergedTheme?.colors?.brand?.primary
    },
  },
}
</script>

<style lang="scss" scoped>
.gift-card-type-selector {
  &__image-wrapper {
    width: 290px;
    border-radius: 8px;
    border-width: 2px;
    border-style: solid;
    padding: 2px 2px 0 2px;
  }
  &__image {
    border-radius: 8px;
  }
}
</style>
