import { logError } from '@/logger'

import { type Brand } from '../types'

const RECENT_BRANDS_KEY = 'OWNER_RECENT_BRANDS'

const MAX_RECENT_BRANDS = 8

export const setRecentBrands = (value: Brand[]): void => {
  localStorage.setItem(RECENT_BRANDS_KEY, JSON.stringify(value))
}

export const getRecentBrands = (): Brand[] => {
  try {
    const storedBrands = localStorage.getItem(RECENT_BRANDS_KEY)

    if (storedBrands) {
      return JSON.parse(storedBrands)
    } else {
      return []
    }
  } catch (error) {
    logError(error)

    return []
  }
}

export const saveToRecentBrands = (newBrand: Brand): void => {
  const storedBrands = getRecentBrands()

  localStorage.setItem(
    RECENT_BRANDS_KEY,
    JSON.stringify(
      [
        newBrand,
        ...storedBrands.filter((brand) => brand.id !== newBrand.id),
      ].slice(0, MAX_RECENT_BRANDS)
    )
  )
}
