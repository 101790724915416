import { type CreateBlockData } from '@/lib/builder/types'

import {
  type LocationsSectionCreate,
  type LocationsSection,
} from '../../../WebsiteBuilder.types'

export const createLocationsData = ({
  brandId,
}: CreateBlockData<LocationsSection>): LocationsSectionCreate => ({
  brandId,
  params: null,
  type: 'locations',
})
