import merge from 'lodash/merge'

import { type TemplateData } from '@/lib/builder/types'
import i18nPlugin from '@/plugins/i18n'

import {
  type FormCreate,
  type FormSectionCreate,
} from '../../types/FormBuilder.types'
import { FormattedInputBlockModule } from '../blocks/formatted-input'
import { SelectBlockModule } from '../blocks/select'
import { TextInputBlockModule } from '../blocks/text-input'
const i18n = i18nPlugin.global

export const cateringTemplate = async (
  brandId: string
): Promise<TemplateData<FormCreate, FormSectionCreate>> => ({
  blocks: [
    FormattedInputBlockModule.createData({ brandId, type: 'name' }),
    FormattedInputBlockModule.createData({ brandId, type: 'email' }),
    FormattedInputBlockModule.createData({ brandId, type: 'phone' }),
    merge(FormattedInputBlockModule.createData({ brandId, type: 'date' }), {
      params: {
        label: i18n.t('form-builder.templates.catering.date-label'),
        required: true,
      },
    }),
    merge(SelectBlockModule.createData({ brandId, type: 'select' }), {
      params: {
        label: i18n.t('form-builder.templates.catering.number.label'),
        options: ['one', 'ten', 'twenty', 'thirty', 'forty', 'fifty'].map(
          (key) => i18n.t(`form-builder.templates.catering.number.${key}`)
        ),
        required: true,
      },
    }),
    merge(SelectBlockModule.createData({ brandId, type: 'select' }), {
      params: {
        label: i18n.t('form-builder.templates.catering.fulfillment.label'),
        options: ['delivery', 'pickup'].map((key) =>
          i18n.t(`form-builder.templates.catering.fulfillment.${key}`)
        ),
        required: true,
      },
    }),
    merge(FormattedInputBlockModule.createData({ brandId, type: 'address' }), {
      params: {
        label: i18n.t('form-builder.templates.catering.address-label'),
        required: false,
      },
    }),
    merge(TextInputBlockModule.createData({ brandId, type: 'text-field' }), {
      params: {
        label: i18n.t('form-builder.templates.catering.items-label'),
        placeholder: null,
        required: true,
        variant: 'multiline',
      },
    }),
    merge(TextInputBlockModule.createData({ brandId, type: 'text-field' }), {
      params: {
        label: i18n.t('form-builder.templates.catering.allergies-label'),
        placeholder: null,
        required: false,
        variant: 'multiline',
      },
    }),
    merge(TextInputBlockModule.createData({ brandId, type: 'text-field' }), {
      params: {
        label: i18n.t('form-builder.templates.catering.notes-label'),
        placeholder: null,
        required: false,
        variant: 'multiline',
      },
    }),
  ],
  resource: {
    brandId,
    description: i18n.t('form-builder.templates.catering.description'),
    submit: i18n.t('form-builder.templates.catering.submit'),
    title: i18n.t('form-builder.templates.catering.title'),
    type: 'catering',
  },
})
