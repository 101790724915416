import merge from 'lodash/merge'

import { useBuilder } from '@/lib/builder/extension/useBuilder'

import type { FormattedInputSection } from '../../../../types/FormBuilder.types'

const formBuilderQueue = useBuilder('formBuilder')

const FormattedInputCommands = {
  updateBlock: formBuilderQueue.createBlockCommand<FormattedInputSection>({
    commit: async (currentData, ctx) => {
      return merge(currentData, ctx.update)
    },
  }),
}

export { FormattedInputCommands }
