<template>
  <div class="landing-hero-layout-card flex-row w-full">
    <PreviewSheet
      class="landing-hero-layout-card__container w-full"
      :style="containerStyles"
      row
      radius="large"
    >
      <div
        class="landing-hero-layout-card__content-container flex-col"
        radius="0"
        justify="center"
      >
        <LandingHeroContentGroup :id="id" max-width="654px" />
      </div>
      <div class="landing-hero-layout-card__image-container">
        <LandingHeroBackground :carousel-assets="carouselAssets" />
      </div>
    </PreviewSheet>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useWebsiteSection } from '@/features/website-builder/builder/common/useWebsiteSection'
import { useMergedTheme } from '@/lib/builder/public/preview/mixins/useMergedThemeComp'
import PreviewSheet from '@/lib/builder/public/preview/PreviewSheet.vue'

import LandingHeroBackground from './LandingHeroBackground.vue'
import LandingHeroContentGroup from './LandingHeroContentGroup.vue'

const props = defineProps<{
  id: string
}>()

const activeBlock = useWebsiteSection(props.id)

const carouselAssets = computed(() =>
  activeBlock.value?.params && 'carouselAssets' in activeBlock.value.params
    ? activeBlock.value?.params?.carouselAssets
    : undefined
)

const theme = useMergedTheme()

const containerStyles = computed(() => ({
  backgroundColor: theme.value?.colors.background.secondary,
}))
</script>

<style lang="scss" scoped>
.landing-hero-layout-card {
  padding: 0 8px 8px;

  &__container {
    padding: 8px;
    display: flex;
  }

  &__content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    min-height: 800px;
    flex: 1 1 50%;
    padding: 80px;
  }

  &__image-container {
    flex: 1 1 50%;

    & > div {
      border-radius: 24px;
    }
  }
}
</style>
