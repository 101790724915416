import { type CreateBlockData } from '@/lib/builder/types'

import {
  type FaqSection,
  type FaqSectionCreate,
} from '../../../WebsiteBuilder.types'

export const createFaqData = ({
  brandId,
}: CreateBlockData<FaqSection>): FaqSectionCreate => ({
  brandId,
  params: null,
  type: 'faq',
})
