<i18n locale="en">
{
  "title": "Give the Perfect Gift for Any Occasion"
}
</i18n>

<template>
  <PreviewSheet>
    <div class="gift-cards-preview flex-col">
      <GiftCardOrderType />
      <div class="gift-cards-preview__form-wrapper flex-col gap-12">
        <GiftCardTypeSelector />
        <div class="gift-cards-preview__form-layout flex-row">
          <div class="gift-cards-preview__form-heading flex-col gap-8">
            <PreviewDecorationLine class="gift-cards-preview__line" />
            <div class="flex-col gap-2">
              <PreviewTypographyLegacy
                :text="currentBlockParams.heading"
                variant="heading2"
              />
              <PreviewTypographyLegacy
                :text="currentBlockParams.subheading"
                variant="paragraphRegular"
              />
            </div>
          </div>
          <GiftCardForm class="gift-cards-preview__form" />
        </div>
      </div>
    </div>
  </PreviewSheet>
</template>

<script>
import PreviewTypographyLegacy from '@/lib/builder/public/preview/legacy/PreviewTypographyLegacy.vue'
import PreviewDecorationLine from '@/lib/builder/public/preview/PreviewDecorationLine.vue'
import PreviewSheet from '@/lib/builder/public/preview/PreviewSheet.vue'

import GiftCardForm from './GiftCardForm'
import GiftCardOrderType from './GiftCardOrderType'
import GiftCardTypeSelector from './GiftCardTypeSelector'

export default {
  name: 'GiftCardsPreview',
  components: {
    GiftCardForm,
    GiftCardOrderType,
    GiftCardTypeSelector,
    PreviewDecorationLine,
    PreviewSheet,
    PreviewTypographyLegacy,
  },
  props: {
    content: { type: Object, required: true },
  },
  computed: {
    currentBlockParams() {
      const { content } = this

      return content?.params
    },
  },
}
</script>

<style lang="scss" scoped>
.gift-cards-preview {
  width: 1280px;
  margin: 0 auto;
  &__line {
    width: 84px;
  }
  &__heading {
    margin-left: 16px;
    margin-top: 120px;
    margin-bottom: 120px;
    width: 720px;
  }
  &__form-wrapper {
    padding-bottom: 80px;
  }
  &__form-layout {
    gap: 126px;
  }
  &__form,
  &__form-heading {
    width: 50%;
  }
}
</style>
