<i18n locale="en">
{
  "map": "Google Map",
  "directions": "Get Directions",
  "address": "Address",
  "contacts": "Contacts",
  "order": "Order Now",
  "see-hours": "See hours",
  "open-until": "Open until xx:xx"
}
</i18n>
<template>
  <PreviewSheet class="info-sheet" radius="medium" background="secondary">
    <div class="flex-row gap-2" spacing="2" row>
      <PreviewSheet class="info-sheet__map" background="divider">
        <div class="flex-col gap-4 align-center">
          <PreviewIcon :icon="PhMapPin" color="secondary" size="48" />
          <PreviewTypography
            :text="t('map')"
            variant="heading3"
            color="secondary"
          />
        </div>
      </PreviewSheet>
      <div class="info-sheet__info flex-col">
        <div class="flex-row align-center justify-between">
          <div class="flex-col gap-1">
            <PreviewTypography
              :text="brand.name"
              variant="titleSmall"
              color="secondary"
            />
            <PreviewTypography
              :text="cityState"
              variant="titleSmall"
              color="primary"
            />
          </div>
          <PreviewButton :title="t('directions')" variant="secondary" caret />
        </div>
        <div class="flex-col gap-8">
          <div class="flex-row gap-20">
            <div v-if="address" class="flex-col gap-2">
              <PreviewTypography
                :text="t('address')"
                variant="paragraphSmall"
                color="secondary"
              />
              <div class="flex-col gap-1">
                <PreviewTypography
                  :text="address.street"
                  variant="paragraphSmall"
                  color="primary"
                />
                <PreviewTypography
                  v-if="address.unit"
                  :text="address.unit"
                  variant="paragraphSmall"
                  color="primary"
                />
                <PreviewTypography
                  :text="`${address.city}, ${address.state} ${address.zip}`"
                  variant="paragraphSmall"
                  color="primary"
                />
              </div>
            </div>
            <div
              v-if="location.contact.phone || location.contact.email"
              class="flex-col gap-2"
            >
              <PreviewTypography
                :text="t('contacts')"
                variant="paragraphSmall"
                color="secondary"
              />
              <div class="flex-col gap-1">
                <PreviewTypography
                  v-if="location.contact.phone"
                  :text="formatPhoneNumber(location.contact.phone)"
                  variant="paragraphSmall"
                  color="primary"
                />
                <PreviewTypography
                  v-if="location.contact.email"
                  :text="location.contact.email"
                  variant="paragraphSmall"
                  color="primary"
                />
              </div>
            </div>
          </div>
          <PreviewHr />
          <div class="flex-row align-center justify-between">
            <div class="flex-row gap-4 align-center">
              <PreviewTypography
                class="info-sheet__nowrap"
                :text="t('open-until')"
                color="primary"
                variant="paragraphSmall"
              />
              <div class="flex-row gap-1 align-center">
                <PreviewTypography
                  :text="t('see-hours')"
                  variant="paragraphSmall"
                  color="secondary"
                />
                <PreviewIcon :icon="PhCaretRight" color="secondary" size="12" />
              </div>
            </div>
            <PreviewButton :title="t('order')" caret />
          </div>
        </div>
      </div>
    </div>
  </PreviewSheet>
</template>
<script lang="ts" setup>
import { PhMapPin, PhCaretRight } from '@phosphor-icons/vue'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import type { Brand, Location } from '@/core/types'
import PreviewButton from '@/lib/builder/public/preview/PreviewButton.vue'
import PreviewHr from '@/lib/builder/public/preview/PreviewHr.vue'
import PreviewIcon from '@/lib/builder/public/preview/PreviewIcon.vue'
import PreviewSheet from '@/lib/builder/public/preview/PreviewSheet.vue'
import PreviewTypography from '@/lib/builder/public/preview/PreviewTypography.vue'
import { useMappedGetter } from '@/store'
import { formatPhoneNumber } from '@/utils/phone'

const props = defineProps<{ location: Location }>()

const { t } = useI18n()

const brand = useMappedGetter<Brand>('core/brand/active/*')

const address = computed(() => props.location.address)

const cityState = computed(() => {
  if (address.value) {
    const { city, state } = address.value
    return `${city}, ${state}`
  }

  return ''
})
</script>
<style lang="scss" scoped>
.info-sheet {
  padding: 8px;

  &__info {
    padding: 24px;
    width: 100%;
    gap: 106px;
  }

  &__map {
    width: 450px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__nowrap {
    white-space: nowrap;
  }
}
</style>
