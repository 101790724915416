import {
  type BlockCore,
  type BuilderState,
  type ResourceCore,
} from '../../types'

export const forceDiscardAllCommandsMutation = <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  state: BuilderState<TResource, TBlock>
): void => {
  state._builder_commandQueue = []
}
