<i18n locale="en">
{
  "title": "Generate An Email",
  "actions": {
    "okay": "Okay"
  },
  "errors": {
    "generate": "You need to generate an email to continue. On the left, enter your occasion and optionally select a menu item, coupon, and tone of voice and click “Generate”."
  }
}
</i18n>

<template>
  <OwnDialog :model-value="true" @cancel="$emit('close')">
    <template #heading>
      <OwnDialogHeading :title="t('title')" @cancel="$emit('close')" />
    </template>

    <OwnDialogContent scroll>
      <OwnType :text="t('errors.generate')" />
    </OwnDialogContent>

    <template #footer>
      <OwnDialogFooter>
        <OwnButton :text="t('actions.okay')" primary @click="$emit('close')" />
      </OwnDialogFooter>
    </template>
  </OwnDialog>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

import { OwnButton } from '@/ui/OwnButton'
import {
  OwnDialog,
  OwnDialogContent,
  OwnDialogFooter,
  OwnDialogHeading,
} from '@/ui/OwnDialog'
import { OwnType } from '@/ui/OwnType'

defineEmits<{ close: [] }>()

const { t } = useI18n()
</script>
