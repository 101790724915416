<i18n locale="en">
{
  "add-your-resume": "Add your resume / cover letter (PDF only)",
  "apply": "Apply",
  "fields": {
    "application": "Application",
    "attachment": "Attachment",
    "email": "Email",
    "full-name": "Full name",
    "jobs": "Positions",
    "location": "Location",
    "message": "Message",
    "phone": "Phone number"
  },
  "placeholders": {
    "message": "Anything to share as we consider your application?",
    "phone": "Phone number"
  },
  "salary": {
    "range": "Salary range is {min} to {max}",
    "starting-at": "Salaries starting at {salary}",
    "up-to": "Salaries up to {salary}"
  }
}
</i18n>

<template>
  <div class="flex-col gap-4">
    <PreviewFormGroup :title="t('fields.location')">
      <div class="careers-form__option-columns">
        <div
          v-for="({ id, name, address }, index) of locations"
          :key="id"
          class="flex-row gap-3 align-center"
        >
          <PreviewRadio
            :checked="activeLocationId ? activeLocationId === id : index === 0"
          />
          <div class="flex-col gap-1">
            <PreviewTypography
              variant="paragraphRegular"
              color="primary"
              :text="name"
            />
            <PreviewTypography
              v-if="address"
              variant="paragraphSmall"
              color="secondary"
              :text="address.street"
            />
          </div>
        </div>
      </div>
    </PreviewFormGroup>
    <PreviewFormGroup v-if="jobs.length > 0" :title="t('fields.jobs')">
      <div class="careers-form__option-columns">
        <div
          v-for="job in jobs"
          :key="job.id"
          class="flex-row gap-3 align-center"
        >
          <PreviewCheckbox />
          <div class="flex-col gap-1">
            <PreviewTypography
              variant="paragraphRegular"
              color="primary"
              :text="job.title"
            />
            <PreviewTypography
              v-if="job.salary.max || job.salary.min"
              variant="paragraphSmall"
              color="secondary"
              :text="salaryRange(job.salary)"
            />
          </div>
        </div>
      </div>
    </PreviewFormGroup>
    <PreviewFormGroup :title="t('fields.application')">
      <div class="flex-col gap-6">
        <div class="flex-col gap-2">
          <PreviewTypography
            color="primary"
            variant="paragraphRegular"
            :text="t('fields.full-name')"
          />
          <PreviewTextField :placeholder="t('fields.full-name')" />
        </div>
        <div class="w-full flex-col gap-2">
          <PreviewTypography
            color="primary"
            variant="paragraphRegular"
            :text="t('fields.email')"
          />
          <PreviewTextField :placeholder="t('fields.email')" />
        </div>
        <div class="w-full flex-col gap-2">
          <PreviewTypography
            color="primary"
            variant="paragraphRegular"
            :text="t('fields.phone')"
          />
          <PreviewTextField :placeholder="t('placeholders.phone')" />
        </div>
        <div class="w-full flex-col gap-2">
          <PreviewTypography
            color="primary"
            variant="paragraphRegular"
            :text="t('fields.message')"
          />
          <PreviewTextarea :placeholder="t('placeholders.message')" :rows="5" />
        </div>
        <div class="w-full flex-col gap-2">
          <PreviewTypography
            color="primary"
            variant="paragraphRegular"
            :text="t('fields.attachment')"
          />
          <div
            class="careers-form__upload flex-row gap-1 justify-between"
            :style="uploadStyle"
          >
            <PreviewTypography
              variant="paragraphRegular"
              :text="t('add-your-resume')"
            />
            <PhPaperclip weight="bold" />
          </div>
        </div>
      </div>
    </PreviewFormGroup>
    <PreviewButton class="careers-form__submit-button" :title="t('apply')" />
  </div>
</template>

<script>
import { PhPaperclip } from '@phosphor-icons/vue'
import keyBy from 'lodash/keyBy'
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'

import { CurrencyFormatter } from '@/format'
import builderConsumer from '@/lib/builder/mixins/builderConsumer'
import { useMergedTheme } from '@/lib/builder/public/preview/mixins/useMergedTheme'
import PreviewButton from '@/lib/builder/public/preview/PreviewButton.vue'
import PreviewCheckbox from '@/lib/builder/public/preview/PreviewCheckbox.vue'
import PreviewFormGroup from '@/lib/builder/public/preview/PreviewFormGroup.vue'
import PreviewRadio from '@/lib/builder/public/preview/PreviewRadio.vue'
import PreviewTextarea from '@/lib/builder/public/preview/PreviewTextarea.vue'
import PreviewTextField from '@/lib/builder/public/preview/PreviewTextField.vue'
import PreviewTypography from '@/lib/builder/public/preview/PreviewTypography.vue'
import { logError } from '@/logger'
import { OlympusClient } from '@/OlympusClient'

export default {
  name: 'CareersForm',
  components: {
    PhPaperclip,
    PreviewButton,
    PreviewCheckbox,
    PreviewFormGroup,
    PreviewRadio,
    PreviewTextField,
    PreviewTextarea,
    PreviewTypography,
  },
  mixins: [builderConsumer('jobs', 'onMessage'), useMergedTheme],
  props: {
    /** Job IDs */
    jobIds: { type: Array, required: true },
  },
  setup() {
    const { t } = useI18n()

    return { t }
  },
  data() {
    return {
      jobsById: {},
    }
  },
  computed: {
    ...mapGetters({
      activeBrandId: 'core/brand/active/id',
      activeLocationId: 'core/location/active/id',
      locations: 'core/brand/active/locations',
      mergedTheme: 'core/brand/active/mergedTheme',
    }),
    jobs() {
      const { jobIds, jobsById } = this

      return jobIds
        .map((jobId) => jobsById[jobId])
        .filter((maybeJob) => maybeJob !== undefined)
    },
    uploadStyle() {
      const { mergedTheme } = this

      return {
        borderColor: mergedTheme.colors.background.divider,
        borderRadius: `${mergedTheme.borderRadius.control}rem`,
        color: mergedTheme.colors.text.secondary,
      }
    },
  },
  created() {
    this.fetchJobs()
  },
  methods: {
    async fetchJobs() {
      const { activeBrandId, activeLocationId, locations } = this

      // Default to the first location in the list if no location is selected
      const locationId = activeLocationId ?? locations[0]?.id

      // Don't fetch jobs without a location
      if (!locationId) {
        return
      }

      try {
        const response =
          await OlympusClient.dashboard.v1.brands.brand.careers.getJobs({
            query: {
              brandId: activeBrandId,
              limit: 100,
              locationId,
            },
          })

        this.jobsById = keyBy(response.results, 'id')
      } catch (err) {
        logError(err)
      }
    },
    async onMessage(message) {
      if (message.topic === 'newJobAdded' || message.topic === 'jobEdited') {
        await this.fetchJobs()
      }
    },
    salaryRange(salary) {
      if (salary.min && !salary.max) {
        return this.t('salary.starting-at', {
          salary: CurrencyFormatter(salary.min),
        })
      } else if (salary.max && !salary.min) {
        return this.t('salary.up-to', {
          salary: CurrencyFormatter(salary.max),
        })
      }
      return this.t('salary.range', {
        max: CurrencyFormatter(salary.max),
        min: CurrencyFormatter(salary.min),
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.careers-form {
  &__form-group {
    padding: 24px;
  }
  &__option-columns {
    display: grid;
    row-gap: 16px;
    column-gap: 24px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  &__upload {
    border-width: 1px;
    border-style: dashed;
    padding: 20px;
  }

  &__submit-button {
    width: 100%;
    justify-content: center;
  }
}
</style>
