import { createClient } from '@owner/olympus-client'
import { type AxiosError } from 'axios'

import { useAuthStore } from '@/auth/useAuthStore'
import { useEmitter } from '@/utils/useEmitter'

const VITE_OLYMPUS_URL = import.meta.env.VITE_OLYMPUS_URL

export const OlympusClient = createClient(
  VITE_OLYMPUS_URL,
  async () => {
    const authStore = useAuthStore()

    return {
      audience: 'hermes',
      client: 'web',
      csrfToken: authStore.csrfToken ?? undefined,
    }
  },
  {
    onResponseError: async (
      err: AxiosError<{ details?: { logout?: boolean } }>
    ) => {
      if (err.status === 401 && err.response?.data?.details?.logout) {
        // Emits to useSessionManager
        useEmitter.emit('auth.logout')
      }

      throw err
    },
  }
)

export interface PaginatedResult<TResource> {
  limit: number
  page: number
  pageCount: number
  results: TResource[]
  total: number
}
