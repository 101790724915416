import { OlympusClient } from '@/OlympusClient'

import emailBuilderModule from '../../store'
import {
  type CampaignResource,
  type EmailSection,
  type EmailSectionCreate,
} from '../../types'

export const createBlockHandler = async <
  TSection extends EmailSection,
  TSectionCreate extends EmailSectionCreate,
>(
  createData: TSectionCreate
): Promise<TSection> => {
  const { brandId } = emailBuilderModule?.getters
    ?.currentResource as unknown as CampaignResource

  return (await OlympusClient.notifications.v1.templates.emails.sections.createEmailSection(
    {
      body: {
        brandId,
        locationId: null,
        ...createData,
      },
    }
  )) as TSection
}
