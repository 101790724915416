import mixpanel from 'mixpanel-browser'
import { type Plugin } from 'vue'

const VITE_DEBUG_ANALYTICS = import.meta.env.VITE_DEBUG_ANALYTICS
const VITE_MIXPANEL_PROJECT = import.meta.env.VITE_MIXPANEL_PROJECT

const isMixpanelEnabled =
  (VITE_DEBUG_ANALYTICS === 'true' && !!VITE_MIXPANEL_PROJECT) ||
  (import.meta.env.MODE === 'production' && VITE_MIXPANEL_PROJECT)

/**
 * Track an analytics event
 * @param eventName Name of event to track
 * @param [args] Extra Args to pass
 */
const track = (eventName: string, args: Record<string, unknown> = {}): void => {
  if (isMixpanelEnabled) {
    mixpanel.track(eventName, args)
  }
}

/**
 * Identify User
 * @param userId Id Of User
 */
const identify = (userId: string): void => {
  if (isMixpanelEnabled) {
    mixpanel.identify(userId)
  }
}

let prevPage: string | undefined

/**
 * Abstracts `track` to manage page loads.
 * Dedupes page load calls
 * @param pageName Name of page being loaded
 */
export const sendPageLoad = (pageName: string): void => {
  if (prevPage !== pageName) {
    prevPage = pageName

    track('Page Load', {
      pageName,
    })
  }
}

/**
 * Reset user id (logout)
 */
const reset = (): void => {
  if (isMixpanelEnabled) {
    mixpanel.reset()
  }
}

/**
 * Sets specific data about user
 * @param data Data to set
 */
const setUserData = (data: Record<string, unknown>): void => {
  if (isMixpanelEnabled) {
    mixpanel.people.set({
      ...data,
      environment: import.meta.env.MODE,
    })
  }
}

export const analytics = {
  identify,
  reset,
  setUserData,
  track,
}

export const AnalyticsPlugin: Plugin = {
  install: function (app) {
    const debug =
      import.meta.env.MODE !== 'production' && VITE_DEBUG_ANALYTICS === 'true'

    if (isMixpanelEnabled) {
      mixpanel.init(VITE_MIXPANEL_PROJECT, {
        debug,
      })
      mixpanel.register({
        application: 'hermes',
      })
    }

    app.config.globalProperties.$analytics = analytics
  },
}
