import { useAuthStore } from '@/auth/useAuthStore'

import { getRedirectToLogin } from './common/getRedirectToLogin'
import { type HermesGuardFn } from './types'

export const checkAuthenticated: HermesGuardFn = async (to) => {
  const { meta = {} } = to

  const authStore = useAuthStore()

  if (meta.isPublic) {
    if (meta.preventAuthenticatedRedirect) return undefined

    if (authStore.isAuthenticated) return authStore.home
  } else {
    if (!authStore.isAuthenticated) return getRedirectToLogin(to)
  }
}
