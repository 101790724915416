import { PhRadioButton, PhCheckSquare } from '@phosphor-icons/vue'

import { type BuilderBlockModule } from '@/lib/builder/types'
import i18nPlugin from '@/plugins/i18n'

import type {
  Form,
  SelectSection,
  SelectSectionCreate,
  SelectSectionUpdate,
} from '../../../types/FormBuilder.types'
import { formBlockMethods } from '../common/methods'

import SelectEditor from './editor/SelectEditor.vue'
import { createSelectData } from './methods/createSelectData'
import SelectPreview from './preview/SelectPreview.vue'
const i18n = i18nPlugin.global

export const SelectBlockModule: BuilderBlockModule<
  SelectSection,
  SelectSectionCreate,
  SelectSectionUpdate,
  Form
> = {
  createData: createSelectData,
  editor: SelectEditor,
  meta: [
    {
      icon: PhRadioButton,
      key: 'select',
      label: i18n.t('form-builder.blocks.select'),
      sort: 3,
    },
    {
      icon: PhCheckSquare,
      key: 'multi-select',
      label: i18n.t('form-builder.blocks.multi-select'),
      sort: 5,
    },
  ],
  methods: formBlockMethods,
  preview: SelectPreview,
}
