<i18n locale="en">
{
  "coming-soon": "Coming soon",
  "method-options": {
    "ai": "Make it with AI",
    "custom": "Make it yourself"
  }
}
</i18n>

<template>
  <div class="template-sidebar flex-col gap-8">
    <OwnTouchRadioGroup v-model="selectedMethod" :options="methodOptions" />
    <OwnOption class="template-sidebar__ai">
      <div class="flex-row align-center justify-between gap-4">
        <div class="flex-row align-center justify-start gap-4">
          <PhSparkle size="24" />

          <div class="flex-col gap-1">
            <OwnType
              :text="t('method-options.ai')"
              variant="subtitle"
              color="primary"
            />
            <OwnType
              :text="t('coming-soon')"
              variant="paragraph-small"
              color="secondary"
            />
          </div>
        </div>
      </div>
    </OwnOption>
  </div>
</template>

<script>
import { PhSparkle, PhPaintBrushHousehold } from '@phosphor-icons/vue'
import { useI18n } from 'vue-i18n'

import { OwnOption } from '@/ui/OwnOption'
import { OwnTouchRadioGroup } from '@/ui/OwnTouchRadioGroup'
import { OwnType } from '@/ui/OwnType'
import { mapModel } from '@/utils/computed'

export default {
  name: 'TemplateSidebar',
  components: {
    OwnOption,
    OwnTouchRadioGroup,
    OwnType,
    PhSparkle,
  },
  props: {
    modelValue: { type: String, default: 'blank' },
    previewLoaded: { type: Boolean, required: true },
  },
  emits: ['update:modelValue'],
  setup() {
    const { t } = useI18n()

    return { t }
  },
  data() {
    return {
      methodOptions: [
        {
          icon: PhPaintBrushHousehold,
          label: this.t('method-options.custom'),
          value: 'custom',
        },
      ],
      selectedMethod: 'custom',
    }
  },
  computed: {
    ...mapModel('selectedOption'),
  },
}
</script>
<style lang="scss" scoped>
.template-sidebar {
  &__ai {
    margin-top: 12px;
    opacity: 0.5;
    box-shadow: none;
  }
}
</style>
