import { type ActionContext } from 'vuex'

import { type CoreModule } from '@/lib/store/types'

import {
  type Command,
  type BlockCore,
  type BuilderState,
  type ResourceCore,
} from '../../types'

export const stepToCommand = async <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  {
    state,
    commit,
    dispatch,
  }: ActionContext<BuilderState<TResource, TBlock>, CoreModule>,
  targetCommandId: string | undefined
): Promise<void> => {
  const currentCommandQueue = state._builder_commandQueue
  const currentCommand = state._builder_currentCommand

  let currentCommandIndex

  if (currentCommand) {
    currentCommandIndex = currentCommandQueue.findIndex(
      (command) => command.id === currentCommand
    )
  } else {
    currentCommandIndex = 0
  }

  const newCommandIndex = currentCommandQueue.findIndex(
    (command) => command.id === targetCommandId
  )

  if (currentCommandIndex === -1) {
    // Future: better error management
    throw new Error('Unable to find command on queue')
  }

  if (newCommandIndex !== -1 && newCommandIndex >= currentCommandIndex) {
    // Stepping Forward
    const commandsToApply = currentCommandQueue.slice(
      currentCommandIndex,
      newCommandIndex + 1
    )

    for (const command of commandsToApply) {
      await dispatch('_builder_applyCommand', command)
    }
  } else {
    // Stepping back
    let commandsToRevert

    // ISSUE: Tucker - This is a dangerous setup here.  If there is a data issue and
    // the command is not found it could wipe the whole command list out
    if (newCommandIndex !== -1) {
      commandsToRevert = currentCommandQueue.slice(
        newCommandIndex + 1,
        currentCommandIndex + 1
      )
    } else {
      commandsToRevert = currentCommandQueue
    }

    for (let i = commandsToRevert.length - 1; i >= 0; i--) {
      const command = commandsToRevert[i] as Command<TBlock>

      await dispatch('_builder_revertCommand', command)
    }
  }
}
