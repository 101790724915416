<i18n locale="en">
{
  "actions": {
    "enable": "Publish Page",
    "update": "Publish Updates"
  }
}
</i18n>

<template>
  <OwnButton :text="t(`actions.${publishType}`)" primary>
    <template #icon-right>
      <PhArrowCircleUp size="24" />
    </template>
  </OwnButton>
</template>

<script>
import { PhArrowCircleUp } from '@phosphor-icons/vue'
import { useI18n } from 'vue-i18n'

import { OwnButton } from '@/ui/OwnButton'

export default {
  name: 'WebsitePublishConfirmActions',
  components: {
    OwnButton,
    PhArrowCircleUp,
  },
  props: {
    /**
     * Is the resource already live?
     */
    isLive: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { t } = useI18n()

    return { t }
  },
  computed: {
    publishType() {
      const { isLive } = this

      return isLive ? 'update' : 'enable'
    },
  },
}
</script>
