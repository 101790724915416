<i18n locale="en">
{
  "label": "Font"
}
</i18n>

<template>
  <OwnInputContainer :label="t('label')">
    <OwnSegmentedControl
      v-slot="{ item }"
      :model-value="themeFont"
      :options="options"
      @update:model-value="onFontUpdate"
    >
      <FontIcon :font="item.value" />
    </OwnSegmentedControl>
  </OwnInputContainer>
</template>

<script>
import set from 'lodash/set'
import { useI18n } from 'vue-i18n'
import { mapActions } from 'vuex'

import { useCurrentTheme } from '@/lib/builder/public/preview/mixins/useCurrentTheme'
import { OwnInputContainer } from '@/ui/OwnInputContainer'
import { OwnSegmentedControl } from '@/ui/OwnSegmentedControl'

import FontIcon from './FontIcon.vue'

const options = [
  'modern',
  'geometric',
  'chic',
  'elegant',
  'urban',
  'super',
  'luxury',
].map((value) => ({ value }))

export default {
  name: 'FontEditor',
  components: {
    FontIcon,
    OwnInputContainer,
    OwnSegmentedControl,
  },
  mixins: [useCurrentTheme],
  data() {
    return {
      options,
    }
  },
  setup() {
    const { t } = useI18n()

    return { t }
  },
  computed: {
    themeFont() {
      const { currentTheme } = this

      return currentTheme?.overrides?.font?.pairing ?? 'modern'
    },
  },
  methods: {
    ...mapActions({
      updateResource: 'websiteBuilder/updateResource',
    }),
    async onFontUpdate(newFont) {
      const update = set({}, 'theme.overrides.font.pairing', newFont)

      await this.updateResource(update)
    },
  },
}
</script>
