<i18n locale="en">
{
  "labels": {
    "photo": "Profile Photo",
    "name": "Name",
    "comment": "Review",
    "rating": "Rating",
    "done": "Done"
  }
}
</i18n>

<template>
  <EditorLayout>
    <div class="flex-col gap-8">
      <OwnInputContainer
        :label="t('labels.comment')"
        :errors="comment.status.errors"
      >
        <OwnTextarea
          v-model="comment.value"
          :placeholder="t('labels.comment')"
        />
      </OwnInputContainer>
      <OwnInputContainer
        :label="t('labels.photo')"
        :errors="image.status.errors"
      >
        <ImageInput
          v-model="image.value"
          :label="t('labels.photo')"
          :crop-width="1000"
        />
      </OwnInputContainer>
      <OwnInputContainer :label="t('labels.name')" :errors="name.status.errors">
        <OwnInput v-model="name.value" :placeholder="t('labels.name')" />
      </OwnInputContainer>
      <OwnInputContainer
        :label="t('labels.rating')"
        :errors="stars.status.errors"
      >
        <StarRating v-model="stars.value" size="lg" editable />
      </OwnInputContainer>
    </div>

    <template #actions>
      <OwnButton
        :disabled="!isFormValid"
        :text="t('labels.done')"
        primary
        @click="$emit('back')"
      />
    </template>
  </EditorLayout>
</template>

<script>
import { useI18n } from 'vue-i18n'

import StarRating from '@/components/general/StarRating'
import { ImageInput } from '@/components/inputs/ImageInput'
import { FormBuilder, Validators } from '@/forms'
import EditorLayout from '@/lib/builder/public/editor/EditorLayout.vue'
import { OwnButton } from '@/ui/OwnButton'
import { OwnInput } from '@/ui/OwnInput'
import { OwnInputContainer } from '@/ui/OwnInputContainer'
import { OwnTextarea } from '@/ui/OwnTextarea'

export default {
  name: 'ReviewDetailEditor',
  components: {
    EditorLayout,
    ImageInput,
    OwnButton,
    OwnInput,
    OwnInputContainer,
    OwnTextarea,
    StarRating,
  },
  mixins: [
    FormBuilder({
      comment: {
        debounce: 250,
        validateOnInit: true,
        validators: [Validators.required],
      },
      image: {
        debounce: 250,
        validateOnInit: true,
        validators: [Validators.required],
      },
      name: {
        debounce: 250,
        validateOnInit: true,
        validators: [Validators.required],
      },
      stars: {
        debounce: 250,
        validateOnInit: true,
        validators: [Validators.required],
      },
    }),
  ],
  emits: ['back', 'update'],
  setup() {
    const { t } = useI18n()

    return { t }
  },
  props: {
    review: { type: Object, required: true },
  },
  created() {
    const { review } = this

    this.setInitialFormValues({
      comment: review.comment,
      image: review.image,
      name: review.name,
      stars: review.stars,
    })
  },
  methods: {
    onFormValuesUpdate(formValues) {
      this.$emit('update', formValues)
    },
  },
}
</script>
