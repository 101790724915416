import { type ActionContext } from 'vuex'

import { type CoreModule } from '@/lib/store/types'

import {
  type BlockCore,
  type BuilderState,
  type ResourceCore,
} from '../../types'

export const commitStagedCommands = async <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>({
  commit,
}: ActionContext<
  BuilderState<TResource, TBlock>,
  CoreModule
>): Promise<void> => {
  commit('_builder_COMMIT_STAGED_CHANGES')
}
