<template>
  <div class="careers-preview">
    <PreviewSheet
      class="careers-preview__callout"
      background="secondary"
      radius="large"
    >
      <div class="careers-preview__grid">
        <div class="flex-col gap-4">
          <PreviewTypography
            :text="currentBlockParams.heading"
            variant="heading3"
          />
          <PreviewTypography
            v-if="currentBlockParams?.subheading"
            :text="currentBlockParams?.subheading"
            variant="paragraphRegular"
          />
        </div>
        <CareersForm
          class="careers-preview__form"
          :job-ids="currentBlockParams?.jobs"
        />
      </div>
    </PreviewSheet>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import PreviewSheet from '@/lib/builder/public/preview/PreviewSheet.vue'
import PreviewTypography from '@/lib/builder/public/preview/PreviewTypography.vue'

import type { CareersSection } from '../../../WebsiteBuilder.types'

import CareersForm from './CareersForm.vue'

const props = defineProps<{
  content: CareersSection
}>()

const currentBlockParams = computed(() => props.content?.params)
</script>
<style lang="scss" scoped>
.careers-preview {
  padding: 8px;

  &__callout {
    padding: 72px 112px;
  }

  &__grid {
    display: grid;
    gap: 164px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
</style>
