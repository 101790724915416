<template>
  <div class="preview-textarea" :style="style">
    <PreviewTypographyLegacy
      :text="placeholder"
      color="placeholder"
      :style="placeholderStyle"
    />
  </div>
</template>

<script>
import PreviewTypographyLegacy from './legacy/PreviewTypographyLegacy.vue'

import { useMergedTheme } from './mixins/useMergedTheme'

export default {
  name: 'PreviewTextarea',
  components: { PreviewTypographyLegacy },
  mixins: [useMergedTheme],
  props: {
    placeholder: { type: String, default: '' },
    rows: { type: Number, default: 2 },
  },
  computed: {
    placeholderStyle() {
      const { rows } = this

      return {
        marginBottom: `${rows * 16}px`,
      }
    },
    style() {
      const { mergedTheme } = this

      return {
        backgroundColor: mergedTheme.colors.background.primary,
        borderColor: mergedTheme.colors.background.divider,
        borderRadius: `${mergedTheme.borderRadius.control}rem`,
        color: mergedTheme.colors.text.placeholder,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.preview-textarea {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 8px 16px;
  border-style: solid;
  border-width: 1px;
}
</style>
