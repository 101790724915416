<i18n locale="en">
{
  "heading": {
    "label": "Title",
    "placeholder": "Aa"
  },
  "pdf": {
    "empty-state": {
      "start": "Drag & drop or",
      "emphasis": "choose a PDF",
      "end": "to upload"
    },
    "label": "PDF",
    "replace": "Replace PDF",
    "select": "Select PDF"
  },
  "subheading": {
    "label": "Description",
    "placeholder": "Aa"
  },
  "upload-error": "Failed to upload PDF"
}
</i18n>

<template>
  <div class="pdf-editor flex-col gap-8">
    <OwnInputContainer
      :label="t('heading.label')"
      :errors="heading.status.errors"
      :value="heading.value"
      :max-chars="MAX_HEADING_LENGTH"
    >
      <OwnInput
        v-model="heading.value"
        :placeholder="t('heading.placeholder')"
      />
    </OwnInputContainer>
    <OwnInputContainer
      :label="t('subheading.label')"
      :errors="subheading.status.errors"
      :value="subheading.value"
      :max-chars="MAX_SUBHEADING_LENGTH"
    >
      <OwnTextarea
        v-model="subheading.value"
        :placeholder="t('subheading.placeholder')"
      />
    </OwnInputContainer>
    <OwnInputContainer :label="t('pdf.label')" :errors="pdf.status.errors">
      <OwnFile
        accept="application/pdf"
        :disabled="!!fileValue && status === 'loading'"
        :edit-button-replace-label="t('pdf.replace')"
        :edit-button-select-label="t('pdf.select')"
        :model-value="fileValue"
        @update:model-value="onFileSelected"
      >
        <template #empty>
          <PhFileText class="text-color-placeholder" size="32" />
          <p class="text--center">
            <OwnType
              color="secondary"
              el="span"
              :text="t('pdf.empty-state.start') + ' '"
              variant="button"
            />
            <OwnType
              color="brand"
              el="span"
              :text="t('pdf.empty-state.emphasis') + ' '"
              variant="button"
            />
            <OwnType
              color="secondary"
              el="span"
              :text="t('pdf.empty-state.end')"
              variant="button"
            />
          </p>
        </template>
        <template v-if="fileValue && status !== 'preview-failed'" #preview>
          <Responsive v-slot="{ height, width }" class="pdf-editor__preview">
            <OwnProgressSpinner v-if="status === 'loading'" />
            <!-- Hide with `v-show` instead of `v-if` so the component can render in the background -->
            <VuePdfEmbed
              v-show="status === 'preview-loaded'"
              :page="1"
              :source="pdf.value"
              :height="height < width ? height : undefined"
              :width="width < height ? width : undefined"
              @loaded="status = 'preview-loaded'"
              @loading-failed="status = 'preview-failed'"
            />
          </Responsive>
        </template>
      </OwnFile>
    </OwnInputContainer>
  </div>
</template>

<script>
import { PhFileText } from '@phosphor-icons/vue'
import { useI18n } from 'vue-i18n'
import VuePdfEmbed from 'vue-pdf-embed'
import { mapGetters } from 'vuex'

import { FormBuilder, Validators } from '@/forms'
import notify from '@/mixins/notify'
import { OlympusClient } from '@/OlympusClient'
import { OwnFile } from '@/ui/OwnFile'
import { OwnInput } from '@/ui/OwnInput'
import { OwnInputContainer } from '@/ui/OwnInputContainer'
import { OwnProgressSpinner } from '@/ui/OwnProgressSpinner'
import { OwnTextarea } from '@/ui/OwnTextarea'
import { OwnType } from '@/ui/OwnType'
import Responsive from '@/ui/Responsive.vue'
import { toDataUrl } from '@/utils/toDataUrl'

import { PdfCommands } from '../commands/PdfCommands'

const MAX_HEADING_LENGTH = 50
const MAX_SUBHEADING_LENGTH = 200

export default {
  name: 'PdfEditor',
  components: {
    OwnFile,
    OwnInput,
    OwnInputContainer,
    OwnProgressSpinner,
    OwnTextarea,
    OwnType,
    PhFileText,
    Responsive,
    VuePdfEmbed,
  },
  mixins: [
    notify,
    FormBuilder({
      heading: {
        debounce: 250,
        validateOnInit: true,
        validators: [
          Validators.required,
          Validators.maxLength(MAX_HEADING_LENGTH),
        ],
      },
      pdf: {
        debounce: 250,
        validators: [Validators.required],
      },
      subheading: {
        debounce: 250,
        validateOnInit: true,
        validators: [Validators.maxLength(MAX_SUBHEADING_LENGTH)],
      },
    }),
  ],
  props: {
    /**
     * Id getting passed to the editor component
     */
    id: { type: String, required: true },
  },
  setup() {
    const { t } = useI18n()

    return { t }
  },
  data() {
    return {
      MAX_HEADING_LENGTH,
      MAX_SUBHEADING_LENGTH,
      /** Base64-encoded PDF data URL, to be used only until the file is done uploading. */
      localFile: null,
      /**
       * Possible values:
       * - 'loading': preview is rendering or PDF file is uploading
       * - 'preview-loaded': PDF preview is done rendering
       * - 'preview-failed': PDF preview failed to render
       */
      status: 'loading',
    }
  },
  computed: {
    ...mapGetters({
      blockById: 'websiteBuilder/blockById',
    }),
    currentBlockParams() {
      const { id } = this

      return this.blockById(id)?.params
    },
    fileValue() {
      return this.localFile ?? this.pdf.value
    },
  },
  created() {
    const { currentBlockParams } = this

    this.setInitialFormValues({
      heading: currentBlockParams.heading,
      pdf: currentBlockParams.pdf,
      subheading: currentBlockParams.subheading,
    })
  },
  methods: {
    async applyPdfUpdate(newValue) {
      const { id } = this

      await PdfCommands.updateBlock({
        targetId: id,
        update: {
          params: {
            ...newValue,
          },
        },
      })
    },
    async onFileSelected(file) {
      // Temporarily set to a base64-encoded PDF so `OwnFile` knows it has a value and shows its preview slot
      this.localFile = await toDataUrl(file)
      this.status = 'loading'

      try {
        const { resultUrl, uploadUrl } =
          await OlympusClient.requestFileUploadKey({
            query: { group: 'document', suffix: '.pdf' },
          })
        await fetch(uploadUrl, {
          body: file,
          headers: {
            'Content-Type': 'application/octet-stream',
          },
          method: 'PUT',
        })

        await this.applyPdfUpdate({ pdf: resultUrl })
        this.pdf.value = resultUrl
      } catch {
        this.$notify(this.t('upload-error', 'error'))
      } finally {
        this.localFile = null
      }
    },
    async onFormControlUpdate(controlName, value, status) {
      if (!status.valid) {
        return
      }

      await this.applyPdfUpdate({ [controlName]: value })
    },
  },
}
</script>
<style lang="scss" scoped>
.pdf-editor {
  &__preview {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
