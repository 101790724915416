import { formBuilderModuleRoutes } from '../builder/formBuilderModuleRouter'

const formBuilderRoutes = [
  ...formBuilderModuleRoutes,
  {
    component: async () => import('../views/FormBuilderFormsView.vue'),
    meta: {
      title: 'website.forms',
    },
    name: 'brand.website.forms.home',
    path: 'forms',
  },
]

export { formBuilderRoutes }
