import { type DeliveryProvider, type DeliveryZoneType } from '../types'

export interface DeliveryEn {
  areaTypes: Record<string, string>
  providers: Record<DeliveryProvider | 'thirdparty', string>
  zoneTypes: Record<DeliveryZoneType, string>
  validators: Record<string, Record<string, string>>
}

export const deliveryEn: DeliveryEn = {
  areaTypes: {
    polygon: 'Custom',
    radius: 'Radius',
    zip: 'Zip Code',
  },
  providers: {
    doordash: 'Doordash',
    inhouse: 'In House',
    thirdparty: 'Third Party',
    ubereats: 'Uber Eats',
  },
  validators: {
    polygon: {
      formatting:
        'JSON not formatted properly.  Please ensure all characters are copied.',
      'no-points': 'No points associated with this value',
      'one-feature': 'Only one feature supported per zone',
      'only-polygon': 'Only type `Polygon` supported',
      'shape-not-closed':
        'Not a closed shape.  First coordinate and last coordinate must match values.',
    },
  },
  zoneTypes: {
    restricted: 'Not Allowed',
    serviceable: 'Allowed',
  },
}
