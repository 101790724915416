import { PhTextAlignLeft } from '@phosphor-icons/vue'

import { type BuilderBlockModule } from '@/lib/builder/types'
import i18nPlugin from '@/plugins/i18n'

import {
  type ContentSection,
  type ContentSectionCreate,
  type ContentSectionUpdate,
  type WebsiteResource,
} from '../../WebsiteBuilder.types'
import { websiteBlockMethods } from '../common/methods'

import ContentEditor from './editor/ContentEditor.vue'
import { createContentData } from './methods/createContentData'
import ContentPreview from './preview/ContentPreview.vue'
const i18n = i18nPlugin.global

export const ContentBlockModule: BuilderBlockModule<
  ContentSection,
  ContentSectionCreate,
  ContentSectionUpdate,
  WebsiteResource
> = {
  createData: createContentData,
  editor: ContentEditor,
  meta: [
    {
      icon: PhTextAlignLeft,
      key: 'content',
      label: i18n.t('website-builder.blocks.content'),
      sort: 0,
    },
  ],
  methods: websiteBlockMethods,
  preview: ContentPreview,
}
