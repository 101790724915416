import { BuilderExtension } from '@/lib/builder/extension/BuilderExtension'
import { type BuilderState } from '@/lib/builder/types'
import { Store } from '@/lib/store/StoreModule'

import { type CampaignResource, type EmailSection } from '../types'

export interface EmailBuilderState {
  templateId?: string | null
}

const emailBuilder = Store.extend<
  EmailBuilderState,
  BuilderState<CampaignResource, EmailSection>
>(
  {
    actions: {},
    getters: {},
    mutations: {},
    state: {},
  },
  BuilderExtension({
    moduleName: 'emailBuilder',
  })
)

const emailBuilderModule = Store.createModule(emailBuilder, true)

export default emailBuilderModule
