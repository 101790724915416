<i18n locale="en">
{
  "content": "Description",
  "youtube": "YouTube Link",
  "heading": "Heading"
}
</i18n>

<template>
  <div class="flex-col gap-8">
    <OwnInputContainer
      class="video-editor__input-container"
      :label="t('heading')"
      :errors="header.status.errors"
    >
      <OwnInput v-model="header.value" :placeholder="t('heading')" />
    </OwnInputContainer>
    <OwnInputContainer
      class="video-editor__input-container"
      :label="t('youtube')"
      :errors="videoId.status.errors"
    >
      <YoutubeInput v-model="videoId.value" />
    </OwnInputContainer>
    <OwnInputContainer
      class="video-editor__input-container"
      :label="t('content')"
      :errors="body.status.errors"
    >
      <OwnTextarea v-model="body.value" :placeholder="t('content')" />
    </OwnInputContainer>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'

import YoutubeInput from '@/components/inputs/YoutubeInput'
import { FormBuilder, Validators } from '@/forms'
import { OwnInput } from '@/ui/OwnInput'
import { OwnInputContainer } from '@/ui/OwnInputContainer'
import { OwnTextarea } from '@/ui/OwnTextarea'

import { VideoCommands } from '../commands/VideoCommands'

export default {
  name: 'VideoEditor',
  components: {
    OwnInput,
    OwnInputContainer,
    OwnTextarea,
    YoutubeInput,
  },
  mixins: [
    FormBuilder({
      body: {
        debounce: 250,
        validateOnInit: true,
        validators: [Validators.required],
      },
      header: {
        debounce: 250,
        validateOnInit: true,
        validators: [Validators.required],
      },
      videoId: {
        debounce: 250,
        validateOnInit: true,
        validators: [Validators.required],
      },
    }),
  ],
  props: {
    /**
     * Id getting passed to the editor component
     */
    id: { type: String, required: true },
  },
  setup() {
    const { t } = useI18n()

    return { t }
  },
  computed: {
    ...mapGetters({
      blockById: 'websiteBuilder/blockById',
    }),
    currentBlockParams() {
      const { id } = this

      return this.blockById(id)?.params
    },
  },
  created() {
    const { currentBlockParams } = this

    this.setInitialFormValues({
      body: currentBlockParams.body,
      header: currentBlockParams.header,
      videoId: currentBlockParams.video.params.id,
    })
  },
  methods: {
    async applyBodyUpdate(update) {
      const { id } = this

      await VideoCommands.updateBlock({
        targetId: id,
        update: { params: { body: update } },
      })
    },
    async applyHeaderUpdate(update) {
      const { id } = this

      await VideoCommands.updateBlock({
        targetId: id,
        update: { params: { header: update } },
      })
    },
    async applyVideoIdUpdate(update) {
      const { id } = this

      await VideoCommands.updateBlock({
        targetId: id,
        update: { params: { video: { params: { id: update } } } },
      })
    },
    onFormControlUpdate(controlName, update) {
      const { isFormValid } = this

      if (!isFormValid) {
        return
      }

      switch (controlName) {
        case 'header':
          this.applyHeaderUpdate(update)
          break
        case 'videoId':
          this.applyVideoIdUpdate(update)
          break
        case 'body':
          this.applyBodyUpdate(update)
          break
      }
    },
  },
}
</script>
