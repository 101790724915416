<template>
  <div ref="reorderable-list">
    <div
      v-for="(item, index) in itemsWithKeys"
      :key="item.reorderKey"
      :class="rowClass"
    >
      <slot :item="item.item" :index="index" />
    </div>
  </div>
</template>

<script>
import Sortable from 'sortablejs'

import { mapModel } from '@/utils/computed'

export default {
  name: 'Reorderable',
  props: {
    /**
     * Block key to be used for for-key
     * @default id
     */
    itemKey: { type: [String, Function], default: 'id' },

    /**
     * List of blocks
     * @model
     */
    modelValue: { type: Array, default: () => [] },

    /**
     * Class to add to row
     */
    rowClass: { type: String, default: undefined },
  },
  emits: ['reorder', 'update:modelValue'],
  computed: {
    ...mapModel('items'),
    itemsWithKeys() {
      const { items, itemKey } = this

      return items.map((item) => ({
        item,
        reorderKey:
          typeof itemKey === 'function' ? itemKey(item) : item[itemKey],
      }))
    },
  },
  mounted() {
    Sortable.create(this.$refs['reorderable-list'], {
      animation: 150,
      direction: 'vertical',
      filter: '.locked',
      handle: '.drag-handle',
      onEnd: ({ oldIndex, newIndex }) => {
        if (oldIndex === newIndex) return
        this.$nextTick(() => {
          this.$emit('reorder', { newIndex, oldIndex })
        })
      },
      onMove: (evt) => {
        // Prevents disabled element from being moved
        return !evt.related.children[0].classList.contains('locked')
      },
    })
  },
}
</script>
