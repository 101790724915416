/**
 * Linearly interpolate between two points
 * @param pointOne First Point [x1, y1]
 * @param pointTwo Second Point [x2, y2]
 * @param targetValue Value to get value from
 */
export const linterp = (
  pointOne: [number, number],
  pointTwo: [number, number],
  targetValue: number
): number => {
  const [x1, y1] = pointOne
  const [x2, y2] = pointTwo

  return y1 + (targetValue - x1) * ((y2 - y1) / (x2 - x1))
}
