import merge from 'lodash/merge'

import { type TemplateData } from '@/lib/builder/types'
import i18nPlugin from '@/plugins/i18n'

import { CouponBlockModule } from '../blocks/CouponSection'
import { TextBlockModule } from '../blocks/TextSection'
import { type EmailSectionCreate, type CampaignResourceCreate } from '../types'
const i18n = i18nPlugin.global

export const offerTemplate = async (
  brandId: string
): Promise<TemplateData<CampaignResourceCreate, EmailSectionCreate>> => ({
  blocks: [
    merge(
      TextBlockModule.createData({
        brandId,
        type: 'heading',
      }),
      {
        data: { text: i18n.t('email-builder.defaults.coupon.heading') },
      }
    ),
    merge(
      TextBlockModule.createData({
        brandId,
        type: 'subheading',
      }),
      {
        data: { text: i18n.t('email-builder.defaults.coupon.subheading') },
      }
    ),
    CouponBlockModule.createData({
      brandId,
      type: 'coupon',
    }),
  ],
  resource: {
    brandId,
    locationId: null,
    name: i18n.t('email-builder.defaults.coupon.subject'),
    sections: [],
    subject: i18n.t('email-builder.defaults.coupon.subject'),
  },
})
