<i18n locale="en">
{
  "title": "Submission Details",
  "defaults": {
    "name": "Name",
    "email": "Email",
    "phone": "Phone Number",
    "date": "Date/time",
    "text-field": "Text Field",
    "select": "Choose one",
    "multi-select": "Choose multiple"
  },
  "no-response": "No response given.",
  "date-received": "Date Received",
  "actions": {
    "mark-unread": "Mark Unread",
    "done": "Done"
  },
  "unread-error": "Could not mark unread."
}
</i18n>

<template>
  <OwnDialog v-model="isVisible" @cancel="onCancel">
    <template #heading>
      <OwnDialogHeading :title="t('title')" @cancel="onCancel" />
    </template>
    <template #default>
      <OwnDialogContent scroll>
        <div class="flex-col gap-6">
          <div
            v-for="field in fields"
            :key="field.sectionId"
            class="flex-col gap-1"
          >
            <OwnType :text="field.title" variant="subtitle" color="primary" />
            <OwnType
              :class="[!field.response && 'text--italic']"
              :text="field.response ?? t('no-response')"
              variant="paragraph"
              color="secondary"
            />
          </div>
        </div>
      </OwnDialogContent>
    </template>
    <template #footer="{ close }">
      <OwnDialogFooter>
        <OwnButton
          :text="t('actions.mark-unread')"
          :processing="loadingUnread"
          @click="onMarkUnread(close)"
        />
        <OwnButton :text="t('actions.done')" primary @click="close" />
      </OwnDialogFooter>
    </template>
  </OwnDialog>
</template>

<script>
import { format } from 'date-fns'
import { useI18n } from 'vue-i18n'

import { logError } from '@/logger'
import notify from '@/mixins/notify'
import { OlympusClient } from '@/OlympusClient'
import { OwnButton } from '@/ui/OwnButton'
import {
  OwnDialog,
  OwnDialogContent,
  OwnDialogFooter,
  OwnDialogHeading,
} from '@/ui/OwnDialog'
import { OwnType } from '@/ui/OwnType'
import { mapModel } from '@/utils/computed'

const mapResponse = ({ type, value }) => {
  if (type === 'address' && value) {
    const { full, street, unit, city, state, zip } = value
    const formattedUnit = unit ? ` #${unit}` : ''
    const builtAddress = `${street}${formattedUnit}, ${city}, ${state} ${zip}`
    return full ?? builtAddress
  }

  if (type === 'date' && value) {
    return format(new Date(value), 'MMM dd, yyyy @ h:mma')
  }

  return value
}

export default {
  name: 'SubmissionDetailModal',
  components: {
    OwnButton,
    OwnDialog,
    OwnDialogContent,
    OwnDialogFooter,
    OwnDialogHeading,
    OwnType,
  },
  mixins: [notify],
  props: {
    /**
     * Show Modal
     */
    modelValue: { type: Boolean, default: false },

    /**
     * Sections on the form
     */
    sections: { type: Array, required: true },

    /**
     * Submission in view
     */
    submission: { type: Object, default: null },
  },
  emits: ['cancel', 'unread', 'update:modelValue'],
  setup() {
    const { t } = useI18n()

    return { t }
  },
  data() {
    return {
      loadingUnread: false,
    }
  },
  computed: {
    ...mapModel('isVisible'),
    fields() {
      const { sections, submission } = this

      const responses = sections
        .map((section) => {
          if (section.type === 'headline' || section.type === 'subheading')
            return null

          const submissionValue = submission?.data?.[section.id]

          const response = submissionValue ? mapResponse(submissionValue) : null

          return {
            response: response || null,
            sectionId: section.id,
            title: section.params.label || this.t(`defaults.${section.type}`),
          }
        })
        .filter((response) => !!response)

      return [
        ...responses,
        {
          response: format(
            new Date(submission?.createdAt),
            'MMM dd, yyyy @ h:mma'
          ),
          sectionId: 'created-at',
          title: this.t('date-received'),
        },
      ]
    },
  },
  watch: {
    async submission(newSubmission) {
      if (newSubmission) {
        await OlympusClient.forms.v1.submissions.submission.setReadStatus({
          body: { read: true },
          params: { submissionId: newSubmission.id },
        })
      }
    },
  },
  methods: {
    onCancel() {
      this.$emit('cancel')
      this.isVisible = false
    },
    async onMarkUnread(onClose) {
      const { submission } = this

      this.loadingUnread = true

      try {
        await OlympusClient.forms.v1.submissions.submission.setReadStatus({
          body: { read: false },
          params: { submissionId: submission.id },
        })
        this.$emit('unread')
        onClose()
      } catch (error) {
        logError(error)
        this.$notify(this.t('unread-error'), 'error')
      } finally {
        this.loadingUnread = false
      }
    },
  },
}
</script>
