<i18n locale="en">
{
  "title": {
    "label": "Name",
    "placeholder": "Aa"
  },
  "toggle": {
    "label": "Display until turned off"
  },
  "validAt": {
    "label": "From"
  },
  "expiresAt": {
    "label": "To"
  },
  "link": {
    "label": "Link",
    "description": "Select where you want the announcement to link to when clicked.",
    "placeholder": "https://example.com"
  },
  "success": "Saved announcement",
  "errors": {
    "save": "Unable to save announcement"
  }
}
</i18n>

<template>
  <OwnScrollContainer>
    <div class="announcement-editor flex-col gap-8">
      <OwnInputContainer
        :label="t('title.label')"
        :max-chars="MAX_CHAR_LENGTH"
        :value="title.value"
        :errors="title.status.errors"
      >
        <OwnInput v-model="title.value" :placeholder="t('title.placeholder')" />
      </OwnInputContainer>

      <OwnInputContainer
        :label="t('link.label')"
        :description="t('link.description')"
        :errors="link.status.errors"
      >
        <OwnInput v-model="link.value" :placeholder="t('link.placeholder')" />
      </OwnInputContainer>

      <OwnOption el="div">
        <div class="flex-col gap-4">
          <OwnToggle v-model="displayAlways" :label="t('toggle.label')" />

          <template v-if="!displayAlways">
            <OwnRule type="dashed" />

            <OwnInputContainer
              :label="t('validAt.label')"
              :value="validAt.value"
              :errors="validAt.status.errors"
            >
              <DatePicker v-model="validAt.value" />
            </OwnInputContainer>

            <OwnInputContainer
              :label="t('expiresAt.label')"
              :value="expiresAt.value"
              :errors="expiresAt.status.errors"
            >
              <DatePicker v-model="expiresAt.value" />
            </OwnInputContainer>
          </template>
        </div>
      </OwnOption>
    </div>
  </OwnScrollContainer>
</template>

<script>
import { format, parseISO } from 'date-fns'
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'

import { DatePicker } from '@/components/inputs/date-time'
import { FormBuilder, Validators } from '@/forms'
import builderConsumer from '@/lib/builder/mixins/builderConsumer'
import builderProducer from '@/lib/builder/mixins/builderProducer'
import { logError } from '@/logger'
import notify from '@/mixins/notify'
import { OlympusClient } from '@/OlympusClient'
import { OwnInput } from '@/ui/OwnInput'
import { OwnInputContainer } from '@/ui/OwnInputContainer'
import { OwnOption } from '@/ui/OwnOption'
import { OwnRule } from '@/ui/OwnRule'
import { OwnScrollContainer } from '@/ui/OwnScrollContainer'
import { OwnToggle } from '@/ui/OwnToggle'

import { defaultAnnouncement } from './defaultAnnouncement'

const MAX_CHAR_LENGTH = 100
const VALUE_FORMAT = 'yyyy-MM-dd'

export default {
  name: 'AnnouncementEditor',
  components: {
    DatePicker,
    OwnInput,
    OwnInputContainer,
    OwnOption,
    OwnRule,
    OwnScrollContainer,
    OwnToggle,
  },
  mixins: [
    builderProducer,
    builderConsumer('announcement', 'onMessage'),
    notify,
    FormBuilder({
      expiresAt: {
        validateOnInit: true,
        validators: [],
      },
      link: {
        validateOnInit: true,
        validators: [Validators.required],
      },
      title: {
        validateOnInit: true,
        validators: [
          Validators.required,
          Validators.maxLength(MAX_CHAR_LENGTH),
        ],
      },
      validAt: {
        validateOnInit: true,
        validators: [],
      },
    }),
  ],
  props: {
    resourceId: { type: String, required: true },
  },
  emits: ['close'],
  setup() {
    const { t } = useI18n()

    return { t }
  },
  data() {
    return {
      MAX_CHAR_LENGTH,
      displayAlways: true,
    }
  },
  computed: {
    ...mapGetters({
      activeBrandId: 'core/brand/active/id',
      activeLocationId: 'core/location/active/id',
    }),
    isNewAnnouncement() {
      const { resourceId } = this

      return resourceId === 'new'
    },
  },
  watch: {
    displayAlways(displayAlways) {
      if (displayAlways) {
        this.expiresAt.value = null
        this.validAt.value = null
      }
    },
  },
  created() {
    this.fetchAnnouncement()
  },
  methods: {
    broadcastAnnouncementUpdate() {
      const { formValues } = this

      this.sendBuilderEvent('announcement', {
        payload: formValues,
        topic: 'update',
      })
    },
    async fetchAnnouncement() {
      const { activeBrandId, resourceId, isNewAnnouncement } = this

      if (!isNewAnnouncement) {
        const result = await OlympusClient.funnel.v1.banners.banner.getBanner({
          params: {
            bannerId: resourceId,
          },
        })

        this.populateFormState(result)
      } else {
        this.populateFormState(defaultAnnouncement(activeBrandId))
      }
    },
    onFormValuesUpdate() {
      this.broadcastAnnouncementUpdate()
    },
    onMessage(message) {
      if (message.topic === 'publish') this.onPublish()
      if (message.topic === 'request-update') this.broadcastAnnouncementUpdate()
    },
    async onPublish() {
      const {
        activeBrandId,
        formValues,
        isFormValid,
        resourceId,
        isNewAnnouncement,
      } = this

      try {
        if (isFormValid) {
          const { link, title, ...rest } = formValues

          const announcementToSave = {
            ...rest,
            link: {
              params: {
                label: title,
                url: link,
              },
              type: 'external',
            },
            title,
          }

          if (isNewAnnouncement) {
            await OlympusClient.funnel.v1.banners.createBanner({
              body: {
                ...announcementToSave,
                brandId: activeBrandId,
                enabled: true,
              },
            })
          } else {
            await OlympusClient.funnel.v1.banners.banner.updateBanner({
              body: announcementToSave,
              params: {
                bannerId: resourceId,
              },
            })
          }

          this.$notify(this.t('success'), 'success')
          this.$emit('close', 'success')
        }
      } catch (error) {
        logError(error)
        this.$notify(this.t('errors.save'), 'error')
      }
    },
    populateFormState(announcement) {
      this.title.value = announcement.title
      this.link.value = announcement?.link?.params?.url

      if (announcement.validAt) {
        this.validAt.value = format(
          parseISO(announcement.validAt),
          VALUE_FORMAT
        )
        this.displayAlways = false
      }

      if (announcement.expiresAt) {
        this.expiresAt.value = format(
          parseISO(announcement.expiresAt),
          VALUE_FORMAT
        )
        this.displayAlways = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@use '@/styles/breakpoints';

.announcement-editor {
  padding: 40px 16px;

  @include breakpoints.respond-to('md-and-up') {
    padding: 40px;
  }
}
</style>
