<i18n locale="en">
{
  "add-existing": "Add Job",
  "add-new": "Add New Job",
  "empty": "You have already added all of the existing jobs. Try creating a new job.",
  "title": "Add Existing Job"
}
</i18n>

<template>
  <OwnForm :submit-handler="onUpdate">
    <OwnDialog :model-value="modelValue" @cancel="close">
      <template #heading>
        <OwnDialogHeading :title="t('title')" @cancel="close" />
      </template>

      <OwnDialogContent scroll>
        <OwnTouchRadioGroup
          v-if="items.length > 0"
          v-model="selectedOption"
          :options="options"
          :columns="1"
        />
        <OwnType
          v-else
          :text="t('empty')"
          variant="paragraph"
          color="secondary"
        />
      </OwnDialogContent>

      <template #footer>
        <OwnDialogFooter>
          <OwnButton
            :text="items.length > 0 ? t('add-existing') : t('add-new')"
            primary
            type="submit"
            :disabled="items.length > 0 && selectedOption === null"
          >
            <template #icon-right>
              <PhPlusCircle size="24" />
            </template>
          </OwnButton>
        </OwnDialogFooter>
      </template>
    </OwnDialog>
  </OwnForm>
</template>

<script>
import { PhPlusCircle } from '@phosphor-icons/vue'
import { useI18n } from 'vue-i18n'

import { OwnButton } from '@/ui/OwnButton'
import {
  OwnDialog,
  OwnDialogContent,
  OwnDialogFooter,
  OwnDialogHeading,
} from '@/ui/OwnDialog'
import { OwnForm } from '@/ui/OwnForm'
import { OwnTouchRadioGroup } from '@/ui/OwnTouchRadioGroup'
import { OwnType } from '@/ui/OwnType'

export default {
  name: 'AddExistingJobDialog',
  components: {
    OwnButton,
    OwnDialog,
    OwnDialogContent,
    OwnDialogFooter,
    OwnDialogHeading,
    OwnForm,
    OwnTouchRadioGroup,
    OwnType,
    PhPlusCircle,
  },
  props: {
    items: { type: Array, required: true },

    /**
     * Show dialog
     */
    modelValue: { type: Boolean, default: false },
  },
  emits: ['close', 'update'],
  setup() {
    const { t } = useI18n()

    return { t }
  },
  data() {
    return {
      selectedOption: null,
    }
  },
  computed: {
    options() {
      const { items } = this

      return items.map(({ title, id }) => ({ label: title, value: id }))
    },
  },
  methods: {
    close() {
      this.$emit('close')
      this.selectedOption = null
    },
    onUpdate() {
      const { selectedOption } = this

      this.$emit('update', selectedOption)
      this.close()
    },
  },
}
</script>
