import mergeWith from 'lodash/mergeWith'

import { useBuilder } from '@/lib/builder/extension/useBuilder'
import { reorder } from '@/utils/helpers'

import { type FeaturesSection } from '../../../WebsiteBuilder.types'

const websiteBuilderQueue = useBuilder('websiteBuilder')

const updateBlock = websiteBuilderQueue.createBlockCommand<FeaturesSection>({
  commit: async (currentData, ctx) => {
    return mergeWith(currentData, ctx.update, (_objValue, srcValue, key) => {
      // Do not merge `features` because it is an array
      if (key === 'features') {
        return srcValue
      }
    })
  },
})

type Feature = FeaturesSection['params']['features'][number]

const BLANK: Feature = {
  description: null,
  icon: 'heart',
  title: '',
}

const FeaturesCommands = {
  /**
   * Create a new, empty feature in a features section.
   * @param current Current block value
   * @returns Index of added feature
   */
  async createFeature(current: FeaturesSection): Promise<number> {
    const features = current.params.features.concat([BLANK])

    await updateBlock({
      targetId: current.id,
      update: { params: { ...current.params, features } },
    })

    return features.length - 1
  },

  /**
   * Remove a feature from a features section.
   * @param current Current block value
   * @param featureIndex Index of feature to update
   */
  async deleteFeature(
    current: FeaturesSection,
    featureIndex: number
  ): Promise<void> {
    const features = current.params.features
    features.splice(featureIndex, 1)

    await updateBlock({
      targetId: current.id,
      update: { params: { ...current.params, features } },
    })
  },

  /**
   * Change the order of a feature in a features section.
   * @param current Current block value
   * @param oldIndex Current index of feature
   * @param newIndex New index of feature
   */
  async reorderFeature(
    current: FeaturesSection,
    oldIndex: number,
    newIndex: number
  ): Promise<void> {
    const features = reorder(current.params.features, oldIndex, newIndex)

    await updateBlock({
      targetId: current.id,
      update: { params: { ...current.params, features } },
    })
  },

  updateBlock,

  /**
   * Update a feature in a features section.
   * @param current Current block value
   * @param featureIndex Index of feature to update
   * @param updatedFeature A feature
   */
  async updateFeature(
    current: FeaturesSection,
    featureIndex: number,
    updatedFeature: Feature
  ): Promise<void> {
    const features = current.params.features
    features.splice(featureIndex, 1, updatedFeature)

    await updateBlock({
      targetId: current.id,
      update: { params: { ...current.params, features } },
    })
  },
}

export { FeaturesCommands }
