<i18n locale="en">
{
  "add": "Add Feature",
  "delete": "Delete feature",
  "labels": {
    "features": "Features",
    "title": "Title"
  }
}
</i18n>

<template>
  <EditPanel
    :is-editing="
      selectedIndex !== null &&
      currentBlockParams.features[selectedIndex] !== undefined
    "
  >
    <template #preview>
      <EditorLayout>
        <div class="features-editor flex-col gap-8">
          <OwnInputContainer
            :label="t('labels.title')"
            :errors="title.status.errors"
            :max-chars="MAX_TITLE_LENGTH"
            :value="title.value"
          >
            <OwnInput v-model="title.value" />
          </OwnInputContainer>
          <div class="flex-col gap-3">
            <OwnInputContainer :label="t('labels.features')">
              <Reorderable
                v-slot="{ item, index }"
                v-model="currentBlockParams.features"
                item-key="title"
                class="space-y-3"
                @reorder="onReorder"
              >
                <OwnOption @click="onEdit(index)">
                  <div class="flex-row gap-4">
                    <DragHandle />
                    <OwnType
                      class="flex-1"
                      color="primary"
                      variant="subtitle"
                      :text="item.title"
                    />
                    <OwnButton
                      tertiary
                      :aria-label="t('delete')"
                      class="features-editor__delete-button"
                      @click="onDelete(index)"
                    >
                      <template #icon-left>
                        <PhTrash size="24" />
                      </template>
                    </OwnButton>
                  </div>
                </OwnOption>
              </Reorderable>
            </OwnInputContainer>
            <OwnButton :text="t('add')" @click="onNew" />
          </div>
        </div>

        <template #actions>
          <slot />
        </template>
      </EditorLayout>
    </template>
    <template #edit>
      <FeatureDetailEditor
        class="features-editor__child"
        :item="currentBlockParams.features[selectedIndex]"
        @back="onBack"
        @update="onUpdate"
      />
    </template>
  </EditPanel>
</template>

<script>
import { PhTrash } from '@phosphor-icons/vue'
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'

import { FormBuilder, Validators } from '@/forms'
import EditorLayout from '@/lib/builder/public/editor/EditorLayout.vue'
import EditPanel from '@/lib/builder/public/editor/EditPanel.vue'
import { OwnButton } from '@/ui/OwnButton'
import { OwnInput } from '@/ui/OwnInput'
import { OwnInputContainer } from '@/ui/OwnInputContainer'
import { OwnOption } from '@/ui/OwnOption'
import { OwnType } from '@/ui/OwnType'
import DragHandle from '@/ui/reorderable/DragHandle.vue'
import Reorderable from '@/ui/reorderable/Reorderable.vue'

import { FeaturesCommands } from '../commands/FeaturesCommands'

import FeatureDetailEditor from './FeatureDetailEditor.vue'

const MAX_TITLE_LENGTH = 50

export default {
  name: 'FeaturesEditor',
  components: {
    DragHandle,
    EditPanel,
    EditorLayout,
    FeatureDetailEditor,
    OwnButton,
    OwnInput,
    OwnInputContainer,
    OwnOption,
    OwnType,
    PhTrash,
    Reorderable,
  },
  mixins: [
    FormBuilder({
      title: {
        debounce: 250,
        validateOnInit: true,
        validators: [
          Validators.required,
          Validators.maxLength(MAX_TITLE_LENGTH),
        ],
      },
    }),
  ],
  props: {
    /**
     * Id getting passed to the editor component
     */
    id: { type: String, required: true },
  },
  setup() {
    const { t } = useI18n()

    return { t }
  },
  data() {
    return {
      MAX_TITLE_LENGTH,
      selectedIndex: null,
    }
  },
  computed: {
    ...mapGetters({
      blockById: 'websiteBuilder/blockById',
    }),
    currentBlock() {
      const { id } = this

      return this.blockById(id)
    },
    currentBlockParams() {
      const { currentBlock } = this

      return currentBlock?.params
    },
    featureTitles() {
      const { currentBlockParams } = this

      return currentBlockParams.features.map(({ title }) => title)
    },
  },
  created() {
    const { currentBlockParams } = this

    this.setInitialFormValues({
      title: currentBlockParams.title,
    })
  },
  methods: {
    async applyFeaturesUpdate(newValue) {
      const { id } = this

      await FeaturesCommands.updateBlock({
        targetId: id,
        update: {
          params: {
            ...newValue,
          },
        },
      })
    },
    onBack() {
      this.selectedIndex = null
    },
    async onDelete(index) {
      await FeaturesCommands.deleteFeature(this.currentBlock, index)
    },
    onEdit(index) {
      this.selectedIndex = index
    },
    async onFormControlUpdate(controlName, value, status) {
      if (!status.valid) {
        return
      }

      await this.applyFeaturesUpdate({
        [controlName]: value,
      })
    },
    async onNew() {
      this.selectedIndex = await FeaturesCommands.createFeature(
        this.currentBlock
      )
    },
    async onReorder({ newIndex, oldIndex }) {
      await FeaturesCommands.reorderFeature(
        this.currentBlock,
        oldIndex,
        newIndex
      )
    },
    async onUpdate(updatedFeature) {
      await FeaturesCommands.updateFeature(
        this.currentBlock,
        this.selectedIndex,
        updatedFeature
      )
    },
  },
}
</script>

<style lang="scss" scoped>
@use '@/styles/globals';

.features-editor {
  &__delete-button {
    padding: 0;
  }
  &__child {
    width: calc(100% - 8px);

    @include globals.flyout-shadow();
  }
}
</style>
