import { differenceInMilliseconds } from 'date-fns/differenceInMilliseconds'
import { isPast } from 'date-fns/isPast'
import { parseISO } from 'date-fns/parseISO'
import { storeToRefs } from 'pinia'
import { onBeforeMount, onBeforeUnmount, watch } from 'vue'

import { useAuthActions } from '@/auth/useAuthActions'
import { useAuthStore } from '@/auth/useAuthStore'
import { useEmitter as messageListener } from '@/utils/useEmitter'

// 2147483647 is the max setTimeout value (32 bit integer value)
const TIMEOUT_MAX = 2147483647

export const useSessionManager = (): void => {
  let timeout: number | null = null

  const { logout } = useAuthActions()

  const { expiresAt, isAuthenticated } = storeToRefs(useAuthStore())

  const onTimoutTriggered = async (): Promise<void> => {
    if (expiresAt.value && isPast(expiresAt.value)) {
      await logout()
    } else {
      registerTimeout()
    }
  }

  const registerTimeout = (): void => {
    if (timeout) window.clearTimeout(timeout)

    if (expiresAt.value) {
      const timeoutDuration = differenceInMilliseconds(
        parseISO(expiresAt.value),
        new Date()
      )

      timeout = window.setTimeout(
        onTimoutTriggered,

        Math.min(timeoutDuration, TIMEOUT_MAX)
      )
    }
  }

  watch(isAuthenticated, async (isAuthed) => {
    if (isAuthed) {
      registerTimeout()
    } else {
      await logout()
    }
  })

  onBeforeMount(async () => {
    if (isAuthenticated.value) {
      registerTimeout()
    }

    messageListener.on('auth.logout', logout)
  })

  onBeforeUnmount(() => {
    if (timeout) window.clearTimeout(timeout)
    messageListener.off('auth.logout', logout)
  })
}
