import { OlympusClient } from '@/OlympusClient'
import i18nPlugin from '@/plugins/i18n'

import type { Form, FormCreate } from '../../types/FormBuilder.types'
const i18n = i18nPlugin.global

export const createResourceHandler = async (
  body: FormCreate
): Promise<Form> => {
  return await OlympusClient.forms.v1.forms.createForm({
    body: {
      ...body,
      description:
        body.description ?? i18n.t('form-builder.defaults.description'),
      title: body.title ?? i18n.t('form-builder.defaults.title'),
    },
  })
}
