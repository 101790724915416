<template>
  <div class="flex-row gap-2">
    <div class="nav-button">
      <PreviewSheet class="nav-button__nav-button-sheet" background="secondary">
        <PhCaretLeft :style="caretStyle" />
      </PreviewSheet>
    </div>
    <div class="nav-button">
      <PreviewSheet class="nav-button__nav-button-sheet" background="secondary">
        <PhCaretRight :style="caretStyle" />
      </PreviewSheet>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { PhCaretRight, PhCaretLeft } from '@phosphor-icons/vue'
import { computed } from 'vue'

import { useMergedTheme } from '@/lib/builder/public/preview/mixins/useMergedThemeComp'
import PreviewSheet from '@/lib/builder/public/preview/PreviewSheet.vue'

const theme = useMergedTheme()

const caretStyle = computed(() => {
  return {
    color: theme.value?.colors.text.primary,
  }
})
</script>
<style lang="scss" scoped>
.nav-button {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  overflow: hidden;

  &__nav-button-sheet {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}
</style>
