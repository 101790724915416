import {
  type BlockCore,
  type BuilderGetters,
  type BuilderState,
  type ResourceCore,
} from '../../types'

export const blockById =
  <TResource extends ResourceCore, TBlock extends BlockCore>(
    _state: BuilderState<TResource, TBlock>,
    getters: BuilderGetters<TResource, TBlock>
  ) =>
  (blockId: string): TBlock | undefined => {
    if (getters.currentBlocks) {
      // The block type will come from olympus client soon
      return getters.currentBlocks.find(
        (block: { id: string }) => block.id === blockId
      )
    }

    return undefined
  }
