<i18n locale="en">
{
  "heading": {
    "label": "Title",
    "placeholder": "Aa"
  },
  "subheading": {
    "label": "Subtitle",
    "placeholder": "Aa"
  }
}
</i18n>

<template>
  <div class="flex-col gap-8">
    <OwnInputContainer
      :label="t('heading.label')"
      :errors="heading.status.errors"
      :value="heading.value"
      :max-chars="MAX_HEADING_LENGTH"
    >
      <OwnInput
        v-model="heading.value"
        :placeholder="t('heading.placeholder')"
      />
    </OwnInputContainer>
    <OwnInputContainer
      :label="t('subheading.label')"
      :errors="subheading.status.errors"
      :value="subheading.value"
      :max-chars="MAX_SUBHEADING_LENGTH"
    >
      <OwnInput
        v-model="subheading.value"
        :placeholder="t('subheading.placeholder')"
      />
    </OwnInputContainer>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'

import { FormBuilder, Validators } from '@/forms'
import { OwnInput } from '@/ui/OwnInput'
import { OwnInputContainer } from '@/ui/OwnInputContainer'

import { HeroCommands } from '../commands/HeroCommands'

const MAX_HEADING_LENGTH = 50
const MAX_SUBHEADING_LENGTH = 200

export default {
  name: 'HeroEditor',
  components: {
    OwnInput,
    OwnInputContainer,
  },
  mixins: [
    FormBuilder({
      heading: {
        debounce: 250,
        validateOnInit: true,
        validators: [
          Validators.required,
          Validators.maxLength(MAX_HEADING_LENGTH),
        ],
      },
      subheading: {
        debounce: 250,
        validateOnInit: true,
        validators: [Validators.maxLength(MAX_SUBHEADING_LENGTH)],
      },
    }),
  ],
  props: {
    /**
     * Id getting passed to the editor component
     */
    id: { type: String, required: true },
  },
  setup() {
    const { t } = useI18n()

    return { t }
  },
  data() {
    return {
      MAX_HEADING_LENGTH,
      MAX_SUBHEADING_LENGTH,
    }
  },
  computed: {
    ...mapGetters({
      blockById: 'websiteBuilder/blockById',
    }),
    currentBlockParams() {
      const { id } = this

      return this.blockById(id)?.params
    },
  },
  created() {
    const { currentBlockParams } = this

    this.setInitialFormValues({
      heading: currentBlockParams.heading,
      subheading: currentBlockParams.subheading,
    })
  },
  methods: {
    async applyHeroUpdate(newValue) {
      const { id } = this

      await HeroCommands.updateBlock({
        targetId: id,
        update: {
          params: {
            ...newValue,
          },
        },
      })
    },
    async onFormControlUpdate(controlName, value, status) {
      if (!status.valid) {
        return
      }

      await this.applyHeroUpdate({ [controlName]: value })
    },
  },
}
</script>
