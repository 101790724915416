<template>
  <OwnInputContainer
    :label="t('labels.url')"
    :errors="url.status.errors"
    sr-only
  >
    <OwnInput v-model="url.value" />
  </OwnInputContainer>
</template>

<script>
import { useI18n } from 'vue-i18n'

import { FormBuilderModelSync, Validators } from '@/forms'
import { OwnInput } from '@/ui/OwnInput'
import { OwnInputContainer } from '@/ui/OwnInputContainer'

export default {
  name: 'UrlForm',
  components: { OwnInput, OwnInputContainer },
  mixins: [
    FormBuilderModelSync({
      url: {
        debounce: 250,
        validateOnInit: true,
        validators: [Validators.required, Validators.url],
      },
    }),
  ],
  props: {
    actionConfig: { type: Object, default: null },
  },
  emits: ['update'],
  setup() {
    const { t } = useI18n()

    return { t }
  },
  beforeMount() {
    const { actionConfig } = this

    if (actionConfig?.params?.url) {
      this.setInitialFormValues({
        url: actionConfig.params.url,
      })
    }
  },
  methods: {
    onFormControlUpdate(controlName, value, status) {
      this.$emit('update', {
        controlName,
        status,
        value,
      })
    },
  },
}
</script>
