<template>
  <div class="flex-row align-center gap-2">
    <PreviewTypographyLegacy :text="label" variant="buttonSmall" />
    <PhCaretDown class="text-color-primary" />
  </div>
</template>

<script>
import { PhCaretDown } from '@phosphor-icons/vue'

import PreviewTypographyLegacy from '@/lib/builder/public/preview/legacy/PreviewTypographyLegacy.vue'
import { useMergedTheme } from '@/lib/builder/public/preview/mixins/useMergedTheme'

export default {
  name: 'NavbarDropdownPreview',
  components: {
    PhCaretDown,
    PreviewTypographyLegacy,
  },
  mixins: [useMergedTheme],
  props: {
    /**
     * Label to show
     */
    label: { type: String, required: true },
  },
  computed: {
    primaryTextColor() {
      const { mergedTheme } = this

      return mergedTheme.colors.text.primary
    },
  },
}
</script>
