import { type ActionContext } from 'vuex'

import { type CoreState } from '@/core/store/types'
import { OlympusClient } from '@/OlympusClient'

export const loadBrand = async (
  { commit, dispatch }: ActionContext<CoreState, unknown>,
  brandId: string
): Promise<void> => {
  const { brand, locations, theme } =
    await OlympusClient.dashboard.v2.brands.brand.core({
      params: {
        brandId,
      },
    })

  await dispatch('locations/setAll', locations)

  commit('SET_BRANDS', [brand])
  commit('SET_THEME', theme)
}
