import { type QueueStoreModules } from '@/lib/store/types'
import globalStore from '@/store'

import {
  type BlockCommand,
  type BlockCommandContext,
  type BuilderCommandFactories,
  type CommandFactoryOptions,
  type ResourceCommand,
  type ResourceCommandContext,
} from '../types'

const generateRandomId = (): string => {
  return Date.now().toString()
}

export const useBuilder = (
  queueStoreModule: QueueStoreModules
): BuilderCommandFactories => {
  const createBlockCommand =
    <T>(factoryOptions: CommandFactoryOptions<T>) =>
    async (context: Omit<BlockCommandContext<T>, 'scope'>): Promise<void> => {
      const newCommand: BlockCommand<T> = {
        commit: factoryOptions.commit,
        committed: false,
        context: {
          ...context,
          scope: 'block',
        },
        id: generateRandomId(),
      }

      await globalStore.dispatch(
        `${queueStoreModule}/_builder_enqueue`,
        newCommand
      )
      await globalStore.dispatch(
        `${queueStoreModule}/_builder_stepTo`,
        newCommand.id
      )
    }

  const createResourceCommand =
    <R>(factoryOptions: CommandFactoryOptions<R>) =>
    async (
      context: Omit<ResourceCommandContext<R>, 'scope'>
    ): Promise<void> => {
      const newCommand: ResourceCommand<R> = {
        commit: factoryOptions.commit,
        committed: false,
        context: {
          ...context,
          scope: 'resource',
        },
        id: generateRandomId(),
      }

      await globalStore.dispatch(
        `${queueStoreModule}/_builder_enqueue`,
        newCommand
      )
      await globalStore.dispatch(
        `${queueStoreModule}/_builder_stepTo`,
        newCommand.id
      )
    }

  return {
    createBlockCommand,
    createResourceCommand,
  }
}
