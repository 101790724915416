import { type CreateBlockData } from '@/lib/builder/types'

import type {
  FormattedInputSectionCreate,
  FormattedInputSection,
} from '../../../../types/FormBuilder.types'

export const createFormattedInputData = ({
  type,
  brandId,
}: CreateBlockData<FormattedInputSection>): FormattedInputSectionCreate => ({
  brandId,
  params: {
    label: '',
    required: false,
  },
  type,
})
