import { type CreateBlockData } from '@/lib/builder/types'

import {
  type FeaturesSection,
  type FeaturesSectionCreate,
} from '../../../WebsiteBuilder.types'

export const createFeaturesData = ({
  brandId,
}: CreateBlockData<FeaturesSection>): FeaturesSectionCreate => ({
  brandId,
  params: {
    features: [
      { description: null, icon: 'tote', title: 'Catering' },
      { description: null, icon: 'grains-slash', title: 'Gluten-Free Options' },
      { description: null, icon: 'heart', title: 'Healthy Options' },
      { description: null, icon: 'car', title: 'Easy Parking' },
      { description: null, icon: 'leaf', title: 'Vegan Options' },
    ],
    layout: 'generic',
    title: 'Featuring',
  },
  type: 'features',
})
