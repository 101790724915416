import { type ActionContext } from 'vuex'

import { type CoreModule } from '@/lib/store/types'

import {
  type BlockCore,
  type BuilderFetchResult,
  type BuilderState,
  type ResourceCore,
} from '../../types'

export const resetResource = async <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  { commit }: ActionContext<BuilderState<TResource, TBlock>, CoreModule>,
  payload: BuilderFetchResult<TResource, TBlock>
): Promise<void> => {
  commit('_builder_SET_RESOURCE', {
    data: payload,
    target: ['current', 'original'],
  })
}
