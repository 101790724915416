<i18n locale="en">
{
  "actions": {
    "add-action": "Add Action"
  },
  "options": {
    "locations": "Locations",
    "profile": "Profile",
    "loyalty": "Loyalty",
    "orders": "Orders"
  }
}
</i18n>

<template>
  <div>
    <OwnOption
      v-if="actionConfig?.params?.action"
      @click="showActionDialog = true"
    >
      <span class="flex-row justify-between align-center">
        <OwnType
          :text="t(`options.${actionConfig.params.action}`)"
          variant="subtitle"
          color="primary"
        />
        <PhPencilSimple class="misc-brand" size="24" />
      </span>
    </OwnOption>

    <OwnButton
      v-else
      class="w-full"
      :text="t('actions.add-action')"
      @click="showActionDialog = true"
    />

    <AddActionDialog
      v-if="showActionDialog"
      :edit-item="actionConfig"
      @update="$emit('update', $event)"
      @close="showActionDialog = false"
    />
  </div>
</template>

<script>
import { PhPencilSimple } from '@phosphor-icons/vue'
import { useI18n } from 'vue-i18n'

import { OwnButton } from '@/ui/OwnButton'
import { OwnOption } from '@/ui/OwnOption'
import { OwnType } from '@/ui/OwnType'

import AddActionDialog from '../../../../common/resource-dialogs/add-action-dialog/AddActionDialog.vue'

export default {
  name: 'ActionForm',
  components: {
    AddActionDialog,
    OwnButton,
    OwnOption,
    OwnType,
    PhPencilSimple,
  },
  props: {
    actionConfig: { type: Object, default: null },
  },
  emits: ['update'],
  setup() {
    const { t } = useI18n()

    return { t }
  },
  data() {
    return {
      showActionDialog: false,
    }
  },
}
</script>
