import { type BuilderBlockModule } from '@/lib/builder/types'

import {
  type Form,
  type FormSection,
  type FormSectionCreate,
  type FormSectionUpdate,
} from '../../types/FormBuilder.types'

import { FormattedInputBlockModule } from './formatted-input'
import { HeadlineBlockModule } from './headline'
import { SelectBlockModule } from './select'
import { TextInputBlockModule } from './text-input'

export const FormBuilderBlocks: Array<
  BuilderBlockModule<FormSection, FormSectionCreate, FormSectionUpdate, Form>
> = [
  TextInputBlockModule,
  FormattedInputBlockModule,
  SelectBlockModule,
  HeadlineBlockModule,
]
