import { OlympusClient } from '@/OlympusClient'

import {
  type FormSection,
  type FormSectionUpdate,
} from '../../../types/FormBuilder.types'

export const updateBlockHandler = async <
  TSection extends FormSection,
  TSectionUpdate extends FormSectionUpdate,
>(
  id: string,
  body: TSectionUpdate
): Promise<TSection> => {
  return (await OlympusClient.forms.v1.sections.section.updateSection({
    body,
    params: { sectionId: id },
  })) as TSection
}
