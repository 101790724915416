import { PhQuestion } from '@phosphor-icons/vue'

import { type BuilderBlockModule } from '@/lib/builder/types'
import i18nPlugin from '@/plugins/i18n'

import {
  type FaqSectionCreate,
  type FaqSection,
  type WebsiteResource,
  type FaqSectionUpdate,
} from '../../WebsiteBuilder.types'
import { websiteBlockMethods } from '../common/methods'

import { createFaqData } from './methods/createFaqData'
import FaqPreview from './preview/FaqPreview.vue'
const i18n = i18nPlugin.global

export const FaqBlockModule: BuilderBlockModule<
  FaqSection,
  FaqSectionCreate,
  FaqSectionUpdate,
  WebsiteResource
> = {
  canAdd: false,
  canDelete: false,
  canReorderDown: true,
  canReorderUp: true,
  createData: createFaqData,
  meta: [
    {
      icon: PhQuestion,
      key: 'faq',
      label: i18n.t('website-builder.blocks.faq'),
    },
  ],
  methods: websiteBlockMethods,
  preview: FaqPreview,
}
