import { type ApplicationScope } from '@/core/types'

import type { CoreState } from '../../types'

export const getApplicationScope = (state: CoreState): ApplicationScope => {
  return {
    activeBrandId: state.activeBrand ?? undefined,
    activeLocationId: state.activeLocation ?? undefined,
  }
}
