import { type ActionContext } from 'vuex'

import { type CoreModule } from '@/lib/store/types'

import {
  type BlockCommand,
  type BlockCore,
  type BuilderState,
  type ResourceCommand,
  type ResourceCore,
} from '../../types'

export const applyCommand = async <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  { state, commit }: ActionContext<BuilderState<TResource, TBlock>, CoreModule>,
  command: BlockCommand<TBlock> | ResourceCommand<TResource>
): Promise<void> => {
  const currentBlocks = state._builder_currentState.blocks
  const currentResource = state._builder_currentState.resource

  const editBlockIndex = currentBlocks.findIndex(
    (block) => block.id === command.context.targetId
  )

  let update

  switch (command.context.scope) {
    case 'block':
      if (editBlockIndex === -1) {
        throw new Error('Unable to find block')
      }

      update = await (command as BlockCommand<TBlock>).commit(
        currentBlocks[editBlockIndex] as TBlock,
        command.context
      )

      commit('_builder_SET_BLOCK', {
        data: update,
      })
      break

    case 'resource':
      update = await (command as ResourceCommand<TResource>).commit(
        currentResource as TResource,
        command.context
      )

      commit('_builder_SET_RESOURCE', { data: update })
      break

    default:
      throw new Error('Block type not supported')
  }

  commit('_builder_SET_CURRENT_COMMAND', command.id)
}
