import { type RouteRecordRaw } from 'vue-router'

export const oauthRouter: RouteRecordRaw[] = [
  {
    component: async () => import('../views/OauthStripeView.vue'),
    meta: {
      title: 'oauth.stripe',
    },
    name: 'oauth.stripe',
    path: 'oauth/stripe',
    props: {
      provider: 'stripe',
    },
  },
]
