import { datadogRum } from '@datadog/browser-rum'
import { storeToRefs } from 'pinia'
import { type RouteLocationRaw, useRoute, useRouter } from 'vue-router'

import { analytics } from '@/plugins/analytics'
import { FeatureFlags } from '@/plugins/featureFlags'
import { Sprig } from '@/plugins/sprig'
import globalStore from '@/store'

import { useAuthStore } from './useAuthStore'

interface UseAuthActions {
  login: (args: { email: string; password: string }) => Promise<void>
  resetPassword: (args: { password: string; token: string }) => Promise<void>
  multiFactorLogin: (args: { email: string; token: string }) => Promise<void>
  logout: () => Promise<void>
}

export const useAuthActions = (): UseAuthActions => {
  const router = useRouter()
  const route = useRoute()

  const authStore = useAuthStore()
  const { user, home, isSuperAdmin } = storeToRefs(authStore)

  const sanitizePath = (path: string): RouteLocationRaw => {
    const { name, params, query } = router.resolve(path)

    if (name && name !== 'not-found' && name !== '') {
      return { name, params, query }
    } else {
      return home.value
    }
  }

  const configurePlugins = async (): Promise<void> => {
    if (!user.value) throw new Error('[Auth] User Not Defined On Plugin Config')

    FeatureFlags.setContextField('userId', user.value.email)

    if (Sprig) {
      await Promise.all([
        Sprig.setUserId(user.value.id),
        Sprig.setEmail(user.value.email),
        Sprig.setAttributes({
          role: user.value.role,
        }),
      ])
    }

    const userData = {
      brands: user.value.brands,
      createdAt: user.value.createdAt,
      email: user.value.email,
      firstName: user.value.firstName,
      id: user.value.id,
      lastName: user.value.lastName,
      locations: user.value.locations,
      name: `${user.value.firstName} ${user.value.lastName}`,
      role: user.value.role,
    }

    analytics.identify(user.value.id)
    analytics.setUserData(userData)
    datadogRum.setUser(userData)
  }

  const logoutPlugins = (): void => {
    if (Sprig) {
      Sprig.logoutUser()
    }
  }

  const redirectToUserHome = async (): Promise<void> => {
    if (!user.value)
      throw new Error('[Auth] User Not Defined On Redirect To Home')

    if (!isSuperAdmin.value) {
      if (user.value.brands === null) {
        throw new Error(
          `[Auth] STOPPING LOGIN - Brand User ${user.value.id} Associated With ALL Brands`
        )
      } else if (user.value.brands.length === 0) {
        throw new Error(
          `[Auth] STOPPING LOGIN - Brand User ${user.value.id} Not Associated With Any Brands`
        )
      }
    }

    const redirectFrom = route.query.redirectFrom

    if (redirectFrom && typeof redirectFrom === 'string') {
      await router.push(sanitizePath(redirectFrom))
    } else {
      await router.push(home.value)
    }
  }

  const login = async ({
    email,
    password,
  }: {
    email: string
    password: string
  }): Promise<void> => {
    await authStore.login({ email, password })

    await configurePlugins()
    analytics.track('Login', { via: 'login' })

    await redirectToUserHome()
  }

  const resetPassword = async ({
    password,
    token,
  }: {
    password: string
    token: string
  }): Promise<void> => {
    await authStore.resetPassword({
      password,
      token,
    })

    await configurePlugins()
    analytics.track('Login', { via: 'reset-password' })

    await redirectToUserHome()
  }

  const multiFactorLogin = async ({
    email,
    token,
  }: {
    email: string
    token: string
  }): Promise<void> => {
    await authStore.multiFactorLogin({
      email,
      token,
    })

    await configurePlugins()
    analytics.track('Login', { via: 'mfa-login' })

    await redirectToUserHome()
  }

  const logout = async (): Promise<void> => {
    await authStore.logout()

    analytics.track('Logout')
    logoutPlugins()

    globalStore.commit(`core/RESET_STATE`, null, { root: true })

    await router.push({
      name: 'auth.login',
    })
  }

  return {
    login,
    logout,
    multiFactorLogin,
    resetPassword,
  }
}
