<i18n locale="en">
  {
    "labels": {
      "image": "Photo"
    }
  }
  </i18n>

<template>
  <OwnInputContainer :label="t('labels.image')" :errors="image.status.errors">
    <ImageInput
      v-model="image.value"
      :label="t('labels.image')"
      :crop-width="1440"
      :aspect-ratio="imageAspectRatio"
    />
  </OwnInputContainer>
</template>

<script>
import { useI18n } from 'vue-i18n'

import { ImageInput } from '@/components/inputs/ImageInput'
import { FormBuilderModelSync, Validators } from '@/forms'
import { OwnInputContainer } from '@/ui/OwnInputContainer'

export default {
  name: 'ContentImageEditor',
  components: {
    ImageInput,
    OwnInputContainer,
  },
  mixins: [
    FormBuilderModelSync({
      image: {
        debounce: 250,
        validateOnInit: true,
        validators: [Validators.required],
      },
    }),
  ],
  props: {
    layout: { type: String, default: 'generic' },
  },
  emits: ['update'],
  setup() {
    const { t } = useI18n()

    return { t }
  },
  computed: {
    imageAspectRatio() {
      const { layout } = this

      switch (layout) {
        case 'generic':
          return 1
        case 'cover':
          return 1.6
        default:
          throw new Error('Unknown layout value')
      }
    },
  },
  watch: {
    // When the layout changes, we want to reset the image, because currently our system can't guarantee that
    // the exisiting image (which we might have cropped/resized) will look good in the new layout.
    layout() {
      this.image.value = null
    },
  },
  methods: {
    onFormControlUpdate(controlName, value, status) {
      this.$emit('update', { controlName, status, value })
    },
  },
}
</script>
