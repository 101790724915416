<i18n locale="en">
{
  "buy-card": "Buy Card",
  "check-balance": "Check Balance"
}
</i18n>

<template>
  <div class="gift-card-order-type flex-row gap-10">
    <div class="flex-col gap-2">
      <span
        class="gift-card-order-type__icon"
        :style="{ color: mergedTheme.colors.text.primary }"
      >
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M21 5.25H3C2.58579 5.25 2.25 5.58579 2.25 6V18C2.25 18.4142 2.58579 18.75 3 18.75H21C21.4142 18.75 21.75 18.4142 21.75 18V6C21.75 5.58579 21.4142 5.25 21 5.25Z"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M15.375 15.375H18.375"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.125 15.375H11.625"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2.25 9.08447H21.75"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
      <PreviewTypographyLegacy
        :text="t('buy-card')"
        variant="titleRegular"
        color="primary"
      />
      <PreviewDecorationLine color="brandPrimary" />
    </div>
    <div class="flex-col gap-2">
      <span
        class="gift-card-order-type__icon"
        :style="{ color: mergedTheme.colors.text.primary }"
      >
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12 6.75V8.25"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12 15.75V17.25"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.75 15.75H13.125C13.6223 15.75 14.0992 15.5525 14.4508 15.2008C14.8025 14.8492 15 14.3723 15 13.875C15 13.3777 14.8025 12.9008 14.4508 12.5492C14.0992 12.1975 13.6223 12 13.125 12H10.875C10.3777 12 9.90081 11.8025 9.54917 11.4508C9.19754 11.0992 9 10.6223 9 10.125C9 9.62772 9.19754 9.15081 9.54917 8.79917C9.90081 8.44754 10.3777 8.25 10.875 8.25H14.25"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
      <PreviewTypographyLegacy
        :text="t('check-balance')"
        variant="titleRegular"
        color="secondary"
      />
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'

import PreviewTypographyLegacy from '@/lib/builder/public/preview/legacy/PreviewTypographyLegacy.vue'
import { useMergedTheme } from '@/lib/builder/public/preview/mixins/useMergedTheme'
import PreviewDecorationLine from '@/lib/builder/public/preview/PreviewDecorationLine.vue'

export default {
  name: 'GiftCardOrderType',
  components: { PreviewDecorationLine, PreviewTypographyLegacy },
  mixins: [useMergedTheme],
  setup() {
    const { t } = useI18n()

    return { t }
  },
}
</script>

<style lang="scss" scoped>
.gift-card-order-type {
  margin: 40px 0;
  &__icon {
    height: 24px;
    width: 24px;
  }
}
</style>
