<i18n locale="en">
{
  "actions": {
    "archive": "Archive"
  },
  "errors": {
    "archive": "Unable to archive campaign"
  },
  "status": {
    "draft": "Draft",
    "error": "Error",
    "active": "Sending",
    "paused": "Paused",
    "archived": "Archived",
    "completed": "Sent",
    "populating": "Sending",
    "processing": "Sending",
    "queued": "Sending",
    "scheduled": "Scheduled"
  }
}
</i18n>

<template>
  <OwnActionMenu
    class="campaign-status-badge"
    :actions="actions"
    @action="onActionSelect"
  >
    <template #trigger>
      <OwnStatus
        class="w-full"
        :status="badgeStatus"
        :text="t(`status.${localCampaign?.status ?? 'draft'}`)"
        is-trigger
      />
    </template>
  </OwnActionMenu>
</template>

<script>
import { PhTrash } from '@phosphor-icons/vue'
import { useI18n } from 'vue-i18n'

import { logError } from '@/logger'
import notify from '@/mixins/notify'
import { OlympusClient } from '@/OlympusClient'
import { OwnActionMenu } from '@/ui/actions/OwnActionMenu'
import { OwnStatus } from '@/ui/OwnStatus'

export default {
  name: 'CampaignStatusBadge',
  components: { OwnActionMenu, OwnStatus },
  mixins: [notify],
  props: {
    campaign: { type: Object, required: true },
  },
  emits: ['archive'],
  data() {
    return {
      localCampaign: undefined,
    }
  },
  setup() {
    const { t } = useI18n()

    return { t }
  },
  computed: {
    actions() {
      return [
        {
          color: 'danger',
          icon: PhTrash,
          value: 'archive',
        },
      ].map((action) => ({
        ...action,
        label: this.t(`actions.${action.value}`),
      }))
    },
    badgeStatus() {
      const { localCampaign } = this

      switch (localCampaign?.status) {
        case 'active':
          return 'success'
        case 'populating':
          return 'success'
        case 'completed':
          return 'success'
        case 'processing':
          return 'success'
        case 'paused':
          return 'neutral'
        case 'archived':
          return 'error'
        case 'scheduled':
          return 'warning'
        default:
          return 'neutral'
      }
    },
  },
  watch: {
    campaign: {
      deep: true,
      handler() {
        this.resetLocalData()
      },
    },
  },
  created() {
    this.resetLocalData()
  },
  methods: {
    async archiveCampaign(campaignId) {
      try {
        const result =
          await OlympusClient.marketing.v1.campaigns.campaign.deleteCampaign({
            params: {
              campaignId,
            },
          })

        if (result.success) {
          this.localCampaign.status = 'archived'
          this.$emit('archive')
        }
      } catch (err) {
        logError(err)
        this.$notify(this.t('errors.archive'), 'error')
      }
    },
    async onActionSelect(action) {
      const { localCampaign } = this

      if (action === 'archive') {
        await this.archiveCampaign(localCampaign.id)
      }
    },
    resetLocalData() {
      this.localCampaign = this.campaign
    },
  },
}
</script>

<style lang="scss" scoped>
.campaign-status-badge {
  min-width: 150px;
  max-width: 150px;
}
</style>
