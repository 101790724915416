import { type RouteLocationRaw } from 'vue-router'

import {
  type BlockCore,
  type BuilderState,
  type ResourceCore,
} from '../../types'

export const returnLocation = <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  state: BuilderState<TResource, TBlock>
): RouteLocationRaw | undefined => {
  return state._builder_returnLocation
}
