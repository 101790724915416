const normalizeToArray = (maybeArray: string | string[]): string[] => {
  return Array.isArray(maybeArray) ? maybeArray : [maybeArray]
}

/**
 * Checks to see if the current user has scopes valid for an operation
 *
 * @param sessionScopes A list of scopes for the current user
 * @param validScopes A list of scopes valid for an operation
 *
 * @returns true if user has access
 */
export const checkScopes = (
  sessionScopes: string[] = [],
  validScopes: string | string[] = []
): boolean => {
  if (!sessionScopes.length) return false

  for (const scope of normalizeToArray(validScopes)) {
    if (Array.isArray(scope)) {
      let allMatched = true

      for (const groupedScope of scope) {
        if (!checkScopes(sessionScopes, groupedScope)) {
          allMatched = false
          break
        }
      }

      if (allMatched) return true
    } else if (
      scope.endsWith('*') &&
      sessionScopes.find((s) => s.startsWith(scope.replace(':*', '')))
    ) {
      return true
    } else if (sessionScopes.includes(scope)) {
      return true
    }
  }

  return false
}

/**
 * Checks the current user's session against a list of valid roles
 *
 * @param sessionRole The current role on the session
 * @param validRoles A list of valid roles
 *
 * @returns true if user has access
 */
export const checkRole = (
  sessionRole: string,
  validRoles: string | string[] = []
): boolean => {
  if (!sessionRole) return false

  for (const role of normalizeToArray(validRoles)) {
    if (role.endsWith(':*') && sessionRole.startsWith(role.replace(':*', '')))
      return true
    else if (role === sessionRole) return true
  }

  return false
}

type Collection = 'Brand' | 'Location'
type Resource = 'brands' | 'locations'

/**
 * Checks to see if the user has access to particular organizations
 *
 * @param sessionACL The current user's ACL object
 * @param sessionACL.brands brands the user has access to
 * @param sessionACL.locations locations the user has access to
 * @param aclMap A mapping of collections to document id[s]
 * @param aclMap.Brand brand to query access against
 * @param aclMap.Location location to query access against
 *
 * @returns true if user has access
 */
export const checkACL = (
  sessionACL: Record<Resource, string[] | null>,
  aclMap: Partial<Record<Collection, string | undefined>>
): boolean => {
  const collectionMap: Record<Collection, Resource> = {
    Brand: 'brands',
    Location: 'locations',
  }

  for (const [collection, list] of Object.entries(aclMap)) {
    if (!list) break

    const userList = sessionACL[collectionMap[collection as Collection]]

    if (userList === null) break

    for (const id of normalizeToArray(list)) {
      if (!userList.includes(id)) return false
    }
  }
  return true
}
