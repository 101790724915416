import { createStore } from 'vuex'

import core from '@/core/store'
import customers from '@/features/customers/store'
import formBuilder from '@/features/form-builder/builder/store'
import hours from '@/features/hours/store'
import emailBuilder from '@/features/marketing/builders/email/store'
import textBuilder from '@/features/marketing/builders/text/store'
import websiteBuilder from '@/features/website-builder/builder/store'

export default createStore({
  modules: {
    core,
    customers,
    emailBuilder,
    formBuilder,
    hours,
    textBuilder,
    websiteBuilder,
  },
  strict: false,
})

export * from './utils/useMappedGetter'
export * from './utils/useMappedAction'
