import {
  type BooleanFilterTranslation,
  type DropdownFilterTranslation,
} from '@/components/filtering/filter-set/types'

import { type OrderStatus, type OrderType } from '../types'

interface OrdersTranslations {
  filters2: Record<
    string,
    BooleanFilterTranslation | DropdownFilterTranslation | string
  >
  type: Record<OrderType, string>
  status: Record<OrderStatus, string>
}

const typeOptions: Record<OrderType, string> = {
  delivery: 'Delivery',
  pickup: 'Pickup',
  store: 'Store',
}

const statusOptions: Record<OrderStatus, string> = {
  cancelled: 'Cancelled',
  claimed: 'Claimed',
  completed: 'Completed',
  unclaimed: 'Unclaimed',
  voided: 'Voided',
}

export const ordersEn: OrdersTranslations = {
  filters2: {
    status: {
      label: 'Status',
      options: statusOptions,
    },
    type: {
      label: 'Type',
      options: typeOptions,
    },
  },
  status: statusOptions,
  type: typeOptions,
}
