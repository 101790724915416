<i18n locale="en">
{
  "title": "Create Email",
  "actions": {
    "create": "Create"
  }
}
</i18n>

<template>
  <OwnDialog :model-value="show" @cancel="$emit('cancel')">
    <template #heading>
      <OwnDialogHeading :title="t('title')" @cancel="$emit('cancel')" />
    </template>

    <OwnDialogContent scroll>
      <CreateEmailForm v-model="formValues" v-model:valid="isFormValid" />
    </OwnDialogContent>

    <template #footer>
      <OwnDialogFooter>
        <OwnButton
          :text="t('actions.create')"
          primary
          :processing="isCreating"
          @click="onCreate"
        >
          <template #icon-right>
            <PhArrowCircleRight size="24" />
          </template>
        </OwnButton>
      </OwnDialogFooter>
    </template>
  </OwnDialog>
</template>

<script>
import { PhArrowCircleRight } from '@phosphor-icons/vue'
import { useI18n } from 'vue-i18n'

import { OwnButton } from '@/ui/OwnButton'
import {
  OwnDialog,
  OwnDialogContent,
  OwnDialogFooter,
  OwnDialogHeading,
} from '@/ui/OwnDialog'

import CreateEmailForm from './CreateEmailForm.vue'

export default {
  name: 'CreateEmailDialog',
  components: {
    CreateEmailForm,
    OwnButton,
    OwnDialog,
    OwnDialogContent,
    OwnDialogFooter,
    OwnDialogHeading,
    PhArrowCircleRight,
  },
  props: {
    /** True when builder-core is instantiating the email template */
    isCreating: { type: Boolean, default: false },
    /** Show Modal */
    show: { type: Boolean, default: false },
  },
  emits: ['create', 'cancel'],
  setup() {
    const { t } = useI18n()

    return { t }
  },
  data() {
    return {
      formValues: {
        name: null,
      },
      isFormValid: false,
    }
  },
  methods: {
    onCreate() {
      const { formValues, isFormValid } = this

      if (isFormValid) {
        this.$emit('create', formValues)
      }
    },
  },
}
</script>
