<i18n locale="en">
{
  "labels": {
    "confirm": "Publish",
    "publishing": "Publishing",
    "success": "Page Published",
    "error": "Uh oh..."
  },
  "descriptions": {
    "edit-success": "Congrats, your updates are live on your website! It may take a couple of minutes to appear."
  },
  "actions": {
    "continue-edit": "Continue Editing",
    "end-edit": "End Editing",
    "publish": "Publish",
    "check": "Go back and check"
  }
}
</i18n>

<template>
  <OwnDialog :model-value="show" @cancel="$emit('close-dialog')">
    <template #full-dialog>
      <PublishInProgress v-if="publishState === 'publishing'" />
    </template>

    <template #heading>
      <OwnDialogHeading
        :title="t(`labels.${publishState}`)"
        @cancel="$emit('close-dialog')"
      >
        <template #icon-left>
          <PhCheckCircle
            v-if="publishState === 'success'"
            class="status-success"
            size="32"
          />
          <PhWarning
            v-if="publishState === 'error'"
            class="status-warning"
            size="32"
          />
        </template>
      </OwnDialogHeading>
    </template>

    <OwnDialogContent scroll>
      <WebsitePublishConfirmContent
        v-if="publishState === 'confirm'"
        :is-live="currentResource?.enabled"
      />

      <OwnType
        v-if="publishState === 'success'"
        :text="t('descriptions.edit-success')"
      />

      <PublishErrorContent
        v-if="publishState === 'error'"
        :errors="errors"
        :block-modules="blockModules"
        :current-blocks="currentBlocks"
      />
    </OwnDialogContent>

    <template #footer>
      <OwnDialogFooter>
        <WebsitePublishConfirmActions
          v-if="publishState === 'confirm'"
          :is-live="currentResource?.enabled"
          @click="$emit('confirm-publish')"
        />

        <template v-if="publishState === 'success'">
          <OwnButton
            :text="t('actions.continue-edit')"
            @click="$emit('close-dialog')"
          />
          <OwnButton
            :text="t('actions.end-edit')"
            primary
            @click="$emit('close-page')"
          />
        </template>

        <template v-if="publishState === 'error'">
          <OwnButton
            :text="t('actions.check')"
            primary
            @click="$emit('close-dialog')"
          />
        </template>
      </OwnDialogFooter>
    </template>
  </OwnDialog>
</template>

<script>
import { PhCheckCircle, PhWarning } from '@phosphor-icons/vue'
import { useI18n } from 'vue-i18n'

import PublishErrorContent from '@/lib/builder/public/utility/PublishErrorContent.vue'
import PublishInProgress from '@/lib/builder/public/utility/PublishInProgress.vue'
import { OwnButton } from '@/ui/OwnButton'
import {
  OwnDialog,
  OwnDialogContent,
  OwnDialogFooter,
  OwnDialogHeading,
} from '@/ui/OwnDialog'
import { OwnType } from '@/ui/OwnType'

import WebsitePublishConfirmActions from './confirm/WebsitePublishConfirmActions.vue'
import WebsitePublishConfirmContent from './confirm/WebsitePublishConfirmContent.vue'

export default {
  name: 'WebsitePublishDialog',
  components: {
    OwnButton,
    OwnDialog,
    OwnDialogContent,
    OwnDialogFooter,
    OwnDialogHeading,
    OwnType,
    PhCheckCircle,
    PhWarning,
    PublishErrorContent,
    PublishInProgress,
    WebsitePublishConfirmActions,
    WebsitePublishConfirmContent,
  },
  props: {
    blockModules: { type: Array, default: () => [] },
    currentBlocks: { type: Array, default: () => [] },
    currentResource: { type: Object, default: undefined },
    errors: { type: Array, default: () => [] },
    publishState: { type: String, required: true },
    show: { type: Boolean, default: false },
  },
  emits: ['close-dialog', 'confirm-publish', 'close-page'],
  setup() {
    const { t } = useI18n()

    return { t }
  },
}
</script>
