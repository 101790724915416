<i18n locale="en">
{
  "title": "Publishing...",
  "description": "This will only take a moment"
}
</i18n>

<template>
  <div class="publish-in-progress flex-col gap-3 justify-center align-center">
    <OwnProgressSpinner color="misc-brand" />
    <div class="flex-col gap-1 justify-center align-center">
      <OwnType :text="t('title')" variant="title" />
      <OwnType :text="t('description')" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

import { OwnProgressSpinner } from '@/ui/OwnProgressSpinner'
import { OwnType } from '@/ui/OwnType'

const { t } = useI18n()
</script>

<style lang="scss" scoped>
.publish-in-progress {
  padding: 80px;
}
</style>
