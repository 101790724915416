import { locationsApi } from '@/api'
import { OlympusClient } from '@/OlympusClient'
import { convertToPathObject } from '@/utils/structure'

import defaultState from './state'

const multiLocationActions = {
  locations: {
    loadByBrandId: async ({ commit }, brandId) => {
      const { results } = await locationsApi.getLocations({
        brandId,
        limit: 100,
      })

      for (const location of results) {
        commit('SET_LOCATION', location)
      }
    },
    setAll: ({ commit }, locations) => {
      commit('SET_LOCATIONS', locations)
    },
    setDefaultState: ({ commit }) => {
      commit('SET_DEFAULTS', defaultState)
    },
  },
}

const singleLocationActions = {
  location: {
    load: async ({ commit }, locationId) => {
      const location = await locationsApi.getLocation(locationId)

      commit('SET_LOCATION', location)
    },

    setActive: async ({ commit }, locationId) => {
      commit('SET_ACTIVE_LOCATION', locationId)
    },

    /**
     * WARNING - This is dangerous.  Check with Tucker before using.
     * Sets location from outside store module
     *
     * TECH_DEBT: https://www.notion.so/owner-com/Multiple-stores-in-hermes-6dfa54e0c45848b8b84b8295e6282030?pvs=4
     * @deprecated This should not be used.  It only exists to sync from the old store module and the new one.  Will be removed when old module is no longer used.
     * @param {object} Vuex ignore
     * @param {Function} Vuex.commit ignore
     * @param {object} location Owner Location
     */
    setLocation: async ({ commit }, location) => {
      commit('SET_LOCATION', location)
    },

    update: {
      address: async ({ commit }, { locationId, newAddress }) => {
        const { id, address } = await locationsApi.updateLocation(locationId, {
          address: newAddress,
        })

        commit('UPDATE_LOCATION_ADDRESS', { id, update: address })
      },
      analytics: async ({ commit }, { locationId, newAnalytics }) => {
        const location = await locationsApi.updateLocation(locationId, {
          analytics: newAnalytics,
        })

        commit('SET_LOCATION', location)
      },
      contact: async ({ commit }, { locationId, newContact }) => {
        const { id, contact } = await locationsApi.updateLocation(locationId, {
          contact: newContact,
        })

        commit('UPDATE_LOCATION_CONTACT', { id, update: contact })
      },
      features: {
        '*': async ({ commit }, { locationId, newFeatures }) => {
          const { id, features } = await locationsApi.updateLocation(
            locationId,
            {
              features: newFeatures,
            }
          )

          commit('UPDATE_LOCATION_FEATURES', { id, update: features })
        },
        automations: async ({ commit }, { locationId, newAutomations }) => {
          const {
            id,
            features: { automations },
          } = await locationsApi.updateLocation(locationId, {
            features: {
              automations: newAutomations,
            },
          })

          commit('UPDATE_LOCATION_AUTOMATIONS', { id, update: automations })
        },
      },
      funnel: async ({ commit }, { locationId, newFunnel }) => {
        const { id, funnel } = await locationsApi.updateLocation(locationId, {
          funnel: newFunnel,
        })

        commit('UPDATE_LOCATION_FUNNEL', { id, update: funnel })
      },
      hours: async ({ commit }, { locationId, newHours }) => {
        const { id, hours } = await locationsApi.updateLocation(locationId, {
          hours: newHours,
        })

        commit('UPDATE_LOCATION_HOURS', { id, update: hours })
      },
      jobs: async ({ commit }, { locationId, newJobs }) => {
        const { id, jobs } = await locationsApi.updateLocation(locationId, {
          jobs: newJobs,
        })

        commit('UPDATE_LOCATION_JOBS', { id, update: jobs })
      },
      link: async ({ commit, getters }, { locationId, update }) => {
        const targetLinks = getters['location/funnel/links/*'](locationId)

        if (targetLinks) {
          const updatedLinks = { ...targetLinks, ...update }

          const location = await locationsApi.updateFunnelLinks(
            locationId,
            updatedLinks
          )

          commit('SET_LOCATION', location)
        }
      },
      listingSyncTime: async ({ commit }, { id, update }) => {
        commit('UPDATE_LOCATION_LISTING_UPDATE_TIME', {
          id,
          update,
        })
      },
      listingsData: async ({ commit }, { locationId, newListingsData }) => {
        const { id, listingsData } = await locationsApi.updateLocation(
          locationId,
          {
            listingsData: newListingsData,
          }
        )
        commit('UPDATE_LOCATION_LISTINGS_DATA', { id, update: listingsData })
      },
      messaging: async ({ commit }, { locationId, newMessaging }) => {
        const { id, messaging } = await locationsApi.updateLocation(
          locationId,
          {
            messaging: newMessaging,
          }
        )

        commit('UPDATE_LOCATION_MESSAGING', { id, update: messaging })
      },
      name: async ({ commit }, { locationId, newName }) => {
        const { id, name } = await locationsApi.updateLocation(locationId, {
          name: newName,
        })

        commit('UPDATE_LOCATION_NAME', { id, update: name })
      },
      orderTypes: async ({ commit }, { locationId, newOrderTypes }) => {
        const { id, orderTypes } = await locationsApi.updateLocation(
          locationId,
          {
            orderTypes: newOrderTypes,
          }
        )

        commit('UPDATE_LOCATION_ORDER-TYPES', { id, update: orderTypes })
      },
      otter: async ({ commit }, { locationId, otterStoreId }) => {
        const location = await locationsApi.updateOtterPosIntegration(
          locationId,
          otterStoreId
        )
        commit('SET_LOCATION', location)
      },
      ownerPos: async ({ commit }, { locationId }) => {
        const location =
          await OlympusClient.locations.location.configureOwnerPos({
            body: {},
            params: { locationId },
          })
        commit('SET_LOCATION', location)
      },
      payments: async ({ commit }, { locationId, newPayments }) => {
        const { id, payments } = await locationsApi.updateLocation(locationId, {
          payments: newPayments,
        })

        commit('UPDATE_LOCATION_PAYMENTS', { id, update: payments })
      },
      posFeatures: async ({ commit }, { locationId, updatedFeatures }) => {
        const location =
          await OlympusClient.locations.location.updatePosFeatures({
            body: updatedFeatures,
            params: { locationId },
          })
        commit('SET_LOCATION', location)
      },
      preparation: async ({ commit }, { locationId, newPreparation }) => {
        const { id, preparation } = await locationsApi.updateLocation(
          locationId,
          {
            preparation: newPreparation,
          }
        )

        commit('UPDATE_LOCATION_PREPARATION', { id, update: preparation })
      },
      removePos: async ({ commit }, { locationId }) => {
        const location = await locationsApi.removePosIntegration(locationId)
        commit('SET_LOCATION', location)
      },
      removeStripe: async ({ commit, getters }, { locationId }) => {
        const targetLocation = getters['location/byId'](locationId)

        const processingUpdate = {
          bearer: targetLocation?.processing?.bearer,
          stripe: {
            id: null,
          },
          type: 'stripe',
        }

        const location = await locationsApi.updateLocation(locationId, {
          processing: processingUpdate,
        })

        commit('SET_LOCATION', location)
      },
      slug: async ({ commit }, { locationId, newSlug }) => {
        const { id, slug } = await locationsApi.updateLocation(locationId, {
          slug: newSlug,
        })

        commit('UPDATE_LOCATION_SLUG', { id, update: slug })
      },
      square: async ({ commit }, { locationId, updatedSquareId }) => {
        const location = await locationsApi.updateSquareLocation(
          locationId,
          updatedSquareId
        )
        commit('SET_LOCATION', location)
      },
      theme: async ({ commit }, { locationId, newTheme }) => {
        const { id, theme } = await locationsApi.updateLocation(locationId, {
          theme: newTheme,
        })

        commit('UPDATE_LOCATION_THEME', { id, update: theme })
      },
      throttle: async ({ commit }, { locationId, newThrottle }) => {
        const { id, throttle } = await locationsApi.updateLocation(locationId, {
          throttle: newThrottle,
        })

        commit('UPDATE_LOCATION_THROTTLE', { id, update: throttle })
      },
      timezone: async ({ commit }, { locationId, newTimezone }) => {
        const { id, timezone } = await locationsApi.updateLocation(locationId, {
          timezone: newTimezone,
        })

        commit('UPDATE_LOCATION_TIMEZONE', { id, update: timezone })
      },
      woflow: async ({ commit }, { locationId, newWoflow }) => {
        const { id, woflow } = await locationsApi.updateLocation(locationId, {
          woflow: newWoflow,
        })

        commit('UPDATE_LOCATION_WOFLOW', { id, update: woflow })
      },
    },
  },
}

export default {
  ...convertToPathObject(multiLocationActions),
  ...convertToPathObject(singleLocationActions),
}
