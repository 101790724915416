<template>
  <PreviewSheet radius="0" class="pb-6">
    <div>
      <div
        class="email-header px-4 py-3 flex-column justify-center align-start"
      >
        <PreviewLogo
          class="email-header__logo flex-column justify-center align-center w-full"
          disable-palette
          logo-size="xs"
        />
      </div>
    </div>
  </PreviewSheet>
</template>

<script>
import PreviewLogo from '@/lib/builder/public/preview/PreviewLogo.vue'
import PreviewSheet from '@/lib/builder/public/preview/PreviewSheet.vue'

export default {
  name: 'EmailHeader',
  components: { PreviewLogo, PreviewSheet },
}
</script>

<style lang="scss" scoped>
.email-header__logo {
  max-height: 40px;
}
</style>
