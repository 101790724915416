import { type CreateBlockData } from '@/lib/builder/types'

import {
  type TopPicksSectionCreate,
  type TopPicksSection,
} from '../../../WebsiteBuilder.types'

export const createTopPicksData = ({
  brandId,
}: CreateBlockData<TopPicksSection>): TopPicksSectionCreate => ({
  brandId,
  params: {
    layout: 'gallery',
  },
  type: 'top-picks',
})
