<template>
  <div class="flex-col">
    <div
      v-if="announcement?.title"
      class="announcement-preview flex-row gap-1 align-center justify-center"
      :style="{ backgroundColor }"
    >
      <PreviewTypographyLegacy :text="announcement.title" color="white" />
    </div>
    <WebsiteBuilderPreviewNavbar />
  </div>
</template>

<script>
import builderConsumer from '@/lib/builder/mixins/builderConsumer'
import builderProducer from '@/lib/builder/mixins/builderProducer'
import PreviewTypographyLegacy from '@/lib/builder/public/preview/legacy/PreviewTypographyLegacy.vue'
import { useMergedTheme } from '@/lib/builder/public/preview/mixins/useMergedTheme'

import { WebsiteHeadingStaticModule } from '../../preview/heading'

export default {
  name: 'AnnouncementPreview',
  components: {
    PreviewTypographyLegacy,
    WebsiteBuilderPreviewNavbar: WebsiteHeadingStaticModule.preview,
  },
  mixins: [
    builderProducer,
    builderConsumer('announcement', 'onMessage'),
    useMergedTheme,
  ],
  data() {
    return {
      announcement: undefined,
    }
  },
  computed: {
    backgroundColor() {
      const { mergedTheme } = this

      return mergedTheme?.colors?.brand?.primary
    },
  },
  mounted() {
    this.sendBuilderEvent('announcement', {
      topic: 'request-update',
    })
  },
  methods: {
    onMessage(message) {
      if (message.topic === 'update') {
        this.announcement = message.payload
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.announcement-preview {
  padding: 12px 80px;
}
</style>
