import { BuilderRouterFactory } from '@/lib/builder/router/BuilderRouterFactory'
import { useBuilderRouter } from '@/lib/builder/router/useBuilderRouter'

import { formBuilderConfig } from './formBuilderConfig'

const formBuilderRouteManager = useBuilderRouter(formBuilderConfig)

const formBuilderModuleRoutes = BuilderRouterFactory(formBuilderConfig)

export { formBuilderModuleRoutes, formBuilderRouteManager }
