import {
  type BlockCore,
  type BuilderState,
  type ResourceCore,
} from '../../types'

export const blockDeleteMutation = <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  state: BuilderState<TResource, TBlock>,
  blockId: string
): void => {
  const indexToDelete = state._builder_currentState.blocks.findIndex(
    (block) => block.id === blockId
  )
  state._builder_currentState.blocks.splice(indexToDelete, 1)
}
