import {
  type BuilderFetchArgs,
  type BuilderFetchResult,
} from '@/lib/builder/types'
import { OlympusClient } from '@/OlympusClient'

import { labelNavItems } from '../../utils/navItems'
import {
  type WebsiteResource,
  type WebsiteSection,
} from '../WebsiteBuilder.types'

export const styleFetchDataHandler = async ({
  brandId,
}: BuilderFetchArgs): Promise<
  BuilderFetchResult<WebsiteResource, WebsiteSection>
> => {
  const { funnel, page, sections, theme, brand, nav, keyword } =
    await OlympusClient.dashboard.v1.brands.brand.website.pages.getPageDataByPath(
      {
        params: {
          brandId,
        },
        query: {
          page: 'index',
          type: 'reserved',
        },
      }
    )

  return {
    blocks: sections,
    resource: {
      ...page,
      brand,
      funnel,
      keyword,
      nav: labelNavItems(nav),
      theme,
    },
  }
}
